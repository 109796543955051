<template>
  <div class="rBox">
    <el-form label-width="130px">
      <el-form-item label="上边距">
        <template>
          <bs-slider v-model="curSelData.marginTop" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="下边距">
        <template>
          <bs-slider v-model="curSelData.marginBottom" :min="0" :max="100" />
        </template>
      </el-form-item>
        <el-form-item label="模块标题">
            <el-input v-model="curSelData.title" placeholder="请输入标题">
            </el-input>
        </el-form-item>
        <el-card class="box-card" style="margin-top: 10px">
          <div slot="header" class="clearfix">
            图片
          </div>
          <el-form label-width="90px">
              <el-row>
                  <el-col :span="6">
                      <el-image :src="curSelData.imgUrl"
                          style="display: inline-block;height: 74px;width: 148px;max-width: 90%">
                          <div slot="error" class="image-slot">
                              <i class="el-icon-picture-outline" />
                          </div>
                      </el-image>
                  </el-col>
                  <el-col :span="18">
                      <el-input v-model="curSelData.imgUrl" placeholder="请输入图片地址">
                          <template slot="append"><span style="cursor: pointer"
                                  @click="selImg('zxCountDown', 'imgUrl')">选择图片</span></template>
                      </el-input>
                  </el-col>
              </el-row>
          </el-form>
       </el-card>
        <el-form-item label="现价">
            <el-input v-model="curSelData.price" placeholder="请输入数字">
            </el-input>
        </el-form-item>
        <el-form-item label="原价">
            <el-input v-model="curSelData.oldPrice" placeholder="请输入数字">
            </el-input>
        </el-form-item>
        <el-form-item label="是否显示倒计时">
          <el-radio-group v-model="curSelData.showTime">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="倒计时时间" v-if="curSelData.showTime">
          <el-date-picker
            v-model="curSelData.endTime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="底边框">
            <template>
                <el-radio v-model="curSelData.bottomshow" :label="true">显示</el-radio>
                <el-radio v-model="curSelData.bottomshow" :label="false">不显示</el-radio>
            </template>
        </el-form-item>
        <el-form-item label="是否显示图片">
          <el-radio-group v-model="curSelData.showImg">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        
        <el-form-item label="是否显示联系客服">
          <el-radio-group v-model="curSelData.showContact">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        
    </el-form>
  </div>
</template>
<script>
import BsSlider from '../module/BsSlider'
// import BsColorPicker from '../module/BsColorPicker'
export default {
  components: {
    BsSlider,
    // BsColorPicker
  },
  props: {
    curSelData: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => {}
    }
  },
  data() {
    return {

    }
  },
  methods: {
    selImg(type, attr) {
      this.$emit('selImg', type, attr)
    },
    addItem(type) {
      if(type === 1) {
				this.curSelData.gnList.push({
          content: '内容'
        })
      }else if(type === 2) {
        this.curSelData.parList.push({
          title:'标题',
          content: '内容',
          with: '100%',
        })
      }

    },
    delItem(type,index) {
      if(type === 1) {
        this.curSelData.gnList.splice(index, 1)
      }else  if(type === 2) {
        this.curSelData.parList.splice(index, 1)
      }
    },
  }
}
</script>
<style scoped>

</style>
