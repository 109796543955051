<template>
<div>
  <div class="oneBox">
    <img :src="item.logo" class="oneLogo" :style="{width: item.logoWidth ? item.logoWidth : '140px'}"/>
    <img :src="item.oneImg" class="oneImg" />
  </div>
  <div class="twoBox">
    <span class="blue">{{item.twoText}}</span>
    <span>{{item.twoContent}}</span>
  </div>
  <div class="threeBox">
    <div>
      <div class="thItem"><img class="lxIcon" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/wx.png" />{{item.wx}}</div>
      <div class="thItem"><img class="lxIcon" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/phone.png" />{{item.phone}}</div>
      <div class="thItem"><img class="lxIcon" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/wz.png" />{{item.website}}</div>
    </div>
    <img class="codeImg" :src="item.codeImg" />
  </div>
</div>
  
</template>
<style scoped>
.oneBox{
  position: relative;
}
.oneImg{
  width: 100%;
  display: block;

}
.oneLogo{
  position: absolute;
  width: 140px;
  top: 20px;
  left: 20px;
}
.twoBox{
  text-align: left;
  color: #fff;
  padding: 40px 40px 30px 40px;
  font-size: 16px;
  background: #011964 url('https://mzmpic.oss-cn-beijing.aliyuncs.com/2.png') top center no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  line-height: 24px;
}
.twoBox .blue{
  color: #00ffff
}
.threeBox{
  background: #011964 url('https://mzmpic.oss-cn-beijing.aliyuncs.com/3.png') top center no-repeat;
  background-size: 100% 100%;
  color: #fff;
  padding-top: 50rpx;
  min-height: 250px;
  box-sizing: border-box;
  padding-top: 0px;
  padding-left: 40px;
  padding-right: 40px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between
}
.threeBox .lxIcon{
  height: 20px;
  margin-right: 10px;
}
.thItem{
  display: flex;
  align-items: center;
  line-height: 35px;
}
.codeImg{
    width: 100px;
}
</style>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
}
</script>