<template>
  <div class="rBox">
    <el-form label-width="130px">
      <el-form-item label="上边距">
        <template>
          <bs-slider v-model="curSelData.marginTop" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="下边距">
        <template>
          <bs-slider v-model="curSelData.marginBottom" :min="0" :max="100" />
        </template>
      </el-form-item>
        <el-form-item label="模块标题">
            <el-input v-model="curSelData.title" placeholder="请输入标题">
            </el-input>
        </el-form-item>
        <el-card class="box-card" style="margin-top: 10px">
          <div slot="header" class="clearfix">
            标题图标
          </div>
          <el-form label-width="90px">
              <el-row>
                  <el-col :span="6">
                      <el-image :src="curSelData.icon"
                          style="display: inline-block;height: 74px;width: 148px;max-width: 90%">
                          <div slot="error" class="image-slot">
                              <i class="el-icon-picture-outline" />
                          </div>
                      </el-image>
                  </el-col>
                  <el-col :span="18">
                      <el-input v-model="curSelData.icon" placeholder="请输入图片地址">
                          <template slot="append"><span style="cursor: pointer"
                                  @click="selImg('realSta', 'icon')">选择图片</span></template>
                      </el-input>
                  </el-col>
              </el-row>
          </el-form>
       </el-card>
       <el-form-item label="数据采集方式">
          <el-radio-group v-model="curSelData.dataType">
            <el-radio :label="1">接口请求</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form label-width="90px">
              <el-row class="batchListBox"> 
                  <el-col :span="6">
                     <el-button @click="selBatchList(curSelData.batchList)">选择批次号</el-button>
                  </el-col>
              </el-row>
              <el-row :span="24">
                     <div v-if="curSelData.batchList" class="batchListBox">
                       <span v-for="(cItem,index) in curSelData.batchList" :key="index">{{cItem}}、</span>
                     </div>
                     <div v-else class="batchListBox">
                       <span>请选择批次号</span>
                     </div>
              </el-row>
        </el-form>
        <el-form-item label="是否显示头像">
          <el-radio-group v-model="curSelData.showAvatar">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否显示标题">
          <el-radio-group v-model="curSelData.showTitle">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否显示地址">
          <el-radio-group v-model="curSelData.showAddr">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否显示标识">
          <el-radio-group v-model="curSelData.showHandle">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否显示时间">
          <el-radio-group v-model="curSelData.showTime">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="底边框">
                <template>
                    <el-radio v-model="curSelData.bottomshow" :label="true">显示</el-radio>
                    <el-radio v-model="curSelData.bottomshow" :label="false">不显示</el-radio>
                </template>
            </el-form-item>
    </el-form>
    <!--批次号列表弹窗-->
    <BatchDialog ref="BatchDialog"></BatchDialog>
  </div>
</template>
<script>
import BsSlider from '../module/BsSlider'
// import BsColorPicker from '../module/BsColorPicker'
import BatchDialog from '../module/BatchDialog'
export default {
  components: {
    BsSlider,
    BatchDialog,
    // BsColorPicker
  },
  props: {
    curSelData: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => {}
    }
  },
  data() {
    return {

    }
  },
  methods: {
    selImg(type, attr) {
      this.$emit('selImg', type, attr)
    },
    addItem(type) {
      if(type === 1) {
				this.curSelData.gnList.push({
          content: '内容'
        })
      }else if(type === 2) {
        this.curSelData.parList.push({
          title:'标题',
          content: '内容',
          with: '100%',
        })
      }

    },
    delItem(type,index) {
      if(type === 1) {
        this.curSelData.gnList.splice(index, 1)
      }else  if(type === 2) {
        this.curSelData.parList.splice(index, 1)
      }
    },
    selBatchList(data) {
      this.$refs.BatchDialog.openDialog(data)
    },
    changeBatchNumber(data) {
      this.$set(this.curSelData, 'batchList', data)
      // this.curSelData['batchList'] = data
    }
  }
}
</script>
<style scoped>
.batchListBox {
  padding-left: 40px;
  margin: 10px 0;
}
.batchListBox span {
  margin-right: 10px;
}
</style>
