<template>
  <div class="rBox">
    <el-form label-width="100px">
      <el-form-item label="左右边距">
        <template>
          <bs-slider v-model="curSelData.paddLeft" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="上下边距">
        <template>
          <bs-slider v-model="curSelData.paddTop" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="背景颜色">
        <template>
          <bs-color-picker v-model="curSelData.bgColor" style="float: left" />
          <el-button type="primary" class="btnClear" @click="clearColor('bgColor')">清除</el-button>
        </template>
      </el-form-item>
      <el-form-item label="内容区颜色">
        <template>
          <bs-color-picker v-model="curSelData.conBgColor" style="float: left"/>
          <el-button type="primary" class="btnClear" @click="clearColor('conBgColor')">清除</el-button>
        </template>
      </el-form-item>
      <el-form-item label="分割线显示">
        <el-radio v-model="curSelData.isShowLine" :label="true">显示</el-radio>
        <el-radio v-model="curSelData.isShowLine" :label="false">隐藏</el-radio>
      </el-form-item>
      <template v-if="curSelData.isShowLine == true">
        <el-form-item label="分割线类型">
          <el-radio v-model="curSelData.lineType" :label="1">类型一</el-radio>
          <el-radio v-model="curSelData.lineType" :label="2">类型二</el-radio>
        </el-form-item>
      </template>
      <el-form-item label="标题">
        <el-input v-model="curSelData.title" placeholder="请输入标题"/>
      </el-form-item>
      <el-form-item label="标题颜色">
        <bs-color-picker v-model="curSelData.titleColor" />
      </el-form-item>
      <el-form-item label="标题大小">
        <bs-slider v-model="curSelData.titleSize" :min="0" :max="100" />
      </el-form-item>
      <el-form-item label="小标题">
        <el-input v-model="curSelData.enTitle" placeholder="请输入小标题"/>
      </el-form-item>
      <el-form-item label="小标题颜色">
        <bs-color-picker v-model="curSelData.enTitleColor" />
      </el-form-item>
      <el-form-item label="小标题大小">
        <bs-slider v-model="curSelData.enTitleSize" :min="0" :max="100" />
      </el-form-item>
      <el-form-item label="内容颜色">
        <bs-color-picker v-model="curSelData.contentColor" />
      </el-form-item>
      <el-form-item label="内容文字尺寸">
        <bs-slider v-model="curSelData.contentSize" :min="0" :max="100" />
      </el-form-item>
      <el-card v-for="(item,index) in curSelData.contents" :key="item.content" class="box-card" style="margin-top: 10px">
        <div slot="header" class="clearfix">
          <el-button style="float: right; padding: 3px 0" type="text" @click="delItem(index)">删除</el-button>
        </div>
        <el-form-item :label="`段落${index + 1}`">
          <el-input type="textarea" :rows="3" v-model="item.content" placeholder="请输入内容"/>
        </el-form-item>
      </el-card>
      <el-button style="margin:20px auto;width: 80%;display: block" @click="addItem">添加</el-button>

    </el-form>
  </div>
</template>
<style lang="scss" scoped>
  .myRow {
    line-height: 30px;
    font-size: 12px;
    margin: 10px 0;
  }
  .formsItem{
    > label {
      position: relative;
      top:10px;
    }
  }
</style>
<script>
import BsSlider from '../module/BsSlider'
import BsColorPicker from '../module/BsColorPicker'
export default {
  components: {
    BsSlider,
    BsColorPicker
  },
  props: {
    curSelData: {
      type: Object,
      required: true
    },
  },
  data() {
    return {

    }
  },
  methods: {
    clearColor(attr) {
      this.curSelData[attr] = 'transparent'
    },
    addItem() {
      let str = '请输入内容' + (this.curSelData.contents.length?  this.curSelData.contents.length+1 : '1')
      this.curSelData.contents.push({
        content: str,
      })
    },
    delItem(index){
      this.curSelData.contents.splice(index, 1)
      // this.curSelData.contents.map((item, index) => {
      //   item.content = '请输入内容' + (index+1)
      // })
    }
  }
}
</script>
