<template>
  <div class="rBox">
    <el-form label-width="90px">
      <el-form-item label="左右边距">
        <template>
          <bs-slider v-model="curSelData.paddLeft" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="上下边距">
        <template>
          <bs-slider v-model="curSelData.paddTop" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="背景颜色">
        <template>
          <bs-color-picker v-model="curSelData.bgColor" style="float:left" />
          <el-button type="primary" class="btnClear" @click="clearColor('bgColor')">清除</el-button>
        </template>
      </el-form-item>
      <el-form-item label="文字颜色">
        <bs-color-picker v-model="curSelData.titleColor" />
      </el-form-item>
      <el-form-item label="名称">
        <template>
          <el-input v-model="curSelData.title" placeholder="请输入按钮名称" style="margin-top: 10px"></el-input>
        </template>
      </el-form-item>
      <el-form-item label="pdf上传">
        <el-upload
            :action="uploadImgUrl"
            :headers="headers"
            name="file"
            :show-file-list="false"
            :on-success="init"
            :before-upload="beforeVideoUpload"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">请上传PDF文件，且不超过5MB</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="pdf地址">
        <template>
          <el-input readonly v-model="curSelData.src" placeholder="请上传PDF" style="margin-top: 10px">
          </el-input>
        </template>
      </el-form-item>
    </el-form>
  </div>
</template>
<style lang="scss" scoped>
.myRow {
  line-height: 30px;
  font-size: 12px;
  margin: 10px 0;
}
.formsItem{
  > label {
    position: relative;
    top:10px;
  }
}
/deep/ .el-upload--text{
  width: auto;
  height: auto;
}
</style>
<script>
import BsSlider from '../module/BsSlider'
import BsColorPicker from '../module/BsColorPicker'
export default {
  components: {
    BsSlider,
    BsColorPicker
  },
  props: {
    curSelData: {
      type: Object,
      required: true
    },
    singleImgae: {
      type: Boolean,
      default: false
    }
  },
  data() {
    let token = localStorage.getItem("token")
    return {
      uploadImgUrl: '/api/file/uploadObjectOSS', // 上传的视频服务器地址
      ossUrl: this.$store.state.ossUrl,
      headers: {
        token: token
      },
    }
  },
  methods: {
    init(res) {
      this.$message.success('上传成功!');
      console.log('上传成功',res);
      this.curSelData.src = this.ossUrl +res.data
    },
    beforeVideoUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 5;
      if (!isLt20M) {
        this.$message.error('上传PDF不能超过5MB!');
      }
      return isLt20M;
    },
    clearColor(attr) {
      this.curSelData[attr] = 'transparent'
    },
  }
}
</script>

