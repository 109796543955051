<template>
  <div class="ggk">
    <img :src="item.entryImg" :class="item.entryType == 1 ? 'absImg' : 'staImg'" :style="{'width': item.width}"/>
  </div>
</template>
<script>
export default {
  name: 'ggk',
  components: {
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {

    }
  }
}
</script>
<style scoped>
.staImg{

}
.absImg{
  position: absolute;
  top: 0px;
  right: 0px
}
</style>