<template>
  <div class="rBox">
    <el-form label-width="90px">
      <el-form-item label="左右边距">
        <template>
          <bs-slider v-model="curSelData.paddLeft" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="上下边距">
        <template>
          <bs-slider v-model="curSelData.paddTop" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="背景颜色">
        <template>
          <bs-color-picker v-model="curSelData.bgColor" style="float:left" />
          <el-button type="primary" class="btnClear" @click="clearColor('bgColor')">清除</el-button>
        </template>
      </el-form-item>

     

      <el-form-item label="名片">
            <el-input v-model="curSelData.cardImg" placeholder="请输入图片地址">
              <template slot="append"><span style="cursor: pointer" @click="selImg('report','cardImg')">选择图片</span></template>
            </el-input>
          <el-col :span="18">
            <img :src="curSelData.cardImg" alt="" class="imgClass">
          </el-col>
      </el-form-item>

      <el-form-item label="证书">
            <el-input v-model="curSelData.certificateImg" placeholder="请输入图片地址">
              <template slot="append"><span style="cursor: pointer" @click="selImg('report','certificateImg')">选择图片</span></template>
            </el-input>
          <el-col :span="18">
            <img :src="curSelData.certificateImg" alt="" class="imgClass">
          </el-col>
      </el-form-item>

      <el-form-item label="营业执照">
            <el-input v-model="curSelData.licenseImg" placeholder="请输入图片地址">
              <template slot="append"><span style="cursor: pointer" @click="selImg('report','licenseImg')">选择图片</span></template>
            </el-input>
          <el-col :span="18">
            <img :src="curSelData.licenseImg" alt="" class="imgClass">
          </el-col>
      </el-form-item>

      <el-form-item label="申请表">
            <el-input v-model="curSelData.ApplicationFormImg" placeholder="请输入图片地址">
              <template slot="append"><span style="cursor: pointer" @click="selImg('report','ApplicationFormImg')">选择图片</span></template>
            </el-input>
          <el-col :span="18">
            <img :src="curSelData.ApplicationFormImg" alt="" class="imgClass">
          </el-col>
      </el-form-item>
      

      <!--权利-->
      <div class="listWrap">
        <div class="listTitle">会员权利</div>
        <el-card v-for="(item,index) in curSelData.rightList" :key="item.title" class="box-card" style="margin-top: 10px">
          <div slot="header" class="clearfix">
            <el-button style="float: right; padding: 3px 0" type="text" @click="delItem(index, 1)">删除</el-button>
          </div>
          <el-row>
            <el-col :span="24">
              <el-input v-model="item.title" placeholder="请输入描述项内容" style="margin-top: 10px">
                <template slot="append">描述项内容</template>
              </el-input>
            </el-col>
          </el-row>
        </el-card>
        <el-button style="margin:20px auto;width: 80%;display: block" @click="addItem(1)">添加权利</el-button>        
      </div>



      <!--义务-->
      <div class="listWrap">
        <div class="listTitle">会员义务</div>
        <el-card v-for="(item,index) in curSelData.obligationList" :key="item.title" class="box-card" style="margin-top: 10px">
          <div slot="header" class="clearfix">
            <el-button style="float: right; padding: 3px 0" type="text" @click="delItem(index, 2)">删除</el-button>
          </div>
          <el-row>
            <el-col :span="24">
              <el-input v-model="item.title" placeholder="请输入描述项内容" style="margin-top: 10px">
                <template slot="append">描述项内容</template>
              </el-input>
            </el-col>
          </el-row>
        </el-card>
        <el-button style="margin:20px auto;width: 80%;display: block" @click="addItem(2)">添加义务</el-button>        
      </div>


      <el-form-item label="地址">
        <template>
          <el-input v-model="curSelData.address" placeholder="请输入地址" ></el-input>
        </template>
      </el-form-item>
      <el-form-item label="网站">
        <template>
          <el-input v-model="curSelData.website" placeholder="请输入网站"></el-input>
        </template>
      </el-form-item>
      <el-form-item label="电话">
        <template>
          <el-input v-model="curSelData.tel" placeholder="请输入电话"></el-input>
        </template>
      </el-form-item>
      <el-form-item label="邮箱">
        <template>
          <el-input v-model="curSelData.mail" placeholder="请输入邮箱"></el-input>
        </template>
      </el-form-item>

      <el-form-item label="背景图片">
            <el-input v-model="curSelData.bgUrl" placeholder="请输入图片地址">
              <template slot="append"><span style="cursor: pointer" @click="selImg('report','bgUrl')">选择图片</span></template>
            </el-input>
          <el-col :span="18">
            <img :src="curSelData.bgUrl" alt="" class="imgClass">
          </el-col>
      </el-form-item>

    </el-form>
  </div>
</template>
<style lang="scss" scoped>
.myRow {
  line-height: 30px;
  font-size: 12px;
  margin: 10px 0;
}
.formsItem{
  > label {
    position: relative;
    top:10px;
  }
}
.imgClass{
  width: 100%;
  margin-top: 5px;
}
</style>
<script>
import BsSlider from '../module/BsSlider'
import BsColorPicker from '../module/BsColorPicker'
export default {
  components: {
    BsSlider,
    BsColorPicker
  },
  props: {
    curSelData: {
      type: Object,
      required: true
    },
  },
  data() {
    return {

    }
  },
  methods: {
    clearColor(attr) {
      this.curSelData[attr] = 'transparent'
    },
    addItem(type) {
      if(type === 1) {
        this.curSelData.rightList.push(
          {
            title: '',
          }
        )
      }else {
        this.curSelData.obligationList.push(
          {
            title: '',
          }
        )
      }
      
    },
    delItem(index, type) {
      if(type === 1) {
        this.curSelData.rightList.splice(index, 1)
      }else {
        this.curSelData.obligationList.splice(index, 1)
      }
    },
    selImg(type, attr) {
      this.$emit('selImg', type, attr)
    },
  }
}
</script>
<style>
.listWrap {
  border: 1px solid #ccc;
  background: #eee;
  border-radius: 5px;
  margin: 20px 0;
  padding: 5px;
}
.listWrap .listTitle {
  font-size: 16px;
  text-align: center;
}
</style>

