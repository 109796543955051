<template>
  <div>
    <div class="block block1" :style="[{ backgroundImage: `url(${item.bgImg1})`},{ backgroundRepeat: 'no-repeat'}, {backgroundSize: '100% 100%'}]">
      <div class="Tit1">{{item.title1}}</div>
      <div class="Tit2">{{item.title1sub}}</div>
      <div v-for="item1 in item.imgsList1" :key="item1.url">
        <img :src="item1.url" class="block1Img" />
      </div>
    </div>
    <div class="block block2" :style="[{ backgroundImage: `url(${item.bgImg2})`},{ backgroundRepeat: 'no-repeat'}, {backgroundSize: '100% 100%'}]">
      <div class="Tit1">{{item.title2}}</div>
      <div class="Tit2">{{item.title2sub}}</div>
      <div v-for="(item2,index) in item.imgsList2" :key="item2.url" :class="'block2ImgBox'+index">
        <img :src="item2.url" class="block2Img" />
      </div>
      <div class="block2Content">
        <div v-for="cItem in item.contentList" :key="cItem.content" class="block2ContentItem">
          {{cItem.content}}
        </div>
      </div>
    </div>

    <div class="block block3" :style="[{ backgroundImage: `url(${item.bgImg3})`},{ backgroundRepeat: 'no-repeat'}, {backgroundSize: '100% 100%'}]">
      <div class="Tit1">{{item.title3}}</div>
      <div class="Tit2">{{item.title3sub}}</div>
      <div class="img3Wrap">
        <div v-for="item3 in item.imgsList3" :key="item3.url" class="block3ImgBox">
          <img :src="item3.url" class="block3Img" />
        </div>
      </div>

      <div>
        <img :src="item.contentImg" class="img3Content" />
      </div>
    </div>

    <div class="block block4" :style="[{ backgroundImage: `url(${item.bgImg4})`},{ backgroundRepeat: 'no-repeat'}, {backgroundSize: '100% 100%'}]">
      <div class="Tit1">{{item.title4}}</div>
      <div class="Tit2">{{item.title4sub}}</div>
      <div class="img4Wrap">
        <!-- <div v-for="(item4,index) in item.imgsList4" :key="item4.url" :class="'block4ImgBox'+index">
              <img :src="item4.url" class="block4Img" />
        </div> -->
        <div class="block4ImgBox0">
          <img :src="item.imgsList4[0].url" class="block4Img" />
        </div> 
        <div class="block4ImgBox1">
          <img :src="item.imgsList4[1].url" class="block4Img" />
        </div> 
        <div>
          <div class="block4ImgBox2"><img :src="item.imgsList4[2].url" class="block4Img" /></div>
          <div class="block4ImgBox3"> <img class="block4Img" :src="item.imgsList4[3].url" /></div>
        </div>
        <div class="block4ImgBox4">
          <img :src="item.imgsList4[4].url" class="block4Img" />
        </div>
         <div class="block4ImgBox5" >
            <img :src="item.imgsList4[5].url" class="block4Img" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {

    }
  }
}
</script>
<style scoped>
.block{
  padding: 0 30px;
}
.block .Tit1{
  text-align: left;
  font-size: 27px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #EBC995;
  padding-top: 65px;
}
.block .Tit2{
  text-align: left;
  font-size: 27px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #fff;
  margin-bottom: 40px;
}
.block1 .block1Img, .block2 .block2Img, .block3 .block3Img, .block4 .block4Img{
  width: 100%;
}
.block2 .block2ImgBox0{
  width: 100%;
  margin-bottom: 5px;
}
.block2 .block2ImgBox1{
  width: 48%;
  display: inline-block;
  margin-right: 4%;
}
.block2 .block2ImgBox2{
  width: 48%;
  display: inline-block;

}
.block2Content {
  margin-top: 35px;
  padding-top: 30px;
  border-top: 1px solid rgba(255,255,255,0.2);
  color: rgba(255,255,255,0.6);
  padding-bottom: 60px;
  text-align: left;
}
.block2Content .block2ContentItem {
  padding-bottom: 20px;
}
.block2Content .block2ContentItem:last-child{
  padding-bottom: 0;
}
.block2Content >>> p{
  text-align: left;
  color: rgba(255,255,255,0.6);
  margin: 0!important;
}
.img3Wrap{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-bottom: 20px;
}
.block3 .block3ImgBox{
  width: 147px;
  height: 210px;
  margin-bottom: 10px;
}
.img3Content{
  width: 100%;
  margin-bottom: 45px;
}

.img4Wrap{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}
.block4 .block4ImgBox0 {
  width: 100%;
  margin-bottom: 15px;

}
.block4 .block4ImgBox1{
  width: 150px;
  height: 219px;
  margin-bottom: 15px;

}
.block4 .block4ImgBox2{
  width: 150px;
  height: 102px;
  margin-bottom: 15px;

}
.block4 .block4ImgBox3{
  width: 150px;
  height: 102px;
  margin-bottom: 15px;

}
.block4 .block4ImgBox4{
  width: 200px;
  height: 160px;
  margin-bottom: 15px;

}
.block4 .block4ImgBox5{
  width: 100px;
  height: 160px;
  margin-bottom: 15px;

}
.block4{
  padding-bottom: 40px;
}
</style>