<template>
	<div class="rBox">
		<el-form label-width="90px">
			<!-- <el-form-item label="风格">
          <template>
            <el-radio v-model="curSelData.scanStyle" :label="1">风格一</el-radio>
            <el-radio v-model="curSelData.scanStyle" :label="2">风格二</el-radio>
          </template>
      </el-form-item> -->
			<el-form-item label="上下边距">
				<template>
					<bs-slider v-model="curSelData.paddTop" :min="0" :max="100" />
				</template>
			</el-form-item>
			<el-form-item label="左右边距">
				<template>
					<bs-slider v-model="curSelData.paddLeft" :min="0" :max="100" />
				</template>
			</el-form-item>
			<el-form-item label="标题">
				<el-input v-model="curSelData.title" placeholder="请输入标题" style="margin-top: 10px">
				</el-input>
			</el-form-item>
			<el-form-item label="标题文字大小">
				<template>
					<bs-slider v-model="curSelData.titleSize" :min="0" :max="100" />
				</template>
			</el-form-item>
			<el-form-item label="标题文字颜色" class="formsItem">
			  <template>
			    <bs-color-picker v-model="curSelData.titleColor" style="float:left;" />
			    <el-button type="primary" class="btnClear" @click="clearColor('titleColor')">清除</el-button>
			  </template>
			</el-form-item>
			<el-form-item label="副标题文字大小">
				<template>
					<bs-slider v-model="curSelData.SubheadingSize" :min="0" :max="100" />
				</template>
			</el-form-item>
			<el-form-item label="副标题文字颜色" class="formsItem">
			  <template>
			    <bs-color-picker v-model="curSelData.SubheadingColor" style="float:left;" />
			    <el-button type="primary" class="btnClear" @click="clearColor('SubheadingColor')">清除</el-button>
			  </template>
			</el-form-item>
			<el-form-item label="小标题文字大小">
				<template>
					<bs-slider v-model="curSelData.SubtitleSize" :min="0" :max="100" />
				</template>
			</el-form-item>
			<el-form-item label="小标题文字颜色" class="formsItem">
			  <template>
			    <bs-color-picker v-model="curSelData.SubtitleColor" style="float:left;" />
			    <el-button type="primary" class="btnClear" @click="clearColor('SubtitleColor')">清除</el-button>
			  </template>
			</el-form-item>
			<el-form-item label="二维码背景">
				<el-input v-model="curSelData.scanBg" placeholder="请输入图片地址">
					<template slot="append"><span style="cursor: pointer"
							@click="selImg('scanFive','scanBg')">选择图片</span></template>
				</el-input>
			</el-form-item>
			<el-form-item label="二维码大小">
				<template>
					<bs-slider v-model="curSelData.scanSize" :min="0" :max="100" />
				</template>
			</el-form-item>
			<el-form-item label="二维码上下边距">
				<template>
					<bs-slider v-model="curSelData.scanTop" :min="0" :max="100" />
				</template>
			</el-form-item>
			<el-form-item label="二维左右边距">
				<template>
					<bs-slider v-model="curSelData.scanLeft" :min="0" :max="100" />
				</template>
			</el-form-item>
			<el-form-item label="背景颜色" class="formsItem">
				<template>
					<bs-color-picker v-model="curSelData.bgColor" style="float:left;" />
					<el-button type="primary" class="btnClear" @click="clearColor('bgColor')">清除</el-button>
				</template>
			</el-form-item>
			<el-form-item label="按钮背景色" class="formsItem">
				<template>
					<bs-color-picker v-model="curSelData.btnBgColor" style="float:left;" />
					<el-button type="primary" class="btnClear" @click="clearColor('btnBgColor')">清除</el-button>
				</template>
			</el-form-item>

			<el-form-item label="背景图片">
				<el-input v-model="curSelData.boxBg" placeholder="请输入图片地址">
					<template slot="append"><span style="cursor: pointer"
							@click="selImg('scanFive','boxBg')">选择图片</span></template>
				</el-input>
				<el-col :span="18">
					<img :src="curSelData.boxBg" alt="" class="imgClass">
				</el-col>
			</el-form-item>
			
			
			<el-form-item label="提示信息文字大小">
				<template>
					<bs-slider v-model="curSelData.myBoxFize" :min="0" :max="100" />
				</template>
			</el-form-item>
			<el-form-item label="提示信息文字颜色" class="formsItem">
				<template>
					<bs-color-picker v-model="curSelData.myBoxColor" style="float:left;" />
					<el-button type="primary" class="btnClear" @click="clearColor('myBoxColor')">清除</el-button>
				</template>
			</el-form-item>
			<el-form-item label="标识码标题大小">
				<template>
					<bs-slider v-model="curSelData.codeTitleFize" :min="0" :max="100" />
				</template>
			</el-form-item>
			<el-form-item label="标识码标题颜色" class="formsItem">
				<template>
					<bs-color-picker v-model="curSelData.codeTitleColor" style="float:left;" />
					<el-button type="primary" class="btnClear" @click="clearColor('codeTitleColor')">清除</el-button>
				</template>
			</el-form-item>
			<el-form-item label="标识码大小">
				<template>
					<bs-slider v-model="curSelData.codeContentFize" :min="0" :max="100" />
				</template>
			</el-form-item>
			<el-form-item label="标识码颜色" class="formsItem">
				<template>
					<bs-color-picker v-model="curSelData.codeContentColor" style="float:left;" />
					<el-button type="primary" class="btnClear" @click="clearColor('codeContentColor')">清除</el-button>
				</template>
			</el-form-item>
			<el-form-item label="签发信息文字大小">
				<template>
					<bs-slider v-model="curSelData.msgBox3Fize" :min="0" :max="100" />
				</template>
			</el-form-item>
			<el-form-item label="签发信息颜色" class="formsItem">
				<template>
					<bs-color-picker v-model="curSelData.msgBox3Color" style="float:left;" />
					<el-button type="primary" class="btnClear" @click="clearColor('msgBox3Color')">清除</el-button>
				</template>
			</el-form-item>
			<el-form-item label="查询信息文字大小">
				<template>
					<bs-slider v-model="curSelData.msgBox4Fize" :min="0" :max="100" />
				</template>
			</el-form-item>
			<el-form-item label="查询信息文字颜色" class="formsItem">
				<template>
					<bs-color-picker v-model="curSelData.msgBox4Color" style="float:left;" />
					<el-button type="primary" class="btnClear" @click="clearColor('msgBox4Color')">清除</el-button>
				</template>
			</el-form-item>
			<el-form-item label="时间地点文字大小">
				<template>
					<bs-slider v-model="curSelData.msgBox5Fize" :min="0" :max="100" />
				</template>
			</el-form-item>
			<el-form-item label="时间地点文字颜色" class="formsItem">
				<template>
					<bs-color-picker v-model="curSelData.msgBox5Color" style="float:left;" />
					<el-button type="primary" class="btnClear" @click="clearColor('msgBox5Color')">清除</el-button>
				</template>
			</el-form-item>
			<el-form-item label="查询次数文字大小">
				<template>
					<bs-slider v-model="curSelData.msgBox6Fize" :min="0" :max="100" />
				</template>
			</el-form-item>
			<el-form-item label="查询次数文字颜色" class="formsItem">
				<template>
					<bs-color-picker v-model="curSelData.msgBox6Color" style="float:left;" />
					<el-button type="primary" class="btnClear" @click="clearColor('msgBox6Color')">清除</el-button>
				</template>
			</el-form-item>
			<el-form-item label="扫码提醒文字大小">
				<template>
					<bs-slider v-model="curSelData.msgBox7Fize" :min="0" :max="100" />
				</template>
			</el-form-item>
			<el-form-item label="扫码提醒文字颜色" class="formsItem">
				<template>
					<bs-color-picker v-model="curSelData.msgBox7Color" style="float:left;" />
					<el-button type="primary" class="btnClear" @click="clearColor('msgBox7Color')">清除</el-button>
				</template>
			</el-form-item>
			
			
			<el-form-item label="验证码">
				<template>
					<el-radio v-model="curSelData.showVerCode" :label="1">不显示</el-radio>
					<el-radio v-model="curSelData.showVerCode" :label="2">显示</el-radio>
				</template>
			</el-form-item>
			<el-form-item label="跳转控件">
				<template>
					<el-radio v-model="curSelData.jumpStyle" :label="1">不显示</el-radio>
					<el-radio v-model="curSelData.jumpStyle" :label="2">风格一</el-radio>
				</template>
			</el-form-item>
			<!--弹窗控件-->
			<el-form-item label="按钮控件">
				<template>
					<el-radio v-model="curSelData.kjStyle" :label="1">风格一</el-radio>
					<el-radio v-model="curSelData.kjStyle" :label="2">不显示</el-radio>
				</template>
			</el-form-item>
			<template v-if="curSelData.kjStyle === 1">
				<el-card v-for="(item,index) in curSelData.picList" :key="item.url+index" class="box-card"
					style="margin-top: 10px">
					<div slot="header" class="clearfix">
						<el-button style="float: right; padding: 3px 0" type="text" @click="delPic(index)">删除
						</el-button>
					</div>
					<el-row>
						<el-col :span="6">
							<el-image :src="item.url" style="display: block;height: 74px;width: 148px;max-width: 90%">
								<div slot="error" class="image-slot">
									<i class="el-icon-picture-outline" />
								</div>
							</el-image>
						</el-col>
						<el-col :span="18">
							<el-input v-model="item.url" placeholder="请输入图片地址">
								<template slot="append"><span style="cursor: pointer"
										@click="selImg('scanFive','picList', index)">选择图片</span></template>
							</el-input>
							<el-input v-model="item.link" placeholder="请输入链接" style="margin-top: 10px">
								<template slot="append">选择链接</template>
							</el-input>
						</el-col>
						<el-input v-model="item.width" placeholder="请输入图片宽度" style="margin-top: 10px">
							<template slot="append">图片宽度</template>
						</el-input>
					</el-row>
				</el-card>
				<el-button style="margin:20px auto;width: 80%;display: block" @click="addItem">添加</el-button>
			</template>

		</el-form>
	</div>
</template>
<script>
	import BsSlider from '../module/BsSlider'
	import BsColorPicker from '../module/BsColorPicker'
	export default {
		components: {
			BsSlider,
			BsColorPicker
		},
		props: {
			curSelData: {
				type: Object,
				// eslint-disable-next-line vue/require-valid-default-prop
				default: () => {}
			}
		},
		data() {
			return {
				
			}
		},
		created() {},
		mounted() {},
		methods: {
			clearColor(attr) {
				if(attr == 'btnBgColor') {
					this.curSelData[attr] = 'transparent'
				}else if(attr == 'myBoxColor') {
					this.curSelData[attr] = '#211F1F'
				}else if(attr == 'myBoxColor1') {
					this.curSelData[attr] = '#211F1F'
				}else if(attr == 'codeTitleColor') {
					this.curSelData[attr] = '#3E362C'
				}else if(attr == 'codeContentColor') {
					this.curSelData[attr] = '#3E362C'
				}else if(attr == 'msgBox3Color') {
					this.curSelData[attr] = '#B49B51'
				}else if(attr == 'msgBox7Color') {
					this.curSelData[attr] = '#B49B51'
				}else if(attr == 'msgBox4Color') {
					this.curSelData[attr] = '#3E362C'
				}else if(attr == 'msgBox5Color') {
					this.curSelData[attr] = '#f00'
				}else if(attr == 'msgBox6Color') {
					this.curSelData[attr] = '#f00'
				}else if(attr == 'titleColor') {
					this.curSelData[attr] = '#000000'
				}else if(attr == 'SubheadingColor') {
					this.curSelData[attr] = '#000000'
				}else if(attr == 'SubtitleColor') {
					this.curSelData[attr] = '#000000'
				}
			},
			selImg(type, attr) {
				this.$emit('selImg', type, attr)
			},
			addItem() {
				const width = (100 / (this.curSelData.picList.length + 1)).toFixed(2)
				this.curSelData.picList.forEach(item => {
					item.width = width + '%'
				})
				this.curSelData.picList.push({
					width: width + '%',
					url: ''
				})
			},
			delPic(index) {
				const width = (100 / (this.curSelData.picList.length - 1)).toFixed(2)
				this.curSelData.picList.forEach(item => {
					item.width = width + '%'
				})
				this.curSelData.picList.splice(index, 1)
			},
		}
	}
</script>
<style scoped>
	.imgClass {
		width: 200px;
		height: auto;
		margin: 15px 0;
	}
</style>
