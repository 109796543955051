<template>
  <div class="rBox">
    <el-form label-width="90px">
      <el-form-item label="背景颜色" class="formsItem">
        <template>
          <bs-color-picker v-model="curSelData.bgColor" style="float: left" />
          <el-button type="primary" class="btnClear" @click="clearColor('bgColor')">清除</el-button>

        </template>
      </el-form-item>
      <el-form-item label="输入框背景" class="formsItem">
        <template>
          <bs-color-picker v-model="curSelData.inputBgColor" style="float: left"/>
          <el-button type="primary" class="btnClear" @click="clearColor('inputBgColor')">清除</el-button>

        </template>
      </el-form-item>
      <el-form-item label="文字颜色">
        <template>
          <bs-color-picker v-model="curSelData.textColor" />
        </template>
      </el-form-item>
      <el-form-item label="图标颜色">
        <template>
          <bs-color-picker v-model="curSelData.iconColor" />
        </template>
      </el-form-item>
     <el-form-item label="提示文字">
        <el-input
          v-model="curSelData.placeholder"
          type="text"
          :rows="2"
          placeholder="请输入内容">
          <el-button slot="append" @click="clearTent">清除</el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="文字对齐">
        <template>
          <el-radio v-model="curSelData.textAlign" label="left">居左</el-radio>
          <el-radio v-model="curSelData.textAlign" label="center">居中</el-radio>
          <el-radio v-model="curSelData.textAlign" label="right">居右</el-radio>
        </template>
      </el-form-item>
      <el-form-item label="搜索框样式">
        <template>
          <el-radio v-model="curSelData.borderRadius" label="0">方形</el-radio>
          <el-radio v-model="curSelData.borderRadius" label="1">圆角</el-radio>
          <el-radio v-model="curSelData.borderRadius" label="2">弧形</el-radio>
        </template>
      </el-form-item>
      <el-form-item label="上下边距">
        <template>
          <bs-slider v-model="curSelData.paddTop" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="左右边距">
        <template>
          <bs-slider v-model="curSelData.paddLeft" :min="0" :max="100" />
        </template>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import BsSlider from '../module/BsSlider'
import BsColorPicker from '../module/BsColorPicker'

export default {
  components: {
    BsSlider,
    BsColorPicker
  },
  props: {
    curSelData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      inputValue: ''
    }
  },
  methods: {
    clearColor(attr) {
      this.curSelData[attr] = 'transparent'
    },
    clearTent() {
      this.curSelData.placeholder = ''
    },
  }
}
</script>
