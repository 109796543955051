<template>
  <div class="rBox">
    <el-form label-width="100px">
      <el-form-item label="距离单位">
          <template>
            <el-radio v-model="curSelData.unit" :label="1">百分比</el-radio>
            <el-radio v-model="curSelData.unit" :label="2">px</el-radio>
          </template>
      </el-form-item>
      <el-form-item label="按钮距左距离">
        <template>
          <bs-slider v-model="curSelData.left" :min="0" :max="500" :unitText="getUnitText(curSelData.unit)"/>
        </template>
      </el-form-item>

      <el-form-item label="按钮距上距离">
        <template>
          <bs-slider v-model="curSelData.top" :min="0" :max="300" :unitText="getUnitText(curSelData.unit)"/>
        </template>
      </el-form-item>
      
      <el-form-item label="按钮风格">
          <template>
            <el-radio v-model="curSelData.btnStyle" :label="1">风格一</el-radio>
            <el-radio v-model="curSelData.btnStyle" :label="2">隐藏</el-radio>
          </template>
      </el-form-item>
      <el-form-item label="按钮名称">
        <template>
          <el-input v-model="curSelData.btnText" placeholder="请输入按钮名称" style="margin-top: 10px"></el-input>
        </template>
      </el-form-item>

       <el-card v-for="(item,index) in curSelData.contents" :key="item.content" class="box-card" style="margin-top: 10px">
        <div slot="header" class="clearfix">
          <el-button style="float: right; padding: 3px 0" type="text" @click="delItem(index)">删除</el-button>
        </div>
        <el-form-item :label="`udi码${index + 1}`">
          <el-input type="input" :rows="3" v-model="item.content" placeholder="请输入内容"/>
        </el-form-item>
      </el-card>
      <el-button style="margin:20px auto;width: 80%;display: block" @click="addItem">添加</el-button>
    </el-form>
    <el-card class="box-card" style="margin-top: 10px">
      <el-row>
        <el-col :span="24">
          <el-input v-model="curSelData.bgImg" placeholder="请输入图片地址">
            <template slot="append"><span style="cursor: pointer" @click="selImg('expertTalk', 'bgImg')">选择图片</span></template>
          </el-input>
        </el-col>
      </el-row>
      <el-row style="margin-top: 20px;">
        <img :src="curSelData.bgImg" style="width: 100%;"/>
      </el-row>
    </el-card>
  </div>
</template>
<script>
import BsSlider from '../module/BsSlider'
export default {
  components: {
    BsSlider
  },
  props: {
    curSelData: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
    }
  },
  methods: {
    delPic(index) {
      const width = (100 / (this.curSelData.picList.length - 1)).toFixed(2)
      this.curSelData.picList.forEach(item => {
        item.width = width + '%'
      })
      this.curSelData.picList.splice(index, 1)
    },
    selImg(type, attr) {
      this.$emit('selImg',type, attr)
    },
    selKf() {
      this.$emit('selKf')
    },
    addItem() {
      let str = '请输入内容' + (this.curSelData.contents.length?  this.curSelData.contents.length+1 : '1')
      this.curSelData.contents.push({
        content: str,
      })
    },
    delItem(index){
      this.curSelData.contents.splice(index, 1)
    },
    getUnitText(unit) {
      let str = ''
      if(unit === 1) {
        str="%"
      }else {
        str = 'px'
      }
      return str
    }
  }
}
</script>
