<template>
  <div class="rBox">
    <el-form label-width="90px">
      <el-form-item label="上下边距">
        <template>
          <bs-slider v-model="curSelData.paddTop" :min="0" :max="100" />
        </template>
      </el-form-item>

      <el-form-item label="按钮名称">
        <template>
          <el-input v-model="curSelData.text" placeholder="请输入按钮名称"></el-input>
        </template>
      </el-form-item>

      <el-form-item label="外部链接">
        <template>
          <el-input v-model="curSelData.url" placeholder="请输入外部链接"></el-input>
        </template>
      </el-form-item>
      <el-form-item label="按钮样式">
          <el-radio-group v-model="curSelData.style">
            <el-radio :label="1">样式一</el-radio>
            <el-radio :label="2">样式二</el-radio>
          </el-radio-group>
        </el-form-item>
    </el-form>

  </div>
</template>

<script>
import BsSlider from '../module/BsSlider'
// import BsColorPicker from '../module/BsColorPicker'
export default {
  components: {
    BsSlider,
    // BsColorPicker
  },
  props: {
    curSelData: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      
    }
  },
  methods: {
   
  }
}
</script>


