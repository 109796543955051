<template>
  <div :style="{backgroundColor: data.bgColor,background:'url('+data.bgImg+')'}">
    <vue-qr :text="data.text" :size="data.size" :margin="0" :style="{margin: data.top+'px '+data.right+'px '+data.bottom+'px '+data.left+'px'}"></vue-qr>
  </div>
</template>
<script>
import vueQr from 'vue-qr'

export default {
  props: {
    data: {
      type: Object
    }
  },
  components: {vueQr},
  computed: {},
  data() {
    return {}
  },
  methods: {}
}
</script>
