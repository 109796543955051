<template>
<div>
  <div class="wineWrap">
    <div class="fDayBox">
      <span class="dayCount">30</span>
    </div>
    <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/da.png" class="tImg" />
    <div class="ydBox">
      <div>
        <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/ydIcon.png" class="ydIcon" />
        <span class="ydtx">异动提醒</span>
      </div>
      <div>
       <span class="ckxq">查看详情</span>
       <img src="" class="jtIcon" />
      </div>
    </div>
    <div class="wineBox">
      <div class="winLine">
        <div class="wineItem wp50">
          <span class="title">位置坐标</span>
          <span class="value">044</span>
        </div>
        <div class="wineItem wp50">
          <span class="title">环境温湿度</span>
          <span class="value">24°,47% </span>
        </div>
      </div>
      
      <div class="winLine">
        <div class="wineItem wp100">
          <span class="title">大气温湿度</span>
          <span class="value">40°,17%</span>
        </div>
      </div>

      <div class="winLine">
        <div class="wineItem wp100">
          <div class="title">实时视频</div>
          <div>
            <img src="" class="ssImg"/>
          </div>
        </div>
      </div>

      <div class="winLine">
        <div class="wineItem wp100">
          <span class="title">酒坛标识</span>
          <span class="value">86.122.2/WA3AdWAEV1</span>
        </div>
      </div>

       <div class="winLine">
        <div class="wineItem wp100">
          <span class="title">客户标识</span>
          <span class="value">张先生</span>
        </div>
      </div>

      <div class="winLine">
        <div class="wineItem wp50">
          <span class="title">封坛时间</span>
          <span class="value">2023.3.25 15:24</span>
        </div>
        <div class="wineItem wp50">
          <span class="title">容量</span>
          <span class="value">400L</span>
        </div>
      </div>

      <div class="winLine">
        <div class="wineItem wp50">
          <span class="title">产地</span>
          <span class="value">中国 茅台镇</span>
        </div>
        <div class="wineItem wp50">
          <span class="title">名称</span>
          <span class="value">窖藏酒</span>
        </div>
      </div>

       <div class="winLine">
        <div class="wineItem wp100">
          <div class="封坛图片">实时视频</div>
          <div>
            <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/tp.png" class="wineImg"/>
          </div>
        </div>
      </div>


    </div>
  </div>  
  <!--详情弹窗-->
  <div class="dialogWrap" v-show="dialogVisible">
    <div class="dialog">
      <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/ydBt.png" class="dialogTit" />
      <div class="jtbh"></div>
      <div class="jtList">

        <div class="ydItem">
          <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/wzIcon.png" class="ydIcon1" />
        </div>

      </div>
    </div>
  </div>
</div>

</template>
<script>

export default {
  data() {
    return {
      dialogVisible: false
    }
  }
}
</script>
<style scoped>
.tImg{
  width: 284px;
  height: 47.5px;
  display: block;
  margin: 0 auto;
}
.ydIcon{
  width: 17px;
  height: 17px;
  margin-right: 8px;
  vertical-align: middle;
}
.ydtx{
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #D85623;
  vertical-align: middle

}
.ydBox{
  width: 100%;
  height: 35px;
  background: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/j1.png') top center no-repeat;
  background-size: 100% 100%;
  margin-bottom: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wineWrap{
  box-sizing: border-box;
  padding: 20px 10px 0;
  position: relative;
}
.wineBox{
  background: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/dk.png') top center no-repeat;
  background-size: 100% 100%;
  min-height: 746px;
  box-sizing: border-box;
  padding: 0 15px;
}
.winLine{
  border-bottom: 1px dashed #BBA68F;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 15px;
  padding-top: 22px;
  text-align: left;
}
.wineBox .title{
  font-size: 12px;
  font-family: SimSun;
  font-weight: 400;
  color: #958265;
  padding-right: 19px;
}
.wineBox .value{
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #423A3A;
}
.wp50{
  width: 50%;
}
.wp100{
  width: 100%;
}
.ckxq{
  font-size: 12px;
  font-family: SimSun;
  font-weight: 400;
  color: #958265;
  padding-right: 5px;
}
.jtIcon{
  width: 6px;
  height: 10px;
  border: 1px solid #958265;
}
.ssImg{
  width: 300px;
  height: 169px;
  display: block;
  margin: 15px auto 0;
}
.wineImg{
  width: 300px;
  height: 117px;
  display: block;
  margin: 15px auto 0;
}
.fDayBox{
  width: 80px;
  height: 80px;
  border: 1px solid #f00;
  position: absolute;
  top:0;
  right: 0;
  z-index: 2;
  transform: rotate(-30deg);
}
.dayCount{
  font-size: 23px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #E00024;
  margin-top: 5px;
  margin-left: 5px;
}

.dialogWrap{
  position: fixed;
  top:0;
  left:0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
}
.dialog {
  width: 285px;
  height: 287px;
  background: #F3EADB;
  border-radius: 10px;
  margin: 200px auto 0;
}
.dialogTit{
  width: 147px;
  height: 51px;
  display: block;
  margin: 10px auto 0;
}
.jtbh{
  font-size: 13px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #AD7C79;
  text-align: center;
  padding-bottom: 13px;
}
.jtList{
  width: 267px;
  height: 192px;
  box-sizing: border-box;
  background: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/bk.png') top center no-repeat;
  padding: 10px;
}
.ydItem{

}
.ydItem .ydIcon1,.ydItem .ydIcon2{
  width: 14px;
  height: 14px;
}
</style>