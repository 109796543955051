<template>
  <div class="rBox">
    <el-form label-width="90px">
      <el-form-item label="风格">
          <template>
            <el-radio v-model="curSelData.scanStyle" :label="1">风格一</el-radio>
            <el-radio v-model="curSelData.scanStyle" :label="2">风格二</el-radio>
          </template>
      </el-form-item>
      <el-form-item label="上下边距">
        <template>
          <bs-slider v-model="curSelData.paddTop" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="左右边距">
        <template>
          <bs-slider v-model="curSelData.paddLeft" :min="0" :max="100" />
        </template>
      </el-form-item>

      <el-form-item label="背景颜色" class="formsItem">
        <template>
          <bs-color-picker v-model="curSelData.bgColor" style="float:left;" />
          <el-button type="primary" class="btnClear" @click="clearColor('bgColor')">清除</el-button>

        </template>
      </el-form-item>
      <el-form-item label="按钮背景色" class="formsItem">
        <template>
          <bs-color-picker v-model="curSelData.btnBgColor" style="float:left;" />
          <el-button type="primary" class="btnClear" @click="clearColor('btnBgColor')">清除</el-button>
        </template>
      </el-form-item>

      <el-form-item label="背景图片">
        <el-col :span="18" v-for="(item,index) in curSelData.picList" :key="item.url">
          <el-input v-model="item.url" placeholder="请输入图片地址">
            <template slot="append"><span style="cursor: pointer" @click="selImg('scanThree','picList',index)">选择图片</span></template>
          </el-input>
        </el-col>
        <el-col :span="18">
          <img :src="item.url" alt="" class="imgClass" v-for="(item,index) in curSelData.picList" :key="item.url+index">
        </el-col>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import BsSlider from '../module/BsSlider'
import BsColorPicker from '../module/BsColorPicker'
export default {
  components: {
    BsSlider,
    BsColorPicker
  },
  props: {
    curSelData: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => {}
    }
  },
  data() {
    return {

    }
  },
  methods: {
    clearColor(attr) {
      this.curSelData[attr] = 'transparent'
    },
    selImg(type, attr, index) {
      this.$emit('selImg', type, attr, index)
    }
  }
}
</script>
<style scoped>
  .imgClass{
    width: 200px;
    height: auto;
    margin: 15px 0;
  }
</style>
