<template>
  <div class="rBox">
    <el-form label-width="90px">
      <el-form-item label="背景颜色" class="formsItem">
        <bs-color-picker v-model="curSelData.bgColor" style="float: left" />
        <el-button type="primary" class="btnClear" @click="clearColor('bgColor')">清除</el-button>
      </el-form-item>

      <el-form-item label="元素高度">
        <template>
          <bs-slider v-model="curSelData.eleHeight" :min="1" :max="150" />
        </template>
      </el-form-item>
    </el-form>
    <!-- <SelIcon ref="SelIcon" @selected="selectedIcon" /> -->
  </div>
</template>
<style>
.btnClear{
  float: left;
  margin-left: 10px;
}
</style>
<script>
import BsSlider from '../module/BsSlider'
import BsColorPicker from '../module/BsColorPicker'
// import SelIcon from '../module/IconShow'
export default {
  components: {
    BsSlider,
    BsColorPicker,
    // SelIcon
  },
  props: {
    curSelData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      inputValue: ''
    }
  },
  methods: {
    chooseIcon() {
      this.$refs.SelIcon.dialogVisible = true
    },
    clearColor(attr) {
      this.curSelData[attr] = 'transparent'
    },
    selectedIcon(val) {
      console.log('val', val)
    },
    changeImg(val) {
      this.$emit('input', val)
      this.inputValue = val
      this.$refs.selImage.dialogVisible = false
    }
  }
}
</script>

