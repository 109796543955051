<template>
	<div class="rBox">
		<el-form label-width="90px">
			<el-form-item label="左右边距">
				<template>
					<bs-slider v-model="curSelData.paddTB" :min="0" :max="100" />
				</template>
			</el-form-item>
			<el-form-item label="上下边距">
				<template>
					<bs-slider v-model="curSelData.paddLR" :min="0" :max="100" />
				</template>
			</el-form-item>
		</el-form>
		
		<el-form label-width="90px">
			<el-form-item label="标识大小">
				<template>
					<bs-slider v-model="curSelData.handleSize" :min="0" :max="50" />
				</template>
			</el-form-item>
			<el-row>
				<el-col :span="24">
					<el-input v-model="curSelData.content" placeholder="请输入文本内容" style="margin-top: 10px">
						<template slot="append">文本内容</template>
					</el-input>
				</el-col>
			</el-row>
			<el-form-item label="文本大小">
				<template>
					<bs-slider v-model="curSelData.contentSize" :min="0" :max="50" />
				</template>
			</el-form-item>
		</el-form>
		
	</div>
</template>
<style lang="scss" scoped>
	
</style>
<script>
	import BsSlider from '../module/BsSlider'
	// import BsColorPicker from '../module/BsColorPicker'
	export default {
		components: {
			BsSlider,
			// BsColorPicker
		},
		props: {
			curSelData: {
				type: Object,
				required: true
			}
		},
		data() {
			return {

			}
		},
		methods: {
			
		}
	}
</script>
