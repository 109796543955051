<template>
  <div>
    <el-input placeholder="请输入图片地址" :value="inputValue" @input="$emit('input',$event)">
      <template slot="append"><span  @click="selImg()" style="cursor: pointer">选择图片</span></template>
    </el-input>
    <SelImage ref="selImage"></SelImage>
  </div>
</template>
<script>
import SelImage from "../module/SelImage";

export  default {
  components: {SelImage},
  props: {
    url: {
      type: String
    }
  },
  computed: {

  },
  data(){
    return {
        inputValue: ''
    }
  },
  mounted() {
    this.inputValue=this.url
  },
  methods:{
     selImg() {
       this.$refs.selImage.dialogVisible=true;
     },
    changeImg(val){
      this.$emit('input',val)
      this.inputValue=val
      this.$refs.selImage.dialogVisible=false
    }
  }
}
</script>
