<template>
  <div class="rBox">
    <el-form label-width="90px">
      <el-form-item label="上下边距">
        <template>
          <bs-slider v-model="curSelData.paddTop" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="左右边距">
        <template>
          <bs-slider v-model="curSelData.paddLeft" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="背景颜色" class="formsItem">
        <template>
          <bs-color-picker v-model="curSelData.bgColor" />
          <el-button type="primary" class="btnClear" @click="clearColor">清除</el-button>
        </template>
      </el-form-item>
      <el-form-item label="字体颜色">
        <template>
          <bs-color-picker v-model="curSelData.textColor" />
        </template>
      </el-form-item>

      <el-form-item label="字体大小">
        <template>
          <bs-slider v-model="curSelData.fontSize" :min="9" :max="30" />
        </template>
      </el-form-item>
      <el-form-item label="字体粗细">
        <template>
          <el-radio v-model="curSelData.weight" label="normal">正常</el-radio>
          <el-radio v-model="curSelData.weight" label="bold">加粗</el-radio>
          <el-radio v-model="curSelData.weight" label="lighter">细</el-radio>
        </template>
      </el-form-item>
      <el-form-item label="对齐方式">
        <template>
          <el-radio v-model="curSelData.align" label="left">左对齐</el-radio>
          <el-radio v-model="curSelData.align" label="center">居中</el-radio>
          <el-radio v-model="curSelData.align" label="right">右对齐</el-radio>
        </template>
      </el-form-item>
      <el-form-item label="选择图标">
        <el-input
          v-model="curSelData.textIcon"
          type="text"
          placeholder="请选择图标">
          <el-button slot="append" @click="chooseIcon">选择图标</el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="文本内容">
        <el-input
          v-model="curSelData.content"
          type="text"
          :rows="2"
          placeholder="请输入内容">
          <el-button slot="append" @click="clearTent">清除</el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <SelIcon ref="SelIcon" @selected="selectedIcon" />
  </div>
</template>
<script>
// import BsSlider from '@/views/custompage/module/BsSlider'
// import BsColorPicker from '@/views/custompage/module/BsColorPicker'

import SelIcon from '../module/IconShow'
export default {
  components: {
    // BsSlider,
    // BsColorPicker,
    SelIcon
  },
  props: {
    curSelData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      inputValue: ''
    }
  },
  methods: {
    clearColor() {
      this.curSelData.bgColor = 'transparent'
    },
    clearTent() {
      this.curSelData.content = ''
    },
    chooseIcon() {
      this.$refs.SelIcon.dialogVisible = true
    },
    selectedIcon(val) {
      console.log('val', val)
      this.curSelData.textIcon = val
    },
    changeImg(val) {
      this.$emit('input', val)
      this.inputValue = val
      this.$refs.selImage.dialogVisible = false
    }
  }
}
</script>
