<template>
    <div>
      <div style="width: 80%;float: left;">
        <el-slider :value="value" :min="min" :max="max" :show-tooltip="false" height="10px" style="display: inline" @input="$emit('input',$event)"></el-slider>
      </div>
       <div style="float: left;margin-left: 10px">{{value}}{{unitText? unitText : 'px'}}</div>
    </div>
</template>
<script>
  export  default {
    props: {
      min: {
        type: Number,
        default() {
          return 0;
        }
      },
      max: {
        type: Number,
        default() {
          return 100;
        }
      },
      value: {
        type: Number
      },
      unitText: {
        type: String,
      }
    },
    computed: {

    },
    data(){
      return {

      }
    },
    methods:{
      change(value){
        this.$emit('input', value)
      }
    }
  }
</script>
