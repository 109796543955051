<template>
  <div class="rBox">
    <el-form label-width="90px">
       <el-card class="box-card" style="margin-top: 10px">
          <div slot="header" class="clearfix">
              商标图片
          </div>
          <el-form label-width="90px">
              <el-row v-for="(item,index) in curSelData.picList" :key="item.url">
                  <el-col :span="6">
                      <el-image :src="item.url"
                          style="display: inline-block;height: 74px;width: 148px;max-width: 90%">
                          <div slot="error" class="image-slot">
                              <i class="el-icon-picture-outline" />
                          </div>
                      </el-image>
                  </el-col>
                  <el-col :span="18">
                      <el-input v-model="item.url" placeholder="请输入图片地址">
                          <template slot="append"><span style="cursor: pointer"
                                  @click="selImg('fyxm', 'picList', index)">选择图片</span></template>
                      </el-input>
                  </el-col>
              </el-row>
              
          </el-form>
      </el-card>
      <el-form-item label="证书图片" class="mt30">
         <el-input v-model="curSelData.zsImg" placeholder="请输入图片地址">
            <template slot="append"><span style="cursor: pointer" @click="selImg('fyxm','zsImg')">选择图片</span></template>
          </el-input>
          <el-col :span="18" class="mt30">
            <img :src="curSelData.zsImg" alt="" class="imgClass">
          </el-col>
      </el-form-item>

      <el-form label-width="90px" label="内容">
          <div>内容项</div>
          <el-card v-for="(item,index) in curSelData.list" :key="item.title" class="box-card"
            style="margin-top: 10px">
            <div slot="header" class="clearfix">
              <el-button style="float: right; padding: 3px 0" type="text" @click="delItem(index)">删除</el-button>
            </div>

           <el-form-item label="标题">
              <el-input v-model="item.title" />
            </el-form-item>
            <el-form-item label="内容">
              <el-input v-model="item.value" />
            </el-form-item>

          </el-card>
          <el-button style="margin:20px auto;width: 80%;display: block" @click="addItem(index)">添加功能项</el-button>
      </el-form>
      <!-- <el-form-item label="商标图片">
         <el-input v-model="curSelData.scanBg" placeholder="请输入图片地址">
            <template slot="append"><span style="cursor: pointer" @click="selImg('fyxm','scanBg')">选择图片</span></template>
          </el-input>
          <el-col :span="18">
            <img :src="curSelData.scanBg" alt="" class="imgClass">
          </el-col>
      </el-form-item>


        <el-form-item label="标题">
            <el-input v-model="curSelData.title" placeholder="请输入标题">
            </el-input>
        </el-form-item>
        <div v-for="item in curSelData.mainPar" :key="item.title">
          <el-form-item label="标题">
              <el-input v-model="item.title" placeholder="请输入标题">
              </el-input>
          </el-form-item>
           <el-form-item label="内容">
              <el-input v-model="item.content" placeholder="请输入内容">
              </el-input>
          </el-form-item>
        </div>
        <div v-for="item in curSelData.subPar" :key="item.title">
          <el-form-item label="标题">
              <el-input v-model="item.title" placeholder="请输入标题">
              </el-input>
          </el-form-item>
           <el-form-item label="内容">
              <el-input v-model="item.content" placeholder="请输入内容">
              </el-input>
           </el-form-item>
        </div>
        <div v-for="(item, index) in curSelData.parDetail" :key="item.title">
          <el-form-item label="模块名称">
            <el-input v-model="item.title" placeholder="请输入模块名称">
            </el-input>
          </el-form-item>
          <el-form-item label="是否折叠">
            <template>
                <el-radio v-model="item.type" :label="1">是</el-radio>
                <el-radio v-model="item.type" :label="2">否</el-radio>
            </template>
          </el-form-item>
          <template v-if="item.type === 1">
             <el-card v-for="(innerItem, innerIndex) in item.list" :key="innerItem.title" class="box-card">
               <div slot="header" class="clearfix" @click="delItem2(index, innerIndex)">删除</div>
                <el-form-item label="第一列">
                  <el-input v-model="innerItem.c1" />
                </el-form-item>
                <el-form-item label="第二列">
                  <el-input v-model="innerItem.c2" />
                </el-form-item>
                <el-form-item label="第三列">
                  <el-input v-model="innerItem.c3" />
                </el-form-item>
             </el-card>
             <el-button style="margin:20px auto;width: 80%;display: block" @click="addItem2(index)">添加一行</el-button>
          </template>
          <template v-else-if="item.type === 2">
           <el-form-item label="模块内容">
              <el-input v-model="item.value" placeholder="请输入模块内容">
              </el-input>
           </el-form-item>
          </template>
        </div>
        <el-button style="margin:20px auto;width: 80%;display: block" @click="addItem">添加一项</el-button>

        -->
    </el-form>
  </div>
</template>
<script>
// import BsSlider from '../module/BsSlider'
// import BsColorPicker from '../module/BsColorPicker'
export default {
  components: {
    // BsSlider,
    // BsColorPicker
  },
  props: {
    curSelData: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => {}
    }
  },
  data() {
    return {

    }
  },
  methods: {
    selImg(type, attr,index) {
      this.$emit('selImg', type, attr, index)
    },
    addItem() {
      this.curSelData.list.push({
        title: '',
        value: ''
      })
    },
    delItem(index) {
        this.curSelData.list.splice(index, 1)
    },
  }
}
</script>
<style scoped>
.imgClass{
  width: 100%;
}
.mt30{
  margin-top: 30px;
}
</style>
