<template>
	<div class="my_select_img_wrap">
		<el-dialog append-to-body :close-on-click-modal="false" :visible="dialogVisible" :before-close="closeDialog"
			title="选择图片" width="500px">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="本地上传" name="local">
					<div class="upWrap">
						<el-form label-width="120px" :inline="true" class="mt20">
							<el-form-item label="">
								<el-select v-model="setClassId" placeholder="请设置上传图片类别" clearable>
									<el-option
											v-for="item in typeList"
											:key="item.id"
											:label="item.name"
											:value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
							<div v-if="ids.length > 0" style="float: right;">
								<el-button v-if="ids.length > 0" size="small" type="primary" @click="deleteList">批量删除
								</el-button>
							</div>
							<el-upload :action="upUrl" :headers="upParams" :data="upData" multiple :show-file-list="false"
								:on-success="handleSuccess" :before-upload="handleBeforeUpload" accept="image/jpeg,image/png" class="upBtn">
								<el-button size="small" type="primary">点击上传</el-button>
							</el-upload>
						</el-form>
						<div class="el-upload__tip">
									只能上传jpg/png文件，且不超过{{singleImgSize ? singleImgSize : '500'}}kb
						</div>
					</div>

					<el-row :gutter="20">
						<el-col :span="24" style="m10">
							<el-select v-model="classId" placeholder="请选择图片类型" clearable @change="classIdChange">
								<el-option
										v-for="item in typeList"
										:key="item.id"
										:label="item.name"
										:value="item.id"
										>
								</el-option>
							</el-select>
							<el-button type="primary" class="myBtn" @click="reset">重置</el-button>
						</el-col>
						<el-col :span="6" v-for="(item,index) in imgList" :key="index" style="height: 120px"
							class="imgBlock">
							<div class="imgBlockBorder" style="height: 120px;" @click="checkImg(index, item)"
								:style="{'border': item.flag ? '1px solid #74d7d5' : '1px solid transparent'}">
								<img :src="ossUrl+item.fileUrl"
									style="max-width: 50px; max-height: 90px;margin: 20px auto 0; display:block;"
									@dblclick="selImage(ossUrl+item.fileUrl)" />
								<div class="imgBlockInner">
									<img src="../../../assets/images/fdj.png" class="fdjIcon"
										@click.stop="handleLookBigImg(ossUrl+item.fileUrl)" />
									<img src="../../../assets/images/lajitong.png" class="ljtIcon"
										@click.stop="handleDelImg(item)" />
								</div>
							</div>
						</el-col>
					</el-row>
					<el-pagination :page-size.sync="params.pageSize" :total="total" :current-page.sync="params.pageNum"
						style="margin-top: 8px;" :page-sizes="pageSizes" layout="total, prev, pager, next, sizes"
						@size-change="pageSizeChange($event)" @current-change="pageChange($event)" />
				</el-tab-pane>
			</el-tabs>
			<!--预览图片弹窗-->
			<el-dialog append-to-body :close-on-click-modal="false" :visible="dialogImgVisible"
				:before-close="closeBigDialog" title="查看大图" width="800px">
				<div class="bigImgBg">
					<img :src="bigSrc" class="bigImg" />
				</div>
			</el-dialog>
		</el-dialog>
	</div>
</template>
<script>
	// import {get} from "@/utils/request";
	import axios from 'axios'
	import {
		fetchListCustom,
		fetchDelImg,
	} from '@/api/customPage'
	import {
		noPage
	} from '@/api/customPageClass'
	
	export default {
		components: {},
		props: {

		},
		data() {
			let token = localStorage.getItem("token")
			return {
				activeIndex: 0,
				ids: [],
				upUrl: this.$store.state.uploadCustomUrl,
				ossUrl: this.$store.state.ossUrl,
				upParams: {
					'token': token,
					type: 'pc'
				},
				
				dialogVisible: false,
				activeName: 'local',
				imgList: [],
				params: {
					pageNum: 1,
					pageSize: 12
				},
				total: 0,
				pageSizes: [12, 16, 20, 24],
				bigSrc: '',
				dialogImgVisible: false,
				singleImgSize: 500,
				setClassId: '',
				classId: '',
				typeList: [],
			}
		},
		mounted() {
			this.init()
			this.singleImgSize = JSON.parse(localStorage.getItem('info')).companyList[0].imageSize

			// 图片类型分类初始化
				noPage({type:1}).then(response=>{
					if (response.data){
						this.typeList = response.data
					}
				})

		},
		computed:{
      upData: function () {
				return {
					classId: this.setClassId
				}
			}
		},
		methods: {
			checkImg(index, item) {
				this.activeIndex = index

				if (this.ids.indexOf(item.id) > -1) {
					item.flag = false
					this.ids.splice(this.ids.indexOf(item.id), 1)
				} else {
					item.flag = true
					this.ids.push(item.id)
				}
				console.log(JSON.stringify(this.ids))
			},
			deleteList() {
				var that = this
				this.$confirm('确定要删除吗?', "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(function() {
					fetchDelImg(that.ids).then(res => {
						that.imgList.map(item=> {
							if(item.flag) {
								that.ids.splice(that.ids.indexOf(item.id), 1)
							}
						})
						console.log(JSON.stringify(that.ids))
						
						that.init()
					})
				})
			},
			handleLookBigImg(src) {
				this.dialogImgVisible = true
				this.bigSrc = src
			},
			handleDelImg(data) {
				var that = this
				this.$confirm('确定要删除吗?', "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(function() {
					let params = []
					params.push(data.id)
					fetchDelImg(params).then(res => {
			 		that.init()
			 	})
				})
			},
			closeBigDialog() {
				this.dialogImgVisible = false
			},
			pageChange(value) {
				console.log('===')
				this.params.pageNum = value
				this.init()
			},
			pageSizeChange(value) {
				this.params.pageSize = value
				this.init()
			},
			selImage(url) {
				// if(this.$parent.curBgFlag) {
				//   this.$parent.changeBgImg(url)
				// }else {
				this.$parent.changeImg(url)
				// }
			},
			closeDialog() {
				this.dialogVisible = false;
				this.ids = []
				this.imgList.map(item=> {
					item.flag = false
				})
			},
			async init() {
				let companyId = localStorage.getItem('info') ? JSON.parse(localStorage.getItem('info')).companyId : ''
				let params = {
					...this.params,
					cId: companyId,
					classId: this.classId
				}
				let result = await fetchListCustom(params)
				if (result.data) {
					this.total = result.data.total
					this.imgList = result.data.list
					this.imgList.map((item, index) => {
						item.flag = false
						this.$set(this.imgList, [index], {
							flag: false,
							createTime: item.createTime,
							fileMd5: item.fileMd5,
							fileUrl: item.fileUrl,
							id: item.id,
							isDel: item.isDel,
							type: item.type,
							userId: item.userId
						})
						if(this.ids.length > 0) {
							this.imgList.map(item=> {
								this.ids.map(i=> {
									if(i == item.id) {
										item.flag = true
									}
								})
							})
						}
						
						console.log(JSON.stringify(this.imgList))
					})
				}
			 
			  
			},
			handleClick(value) {},
			handleSuccess(res, file, fileList) {
				if (res.code !== 200) {
					this.$message({
						message: res.message ? res.message : "上传失败",
						type: "error",
					});
					return
				}
				this.init()
			},
			handleBeforeUpload(file) {
				const is500k = file.size / 1024 < this.singleImgSize; // 限制小于500kb
				if (!is500k) {
					this.$message.error({
						title: '图片上传失败',
						message: `图片大小不可超过${this.singleImgSize}kB`
					})
				}
				return is500k
			},
			classIdChange(data) {
				this.params.pageNum = 1
				this.init()
			},
			reset() {
				this.params.pageNum = 1
				this.classId = ''
				this.init()
			}
		}
	}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	/deep/ .el-upload--text {
		width: 90px;
		height: 36px;
		text-align: center;
		border: none;
		margin-right: 10px;
	}

	/deep/ .el-tabs__nav {
		margin-left: 0 !important;
	}

	.imgBlock {
		position: relative;
	}

	.imgBlock:hover .imgBlockBorder{
		background: rgba(0, 0, 0, 0.5);

	}

	.imgBlock .imgBlockInner {
		// display: none;
	}

	.imgBlock:hover .imgBlockInner {
		// display: block;
		// background: rgba(0,0,0,0.5);
		// position: absolute;
		// top: 0;
		// left:0;
		// right: 0;
		// bottom: 0;
	}

	.fdjIcon {
		position: absolute;
		right: 10px;
		top: 5px;
		width: 20px;
		height: 20px;
	}

	.ljtIcon {
		position: absolute;
		right: 10px;
		top: 30px;
		width: 20px;
		height: 20px;
	}

	.bigImg {
		max-width: 100%;
		margin: 0 auto;
		display: block;
	}

	.bigImgBg {
		width: 100%;
		background: #efefef;
	}

	.borderStyle {
		border: 1px solid #e6e6e6
	}

	.upBtn{
		float: right;
	}
	.mt20{
		margin-top: 20px;
	}
	.m10{
		margin-top: 10px;
		margin-bottom: 20px;
	}
	/deep/ .el-dialog__body{
		padding-top: 0;
	}
	.upWrap{
		border: 1px dashed #ccc;
		border-radius: 10px;
		margin: 10px 0 20px;
		padding: 10px 10px 20px;
	}
	/deep/ .upload__tip{
		margin-top: 0;
	}
</style>
