<template>
 <div class="my_select_img_wrap">
  <el-dialog
    append-to-body
    :close-on-click-modal="false"
    :visible="dialogVisible"
    :before-close="closeDialog"
    title="选择视频"
    width="900px"
  >
        <el-button @click="showAddDialog">添加视频</el-button>
        <el-row :gutter="20" >
          <el-table :data="videoList">
            <el-table-column
              prop="fileUrl"
              label="视频"
              align="center"
            >
              <template slot-scope="scope">
                <video :src="scope.row.url" style="max-width: 300px;max-height: 110px;margin: 10px"/>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="操作"
            >
              <template slot-scope="scope">
                <el-button @click="selVideo(scope.row.url)">选择</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-pagination
          :page-size.sync="params.pageSize"
          :total="total"
          :current-page.sync="params.pageNum"
          style="margin-top: 8px;"
          :page-sizes="params.pageSize"
          layout="total, prev, pager, next, sizes"
          @size-change="pageSizeChange($event)"
          @current-change="pageChange($event)"
        />
  </el-dialog>

  <!---添加视频-->
   <el-dialog
      title="添加视频"
      :visible.sync="centerDialogVisible"
      :close-on-click-modal="false"
      @open="openDialog"
      @close="close"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      width="50%"
      center
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
      >
        <el-form-item label="视频名称" prop="title">
          <el-input v-model="ruleForm.title"></el-input>
        </el-form-item>
        <!-- <el-form-item label="添加位置" prop="address">
          <el-input id="suggestId" v-model="ruleForm.address"></el-input>
          <div id="baidumap"></div>
        </el-form-item> -->
        <el-form-item label="视频内容" prop="url">
          <el-upload
            class="avatar-uploader myAvatar-uploader"
            :action="$store.state.uploadingUrl"
            :before-upload="handleBeforeUpload"
            :headers="upParams"
            :show-file-list="true"
            accept=".mp4"
            :on-progress="handProgress"
            :on-success="handleAvatarSuccessVideo"
            :on-remove="handleRemoveVideo"
            :limit="1"
            :file-list="fileVideoList"
          >
           <template v-if="ruleForm.url">
              <video
                :src="ruleForm.url"
                width="100%"
                height="100%"
                controls
              >
                <source src="movie.mp4" type="video/mp4" />
              </video>
           </template>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              只能上传MP4文件，且不超过{{singleVideoSize}}M
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="视频封面" prop="coverImg">
          <el-upload
            :class="{
              'avatar-uploader': true,
              'avatar-coverImg': true,
              disabled: uploadDisabled,
            }"
            :action="$store.state.uploadingUrl"
            :headers="upParams"
            list-type="picture-card"
            :on-progress="handProgress"
            :before-upload="handleBeforeImgUpload"
            :on-success="handleAvatarSuccessCoverImg"
            :on-remove="handleRemove"
            :file-list="fileList"
            accept="image/jpeg,image/png"
            :limit="1"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              上传图片的最佳尺寸：750像素*750像素，其他尺寸会影响页面效果，格式png，jpeg，jpg。大小不超过{{singleImgSize}}KB
            </div>
          </el-upload>
        </el-form-item>
        <!-- <el-form-item label="推荐商品" prop="suggestions">
          <el-input
            v-model="ruleForm.suggestions"
            maxlength="15"
            placeholder="请输入商品推荐语"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button
            type="primary"
            style="margin-left: 0px; margin-bottom: 30px"
            @click="dialogDetailVisible = true"
            >商品选择</el-button
          >
        </el-form-item> -->
        <el-form-item label="">
          <div
            class="d-flex w220 justify-content-between align-items-center select-goods-list"
            v-for="(item, index) in ruleForm.videoGoods"
            :key="index"
          >
            <div>{{ item.name }}</div>
            <div>￥{{ item.price }}</div>
            <i class="el-icon-delete" @click="goodsDelete(index)"></i>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine"
          >确 定</el-button
        >
      </span>
    </el-dialog>

 </div>
</template>

<script>
// import { serverUrl } from '@/utils/server.js'
import { fetchVideoList } from '@/api/customPage'
import {
  add,
} from "@/api/videoManage";
let ac;
export default {
  components: {  },
  data() {
    let token = localStorage.getItem('token')
    return {
      upParams: {
        token: token
      },
      dialogVisible: false,
      VideoSource: null,
      // uploadImgUrl: serverUrl.baseURL + 'file/uploadObjectOSS', // 上传的视频服务器地址
      // ossUrl: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/',
      uploadImgUrl: this.$store.state.uploadingUrl, // 上传的视频服务器地址
      ossUrl: this.$store.state.ossUrl,
      headers: {
        type: 'admin',
        token: token,
      },
      activeName: 'local',
      params: {pageNum: 1,pageSize: 12},
      total:0,
      videoList: [],

      // 添加视频
      centerDialogVisible: false,
      ruleForm: {
        title: "",
        address: "--",
        url: "",
        coverImg: "",
        suggestions: "",
        videoGoods: [],
        videoId: "",
        type: 3,
      },
      rules: {
        title: [{ required: true, message: "请输入视频名称", trigger: "blur" }],
        // address: [
        //   { required: true, message: "请输入添加位置", trigger: "blur" },
        // ],
        url: [{ required: true, message: "请上传视频内容" }],
        coverImg: [{ required: true, message: "请上传视频封面" }],
      },
      fileList: [],
      fileVideoList: [],
      singleVideoSize: 20,
      singleImgSize: 500,
    }
  },
  mounted() {
     this.init()
  },
  methods: {
    // init(){
    //   // this.VideoSource='https://mzmpic.oss-cn-beijing.aliyuncs.com/bolaiya_video.mp4'
    // },
    showAddDialog() {
      this.centerDialogVisible = true
      this.dialogVisible = false
      this.fileList = []
      this.fileVideoList = []
    },
    async init(){
      let result = await fetchVideoList(this.params)
      if(result.data) {
        this.total = result.data.total
        this.videoList = result.data.list
      }
      this.singleVideoSize =JSON.parse(localStorage.getItem('info')).singleVideoSize
      this.singleImgSize =JSON.parse(localStorage.getItem('info')).companyList[0].imageSize
    },
    selVideo(url) {
      this.VideoSource = url
      this.$emit('getVideo', this.VideoSource)
      this.closeDialog()
    },
    closeDialog(){
      this.dialogVisible=false;
    },
    handleUploadSuccess(res) {
      // todo $emit
      this.VideoSource = this.ossUrl +res.data
    },
    handleUploadError() {
      // this.src = 'https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/crm/VR.png'
      this.$message({
        type: "error",
        message: "上传失败",
      });
    },
    // removeImage() {
    //   // todo 删除接口
    //   this.VideoSource = null;
    // },
    confirmVideo() {
      if(!this.VideoSource) {
        this.$message({
        type: "warning",
          message: "请上传视频",
        });
        return
      }
      this.$emit('getVideo', this.VideoSource)
      this.closeDialog()
    },
    handleClick(value){
    },
    beforeVideoUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
          this.$message.error('上传视频不能超过20MB!');
      }
      return isLt20M;
    },
    pageChange(value){
      this.params.pageNum=value
      this.init()
    },
    pageSizeChange(value){
      this.params.pageSize=value
      this.init()
    },
    beforeDestroy() {
			ac.removeEventListener("onConfirm", this.setValue);
		},
    // 添加视频
    /**@method 模态框打开时的回调 */
    openDialog() {
      let map = new BMap.Map("baidumap");
				ac = new BMap.Autocomplete({
					//建立一个自动完成的对象
					input: "suggestId",
					location: map,
				});
				ac.addEventListener("onConfirm", this.setValue);
    },
    setValue(e) {
				const {
					item: {
						value
					},
				} = e;
				this.ruleForm.address = `${value.city}${value.district}${value.business}`;
			},
    /**@method 模态框关闭的回调 */
    close() {
      this.ruleForm = {
        title: "",
        address: "",
        url: "",
        coverImg: "",
        suggestions: "",
        videoGoods: [],
        videoId: "",
        type: 3,
        isUpdate: false,
      }
    },
    handleBeforeUpload(file) {
      let is20M = file.size / 1024 / 1024 < this.singleVideoSize
      if (!is20M) {
        this.$message.error({
          title: '视频上传失败',
          message: `视频大小不可超过${this.singleVideoSize}M`
        })
      }
      return is20M
    },
    handProgress(event, file, fileList) {
				if (this.isUpdate) {
					this.$message.closeAll();
					// this.$message({
					// 	message: "正在上传",
					// });
				}
				this.isUpdate = false;

			},
    /**@method 添加视频 */
    determine() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            if (this.ruleForm.suggestions == "") {
              delete this.ruleForm.suggestions;
            }
            let data = {
              ...this.ruleForm,
              link: "",
            };
            // this.$message({
            //   message: "正在保存",
            // });
            await add(data);
            this.centerDialogVisible = false;
            this.dialogVisible = true
            this.$message({
              message: "添加成功",
              type: "success",
            });
            this.init()
          } catch (error) {
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
            this.dialogVisible = false
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleBeforeImgUpload(file) {
      const is500k = file.size / 1024 < this.singleImgSize; // 限制小于500kb
      if (!is500k) {
        this.$message.error({
          title: '图片上传失败',
          message: `图片大小不可超过${this.singleImgSize}kB`
        })
      }
      return is500k
    },
    handleAvatarSuccessCoverImg(res, file, fileList) {
      if(res.code === 200) {
        this.$message({
          message: "上传成功",
          type: "success",
        });
        this.ruleForm.coverImg = `https://mzmpic.oss-cn-beijing.aliyuncs.com/${res.data}`;
        this.fileList = fileList
      }else {
        this.$message({
          message: res.message ? res.message : "上传失败",
          type: "error",
        });
        this.fileList = fileList.pop()
      }

    },
    /**@method 上传成功 */
    handleAvatarSuccessVideo(res, file, fileList) {
      this.$message.closeAll();
      if(res.code === 200) {
        this.$message({
          message: "上传成功",
          type: "success",
        });
        this.isUpdate = true;
        this.ruleForm.url = `https://mzmpic.oss-cn-beijing.aliyuncs.com/${res.data}`; 
        this.fileVideoList = fileLis 
      }else {
        this.$message({
          message: res.message ? res.message : "上传失败",
          type: "error",
        });
        this.isUpdate = false
        this.ruleForm.url = '';
        this.fileVideoList = fileList.pop()
      }

    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.ruleForm.coverImg = "";
    },
    handleRemoveVideo() {
      this.ruleForm.url = ""
    }
  },
  watch: {
    dialogVisible (val) {
      if(!val) {
        this.VideoSource = null
      }
    }
  },
  computed: {
    uploadDisabled() {
      return this.ruleForm.coverImg !== "";
    },
  }
}
</script>

<style lang="scss" scoped>
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 78px;
		height: 78px;
		line-height: 180px;
		text-align: center;
	}

	/deep/ .avatar-coverImg .el-upload--text {
		width: 100px;
		height: 100px;
	}

	/deep/ .avatar-coverImg .avatar-uploader-icon {
		line-height: 100px;
	}

	.select-goods-list {
		margin-bottom: 20px;
		border-radius: 40px;
		box-sizing: border-box;
		padding: 0 20px;
		background: #ffffff;
		transition: all 0.4s;
	}

	.select-goods-list:hover {
		background: #f5f5f6;
		transition: all 0.4s;
		cursor: pointer;
	}

	/deep/ .disabled .el-upload--picture-card {
		display: none;
	}
</style>
