<template>
  <div class="rBox">
    <el-form label-width="90px">
      <el-form-item label="上下外边距">
        <template>
          <bs-slider v-model="curSelData.marginTop" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="左右外边距">
        <template>
          <bs-slider v-model="curSelData.marginLeft" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="上下内边距">
        <template>
          <bs-slider v-model="curSelData.paddTop" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="左右内边距">
        <template>
          <bs-slider v-model="curSelData.paddLeft" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="字体颜色">
        <template>
          <bs-color-picker v-model="curSelData.textColor" />
        </template>
      </el-form-item>

      <el-form-item label="字体大小">
        <template>
          <bs-slider v-model="curSelData.fontSize" :min="9" :max="30" />
        </template>
      </el-form-item>
      <el-form-item label="字体粗细">
        <template>
          <el-radio v-model="curSelData.fontWeight" label="normal">正常</el-radio>
          <el-radio v-model="curSelData.fontWeight" label="bold">加粗</el-radio>
          <el-radio v-model="curSelData.fontWeight" label="lighter">细</el-radio>
        </template>
      </el-form-item>
      <el-form-item label="对齐方式">
        <template>
          <el-radio v-model="curSelData.align" label="left">左对齐</el-radio>
          <el-radio v-model="curSelData.align" label="center">居中</el-radio>
          <el-radio v-model="curSelData.align" label="right">右对齐</el-radio>
        </template>
      </el-form-item>
      <el-form-item label="文本内容">
        <el-input
          v-model="curSelData.content"
          type="text"
          :rows="2"
          placeholder="请输入内容">
          <el-button slot="append" @click="clearTent">清除</el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="底边框显示">
        <template>
          <el-radio v-model="curSelData.isShowLine" :label="true">显示</el-radio>
          <el-radio v-model="curSelData.isShowLine" :label="false">隐藏</el-radio>
        </template>
      </el-form-item>
      <template v-if="curSelData.isShowLine == true">
        <el-form-item label="底边框颜色" class="formsItem">
          <template>
            <bs-color-picker v-model="curSelData.lineColor" />
          </template>
        </el-form-item>
      </template>

    </el-form>
  </div>
</template>
<script>
import BsSlider from '../module/BsSlider'
import BsColorPicker from '../module/BsColorPicker'

// import SelIcon from '@/views/custompage/module/IconShow'
export default {
  components: {
    BsSlider,
    BsColorPicker,
    // SelIcon
  },
  props: {
    curSelData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      inputValue: ''
    }
  },
  methods: {
    clearColor() {
      this.curSelData.bgColor = 'transparent'
    },
    clearTent() {
      this.curSelData.content = ''
    },
    chooseIcon() {
      this.$refs.SelIcon.dialogVisible = true
    },
    selectedIcon(val) {
      console.log('val', val)
      this.curSelData.textIcon = val
    },
    changeImg(val) {
      this.$emit('input', val)
      this.inputValue = val
      this.$refs.selImage.dialogVisible = false
    }
  }
}
</script>
