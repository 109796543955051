<template>
  <div class="telBtn1" v-if="item.style == 1" :style="{margin: `${item.paddTop}px auto`}">
    {{item.text}}
  </div>
</template>
<script>

export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
    },
    methods: {

    }
}
</script>
<style scoped>
.telBtn1{
  background: linear-gradient(90deg, #009DEE, #13EACE);
  border-radius: 45rpx;
  width: 90%;
  text-align: center;
  font-family: Source Han Sans CN;
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  border-radius: 30px;
  padding: 10px 0;
}
</style>