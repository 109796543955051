<template>

    <div class="ewmBoxWrap" :style="[{backgroundColor:data.boxColor}]">
       <div class="subTitle">{{data.subTitle}} </div>
       <div class="ewmBoxTitle">{{data.title}}</div>
       <div class="ewmBox">
         <!--左边-->
          <div class="ewmLeftBox2" :style="{backgroundImage: `url(${data.scanBg})`}">
            <div class="ewmImgBox2">
              <vue-qr
                :size="data.scanSize"
                :margin="0"
                :auto-color="true"
                :dot-scale="1"
                :text="codeUrl"
                :style="[{marginLeft: `${data.marginLeft}px`},{marginTop: `${data.marginTop}px`}]"
              />
            </div>
          </div>
        <!--右边-->
        <div class="ewmRightBox">
          <div class="chinaTitle">国家标识 专利防伪</div>
          <div class="veridicalTitle"> 防伪验证 </div>
          <div class="myVerBox">
            <input v-model="veridicalCode" placeholder="请输入验证码验证" />
            <button @click="toVeridical">验证</button>
          </div>
          <div style="height: 35px;">
            <div class="errTip" v-if="errTip">请输入验证码进行验证</div>
            <!--验证结果-->
            <div class="verRes">
              <div v-if="verState === 1">
                <span class="red">您好，验证成功，您购买的是正品,请放心使用！</span>
              </div>
              <div v-if="verState === 2">
                <span class="red">您好，验证码无效，请谨防假冒！</span>
              </div>
            </div>
          </div>
        </div>
       </div>
       <!--提示信息-->
        <div class="myboxWrap">
          <div class="myBox1">
            <span>1</span>对比实物标签与图片
          </div>
          <div class="myBox2">
            <span>2</span>观察图片中的文字、位置、编码是否相符
          </div>
          <div class="myBox3">以上特征均相符，则是真品，否则谨防假冒</div>
        </div>
        
        <div class="msgBoxWrap">
          <div class="style2_msgBox2">
            <img src="https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/interImg.png" />
            <div class="rightContent">
              <p class="rightText1">工业互联网标识码：</p>
              <p class="rightText2">{{handle? handle : '---'}}</p>
            </div>
          </div>
          <div class="style2_msgBox3">由国家工信部授权工业互联网标识解析二级节点（方圆标志认证）签发</div>

          <div class="style2_msgBox4">
            <span class="tit">上次查询时间为：</span>
            <span class="">-----</span>
          </div>

          <div class="style2_msgBox5">
            <span class="tit">上次查询地点为：</span>
            <span class="">-----</span>
          </div>

        </div>

        <div class="style2_msgBox6">
          现在是第<span class="countNum">-</span>次查询
          <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/tb_fdj%402x.png" />
        </div>
        <div class="style2_msgBox7">*若查询扫码次数过多，标识码可能被盗用，谨防假冒产品！</div>
      </div>

</template>

<script>

import VueQr from 'vue-qr'
// import $ from 'jquery'
export default {
  name: 'ScanFive',
  components: {
    VueQr
  },
  props: {
    data: {
      type: Object
    },
    verState: Number,
    handleLink: String,
    handle: String,
    query: {
      type: String
    },
    h: {
      String
    },
    codeData:{
      type: Object
    }
  },
  data() {
    return {
      codeUrl: '',
      veridicalCode: '', // 防伪码
      errTip: false,
      // verState: 0, // 0 未验证 1 验证成功 2 验证失败
      txmDialogVisible: false,
    }
  },
  methods: {
    setCode() {
      if(this.handleLink) {
        this.codeUrl = this.handleLink
      }else {
        this.codeUrl = 'https://api.86122m.com/h?h=aaamz'
      }
    },
    // 点击验证
    toVeridical() {
      if(!this.veridicalCode.trim()) {
        this.errTip = true
        this.$emit('veridicalCode', this.veridicalCode)
      }else {
        this.errTip = false
        // 调接口
        this.$emit('veridicalCode', this.veridicalCode)
      }
    },
    openTxmDialog() {
      this.txmDialogVisible = true
    },
    closeTxmDialog() {
      this.txmDialogVisible = false
    }
  },
  mounted() {
    this.setCode()
  },
  computed: {
    imgStyle(picItem,item) {
      return (n, item) => {
        var wid
        var pos
        var str
        // pos = item.align
        if (n.width) {
          wid = n.width
        } else {
          wid = '100%'
        }
        str = 'width: ' + wid
        if (pos === 'left') {
          console.log('pos', pos)
          str = `width: ${wid};float: ${pos}`
        } else if (pos === 'right') {
          console.log('pos', pos)
          str = `width: ${wid};float: ${pos}`
        } else if (pos === 'center') {
          console.log('pos', pos)
          str = `width: ${wid};margin: 0 auto;`
        }
        return str
      }
    }
  }
}
</script>

<style scoped>

  .ewmBoxWrap {
    width: 100%;
    padding-top: 27px;
    height: auto;
    /* background: url('https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/lan/backHe.png'); */
    background-size: cover;
    position: relative;
  }
  .subTitle{
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #E3E9DF;
  }
  .ewmBoxTitle{
    font-size: 25px;
    text-align: center;
    padding: 12px 0 40px;
    color: #FFFFFF;
  }
  .ewmBox{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0px 10px;
  }

  .ewmRightBox{
    display: border-box;
  }
  .ewmRightBox input {
	border: 1px solid #838a9b;
	    border-radius: 5px;
	    display: inline-block;
	    width: 68%;
	    height: 30px;
	    line-height: 30px;
	    padding-left: 5px;
	    font-size: .6rem;
	    text-align: left;
	    padding-right: 50px;
	    /* display: border-box; */
	    border-right: none;
  }
  .ewmRightBox button{
    width: 50px;
    height: 27px;
    text-align: center;
    line-height: 27px;
    font-size: 0.6rem;
    background: #0b667c;
    color: #fff;
    /* margin: 5px 0; */
    border-radius: 5px;
    display: inline-block;
    outline: none;
    border: none;
  }
  .errTip{
    font-size: 12px;
    color: #f00;
    text-align: left;
    line-height: 20px;
    padding-left: 10px;
  }
  .verRes{
    font-size: 12px;
    color: #f00;
    text-align: left;
    line-height: 20px;
    padding-left: 10px;
    overflow: hidden;
  }
  .veridicalTitle{
    font-size: 12px;
    text-align: center;
    position: relative;
    /* font-weight: 700; */
    width: 80%;
    margin: 0 auto  20px;
    color: #fff;
  }
  .veridicalTitle:before, .veridicalTitle:after {
    content: '';
    position: absolute;
    top: 50%;
    background: #fff;
    width: 16%;
    height: 1px;
  }
  .veridicalTitle:before{
    left: 10%;
  }
  .veridicalTitle:after {
    right: 10%;
  }
  .chinaTitle{
    font-size: 16px;
    color: #FFF;
    text-align: center;
    /* font-weight: bolder; */
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 18px;
  }

  /**风格一**/
  .ewmLeftBox{
    width: 43%;
    margin-left: 2%;
    margin-right: 2%;
    background: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/template/bq2%402x.png');
    background-size: 100% 100%;
    height: 210px;
    min-height: 170px; 
    display: border-box;
    margin-top: 20px;
  }
  .ewmLeftBox .ewmImgBox {
    margin-top: 80px;
  }
 .topMsg p{
    text-align: left;
    line-height: 30px;
    color: #000;
    padding-left: 15px;
    background-color: #c8d6da;
    width: 96%;
    font-size: 0.66rem;
    margin: 6px auto;
  }
  .topMsg p span {
    color: #055167;
  }
  .centerMsg p{
    text-align: left;
    font-size: 0.7rem;
    line-height: 30px;
    padding-left: 10px;
    color: #000;
  }
  .centerMsg p img{
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-right: 5px;
  }
  .centerMsg p span{
    color: #055167;
    font-size: 1rem;
  }
  .tipsBox
  {
    margin-top: 10px;
    padding-top: 10px;
    padding-left: 20px;
    background: url('https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/lan/bjKuang.png') top left no-repeat;
    background-size: 100% 100%;
  }
  .tipsBox p {
    font-size: 0.66rem;
    color: #fff;
    text-align: left;
    line-height: 30px;
    padding-left: 10px;
  }
  .tipsBox p img {
    width: 25px;
    height: 25px;
    vertical-align: top;
  }
  .tipsBox .tipText{
    font-weight: bolder;
  }
  .qklBox {
    margin-top: 10px;
    background: url('https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/lan/bjQkl.png') top left no-repeat;
    background-size: 100% 100%;
    padding-left: 20px;
    padding-top: 20px;
  }
  .qklBox .box1{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
  }
  .qklBox .box1 img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    vertical-align: top;
  }
  .qklRightBox .qklMsg1{
    color: #fff;
    font-size: 0.6rem;
    text-align: left;
  }
  .qklRightBox .qklMsg2{
    color: #fff;
    font-weight: 700;
     font-size: 0.6rem;
     text-align: left;
  }
  .qklBox .box2{
    font-size: 12px;
    color: #fff;
    line-height: 20px;
    width: 98%;
    padding: 5px 0 10px;
  }
  /***风格二***/
  .ewmLeftBox2{
    width: 154px;
    height: 103px;
    /* background: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/template/bq2%402x.png'); */
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: border-box;
    box-sizing: border-box;
    margin-right: 13px;
  }
  .codeHandle{
    font-size: 8px;
    color: #000;
    text-align: left;
    margin-left: 50%;
    width: 50%;
    word-break: break-all;
  }
  .ewmLeftBox2 .yzCode {
    text-align: center;
    font-size: 14px;
    margin-top: 14px;
  }
  .ewmLeftBox2 .ewmImgBox2 {
    /* margin-top: 90px; */
  }
  .ewmLeftBox .ewmImgBox2 .ewmImgCode2{
    text-align: center;
    font-size: 12px;
    color: #333;
    line-height: 25px;
  }
  .style2_msgBox1{
    font-size: 14px;
    font-weight: 400;
    color: #211F1F;
    text-align: left;
    margin-bottom: 20px;
  }
  .style2_msgBox1 img {
    width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
  }
  .style2_msgBox1 .tipMsg{
    font-size: 12px;
    font-weight: 500;
    color: #211F1F;
    padding-left: 10px;
  }
  .style2_msgBox2{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
    border-bottom: 1px solid rgba(255,255,255,0.3);
    width: 80%;
    position: relative;
    margin-bottom: 12px;
  }
  .style2_msgBox2 img {
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }
  .rightContent{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
  }
  .style2_msgBox2 .rightContent .rightText1{
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
  }
  .style2_msgBox2 .rightContent .rightText2{
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #365223;
  }
  .style2_msgBox3{
    font-size: 10px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #365223;
    text-align: left;
  }
  .style2_msgBox4, .style2_msgBox5{
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #365223;
    text-align: left;
    padding-top: 5px;
  }
  .style2_msgBox4 .tit, .style2_msgBox5 .tit{
    color: #fff;
  }

  .style2_msgBox6{
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    text-align: left;
    width: 356px;
    margin: 0 auto;
  }
 
  .style2_msgBox6 img {
    width: 15px;
    height: px;
    vertical-align: middle;
    margin-left: 8px;
  }
  .style2_msgBox6 .countNum{
    font-size: 24px;
    color: #F2C52A;
    padding: 0 5px;
  }

  .style2_msgBox7{
     width: 356px;
    margin: 0 auto 0px;
    font-size: 10px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #365223;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 47px;
  }



  .myBox1{
    font-size: 12px;
    color: #FFFFFF;
    text-align: left;
    vertical-align: middle;
    padding: 0px 0 10px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 18px;

  }
  .myBox2{
    font-size: 12px;
    color: #FFFFFF;
    text-align: left;
    vertical-align: middle;
    padding: 0px 0 10px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 18px;
  }

  .myBox3{
    font-size: 12px;
    color: #FFFFFF;
    text-align: left;
    vertical-align: middle;
    padding: 0px 0 10px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    line-height: 18px;
    
  }
  .myBox1 span, .myBox2 span{
    font-size: 18px;
    color: #D9EACE;
    padding-right: 5px;
    line-height: 18px;
    font-weight: 500;
    vertical-align: middle;

  }
  .msgBoxWrap{
    /* border: 1px dashed #CCCCCC; */
    /* border-radius: 10px; */
    /* padding: 5px; */
    /* width: 100%; */
    background: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/gxbBg.png') top center no-repeat;
    background-size: 100% 100%;
    width: 356px;
    margin: 0 auto 12px;
    box-sizing: border-box;
    padding: 15px;
  }
  .myVerBox{
    position: relative;
    width: 90%;
    text-align: left;
    margin: 0 auto;
  }
  .myVerBox input{
    background: transparent;
    border-color: #fff;
    color: #fff;
  }
    .myVerBox input::placeholder{
      color: #fff;
    }
  .myVerBox button {
    position: absolute;
    right: -1px;
    top: 0px;
    height: 33px;
    background-color: #3F9C4C;
  }

  /**弹窗控件**/
    .txmBtn{
      width: 95px;
      height: 112px;
      position: absolute;
      right: 0;
      bottom: 10px;
    }
    .txmInner{
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      flex-wrap: wrap;
    }
    .txmDialog{
      position: absolute;
      top: 0;
      left:0;
      right: 0;
      bottom: 0;
      background: rgba(0,0,0,0.5);
    }
    .closeImg{
      position: absolute;
      top: 5px;
      right: 5px;
      width: 20px;
      height: 20px;
      overflow-y: scroll
    }
    .txmInner{
      padding-top: 30px;
    }
    .txmInner img {
      max-width: 100%;
    }
    .style2_msgBox2 .jumpButton1{
      position: absolute;
      right: -20%;
      top: 0;
      width: 136px;
      height: 42px;
    }
</style>
