<template>
	<div class="rBox">
		<el-form label-width="90px">
			<el-form-item label="标题1">
				<el-input v-model="curSelData.sTitle" type="text" placeholder="请输入标题">
				</el-input>
			</el-form-item>
			<el-form-item label="字体颜色">
				<template>
					<bs-color-picker v-model="curSelData.scolor" />
				</template>
			</el-form-item>
			<el-form-item label="字体大小">
				<template>
					<bs-slider v-model="curSelData.sfontSize" :min="8" :max="50" />
				</template>
			</el-form-item>
			<el-form-item label="标题2">
				<el-input v-model="curSelData.bTitle" type="text" placeholder="请输入标题">
				</el-input>
			</el-form-item>
			<el-form-item label="字体颜色">
				<template>
					<bs-color-picker v-model="curSelData.bcolor" />
				</template>
			</el-form-item>
			<el-form-item label="字体大小">
				<template>
					<bs-slider v-model="curSelData.bfontSize" :min="8" :max="50" />
				</template>
			</el-form-item>
			<el-card v-for="(item, index) in curSelData.picList" :key="item.index" class="box-card"
				style="margin-top: 10px">
				<div slot="header" class="clearfix">
					<el-button style="float: right; padding: 3px 0" type="text" @click="delZs(index)">删除</el-button>
				</div>
				<el-form-item label="证书图片">
					<el-col :span="18">
						<el-input v-model="item.url" placeholder="请输入内容图片地址">
							<template slot="append"><span style="cursor: pointer"
									@click="selImg('Quality', 'picList', index)">选择图片</span></template>
						</el-input>
					</el-col>
					<el-col :span="18">
						<img height="100px" :src="item.url" alt="" class="imgClass">
					</el-col>
				</el-form-item>
				<el-row>
					<el-col :span="24" style="margin-top: 5px;">
						<el-input v-model="item.title" placeholder="这里是标题">
							<template slot="append"><span style="cursor: pointer">标题</span></template>
						</el-input>
					</el-col>
				</el-row>
				<el-form-item label="字体颜色" style="margin-top: 5px;">
					<template>
						<bs-color-picker v-model="item.color" />
					</template>
				</el-form-item>
				<el-form-item label="字体大小">
					<template>
						<bs-slider v-model="item.fontSize" :min="8" :max="50" />
					</template>
				</el-form-item>
			</el-card>
			<el-button style="margin:20px auto;width: 80%;display: block;" @click="addZs">添加证书</el-button>


			<el-form-item label="标题3">
				<el-input v-model="curSelData.oCTitle" type="text" placeholder="请输入标题">
				</el-input>
			</el-form-item>
			<el-form-item label="字体颜色">
				<template>
					<bs-color-picker v-model="curSelData.oCColor" />
				</template>
			</el-form-item>
			<el-form-item label="字体大小">
				<template>
					<bs-slider v-model="curSelData.oCFontSize" :min="8" :max="50" />
				</template>
			</el-form-item>

		
			
			
			<el-card v-for="(item, index) in curSelData.listData" :key="item.index" class="box-card"
				style="margin-top: 10px">
				<div slot="header" class="clearfix">
					<el-button style="float: right; padding: 3px 0" type="text" @click="delListItem(index)">删除</el-button>
				</div>
				<el-form-item label="内容图片">
					<el-col :span="18">
						<el-input v-model="item.numImg" placeholder="请输入内容图片地址">
							<template slot="append"><span style="cursor: pointer"
									@click="selImg('Quality', 'listData', index)">选择图片</span></template>
						</el-input>
					</el-col>
					<el-col :span="18">
						<img height="100px" :src="item.numImg" alt="" class="imgClass">
					</el-col>
				</el-form-item>
				<el-row>
					<el-col :span="24" style="margin-top: 5px;">
						<el-input v-model="item.name" placeholder="这里是标题">
							<template slot="append"><span style="cursor: pointer">标题</span></template>
						</el-input>
					</el-col>
			
				</el-row>
				<el-form-item label="字体颜色" style="margin-top: 5px;">
					<template>
						<bs-color-picker v-model="item.ncolor" />
					</template>
				</el-form-item>
				<el-form-item label="字体大小">
					<template>
						<bs-slider v-model="item.nfontSize" :min="8" :max="50" />
					</template>
				</el-form-item>
				<el-row>
					<el-col :span="24" style="margin-top: 5px;">
						<el-input v-model="item.content" placeholder="这里是内容">
							<template slot="append"><span style="cursor: pointer">内容</span></template>
						</el-input>
					</el-col>
							
				</el-row>
				<el-form-item label="字体颜色" style="margin-top: 5px;">
					<template>
						<bs-color-picker v-model="item.ccolor" />
					</template>
				</el-form-item>
				<el-form-item label="字体大小">
					<template>
						<bs-slider v-model="item.cfontSize" :min="8" :max="50" />
					</template>
				</el-form-item>
			</el-card>
			<el-button style="margin:20px auto;width: 80%;display: block;" @click="addListItem">添加一项</el-button>

			<el-form-item label="标题4">
				<el-input v-model="curSelData.tCTitle" type="text" placeholder="请输入标题">
				</el-input>
			</el-form-item>
			<el-form-item label="字体颜色">
				<template>
					<bs-color-picker v-model="curSelData.tCColor" />
				</template>
			</el-form-item>
			<el-form-item label="字体大小">
				<template>
					<bs-slider v-model="curSelData.tCFontSize" :min="8" :max="50" />
				</template>
			</el-form-item>

			<el-form-item label="4小标题">
				<el-input v-model="curSelData.tETitle" type="text" placeholder="请输入标题">
				</el-input>
			</el-form-item>
			<el-form-item label="字体颜色">
				<template>
					<bs-color-picker v-model="curSelData.tEColor" />
				</template>
			</el-form-item>
			<el-form-item label="字体大小">
				<template>
					<bs-slider v-model="curSelData.tEFontSize" :min="8" :max="50" />
				</template>
			</el-form-item>

			<el-form-item label="流程图片">
					<el-col :span="18">
						<el-input v-model="curSelData.tImg" placeholder="请输入内容图片地址">
							<template slot="append"><span style="cursor: pointer"
									@click="selImg('Quality', 'tImg')">选择图片</span></template>
						</el-input>
					</el-col>
					<el-col :span="18">
						<img height="100px" :src="curSelData.tImg" alt="" class="imgClass">
					</el-col>
			</el-form-item>

			<el-form-item label="标题5">
				<el-input v-model="curSelData.dCTitle" type="text" placeholder="请输入标题">
				</el-input>
			</el-form-item>
			<el-form-item label="字体颜色">
				<template>
					<bs-color-picker v-model="curSelData.dCColor" />
				</template>
			</el-form-item>
			<el-form-item label="字体大小">
				<template>
					<bs-slider v-model="curSelData.dCFontSize" :min="8" :max="50" />
				</template>
			</el-form-item>

			<el-form-item label="5小标题">
				<el-input v-model="curSelData.dETitle" type="text" placeholder="请输入标题">
				</el-input>
			</el-form-item>
			<el-form-item label="字体颜色">
				<template>
					<bs-color-picker v-model="curSelData.dEColor" />
				</template>
			</el-form-item>
			<el-form-item label="字体大小">
				<template>
					<bs-slider v-model="curSelData.dEFontSize" :min="8" :max="50" />
				</template>
			</el-form-item>
     <el-form-item label="过程图片">
				<el-row>
					<el-col :span="18">
						<el-input v-model="curSelData.dImg" placeholder="请输入内容图片地址">
							<template slot="append"><span style="cursor: pointer"
									@click="selImg('Quality', 'dImg')">选择图片</span></template>
						</el-input>
					</el-col>
					<el-col :span="18">
						<img height="100px" :src="curSelData.dImg" alt="" class="imgClass">
					</el-col>
				</el-row>
			</el-form-item>
			<!--5轮播图-->
			<el-card v-for="(item, index) in curSelData.picList2" :key="item.index" class="box-card"
				style="margin-top: 10px">
				<div slot="header" class="clearfix">
					<el-button style="float: right; padding: 3px 0" type="text" @click="delPicList2Item(index)">删除</el-button>
				</div>
				<el-form-item label="内容图片">
					<el-col :span="18">
						<el-input v-model="item.url" placeholder="请输入内容图片地址">
							<template slot="append"><span style="cursor: pointer"
									@click="selImg('Quality', 'picList2', index)">选择图片</span></template>
						</el-input>
					</el-col>
					<el-col :span="18">
						<img height="100px" :src="item.url" alt="" class="imgClass">
					</el-col>
				</el-form-item>
				<el-button style="margin:20px auto;width: 80%;display: block;" @click="addC(index)">添加内容</el-button>
			</el-card>
			<el-button style="margin:20px auto;width: 80%;display: block;" @click="addLunboItem">添加轮播图</el-button>


			<!--6-->
     <el-form-item label="标题6">
				<el-input v-model="curSelData.qCTitle" type="text" placeholder="请输入标题">
				</el-input>
			</el-form-item>
			<el-form-item label="字体颜色">
				<template>
					<bs-color-picker v-model="curSelData.qCColor" />
				</template>
			</el-form-item>
			<el-form-item label="字体大小">
				<template>
					<bs-slider v-model="curSelData.qCFontSize" :min="8" :max="50" />
				</template>
			</el-form-item>

			<el-form-item label="6小标题">
				<el-input v-model="curSelData.qETitle" type="text" placeholder="请输入标题">
				</el-input>
			</el-form-item>
			<el-form-item label="字体颜色">
				<template>
					<bs-color-picker v-model="curSelData.qEColor" />
				</template>
			</el-form-item>
			<el-form-item label="字体大小">
				<template>
					<bs-slider v-model="curSelData.qEFontSize" :min="8" :max="50" />
				</template>
			</el-form-item>

     <el-form-item label="公司名称">
				<el-input v-model="curSelData.qyTit" type="text" placeholder="请输入公司名称">
				</el-input>
			</el-form-item>
      <el-form-item label="公司logo">
				<el-row>
         <el-col :span="18">
					<el-input v-model="curSelData.qyLogo" placeholder="请输入内容图片地址">
						<template slot="append"><span style="cursor: pointer"
								@click="selImg('Quality', 'qyLogo')">选择图片</span></template>
					</el-input>
				</el-col>
				<el-col :span="18">
					<img height="100px" :src="curSelData.qyLogo" alt="" class="imgClass">
				</el-col>
				</el-row>
				
			</el-form-item>
			<el-form-item label="公司介绍">
       <el-input v-model="curSelData.qyContent" placeholder="请输入内容图片地址"></el-input>
			</el-form-item>

			<el-form-item label="公司图片">
				<el-col :span="18">
					<el-input v-model="curSelData.qyImg" placeholder="请输入内容图片地址">
						<template slot="append"><span style="cursor: pointer"
								@click="selImg('Quality', 'qyImg')">选择图片</span></template>
					</el-input>
				</el-col>
				<el-col :span="18">
					<img height="100px" :src="curSelData.qyImg" alt="" class="imgClass">
				</el-col>
			</el-form-item>





		</el-form>
	</div>
</template>
<script>
	import BsSlider from '../module/BsSlider'
	import BsColorPicker from '../module/BsColorPicker'
	export default {
		components: {
			BsSlider,
			BsColorPicker
		},
		props: {
			curSelData: {
				type: Object,
				default: () => {}
			}
		},
		data() {
			return {
				inputValue: '',
				OneZSImgDefaultData: {
						title: '质量管理体系认证证书',
						url: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/fangyuanzhengshu/WechatIMG3.png',
						fontSize: 14,
						color: '#FFFFFF'
				},
				OneListItem: {
						name: '介绍',
						ncolor: '#FEFEFE',
						nfontSize: 15,
						content: '为了更好地满足顾客及法律法规的要求,增强顾客满意,国际标准化组织(ISO)于1987年发布了IS09000-IS09004五项ISO9000系列质量管理标准。后又针对ISO9000族标准应用中的问题，进行了四次修订，发布了1994版、2000版、2008版和2016版ISO9000族标准。标准是在总结国际上质量管理实践经验和理论基础上制订的，组织按质量管理体系标准要求建立、实施、保持和持续改进质量管理体系，必将提高产品和服务质量，增强顾客满意，提高市场竞争力。',
						ccolor: '#43464A',
						cfontSize: 12,
						numImg: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/zlgl/01@2x.png'
				},
				OnePicList2Item: {
						url: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/fangyuanzhengshu/lbt01.png',
				}
			}
		},
		methods: {
			clearColor() {
				this.curSelData.bgColor = 'transparent'
			},
			clearTent() {
				this.curSelData.content = ''
			},
			chooseIcon() {
				this.$refs.SelIcon.dialogVisible = true
			},
			selectedIcon(val) {
				console.log('val', val)
				this.curSelData.textIcon = val
			},
			changeImg(val) {
				this.$emit('input', val)
				this.inputValue = val
				this.$refs.selImage.dialogVisible = false
			},
			selImg(type, attr, index) {
				this.$emit('selImg', type, attr, index)
			},
			addZs() {
				this.curSelData.picList.push(this.OneZSImgDefaultData)
			},
			addListItem() {
				this.curSelData.listData.push(this.OneListItem)
			},
			addLunboItem() {
				this.curSelData.picList2.push(this.OnePicList2Item)
			},
      delZs(index) {
        this.curSelData.picList.splice(index, 1)
			},
			delListItem(index) {
				this.curSelData.listData.splice(index, 1)
			},
			delPicList2Item(index) {
				this.curSelData.picList2.splice(index, 1)
			},
			
		}
	}
</script>

<style scoped>
.imgClass{
	margin-top: 20px;
}
</style>
