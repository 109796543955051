<template>
	<div class="rBox">
		<el-form label-width="90px">
			<el-form-item label="是否展示">
				<template>
					<el-radio v-model="curSelData.isShow" :label="1">是</el-radio>
					<el-radio v-model="curSelData.isShow" :label="2">否</el-radio>
				</template>
			</el-form-item>
			<!-- <el-form-item label="抽奖方式">
				<template>
					<el-radio v-model="curSelData.method" :label="1">刮刮卡</el-radio>
					<el-radio v-model="curSelData.method" :label="2">转盘抽奖</el-radio>
				</template>
			</el-form-item> -->
			<el-form-item label="是否定位">
				<template>
					<el-radio v-model="curSelData.entryType" :label="1">是</el-radio>
					<el-radio v-model="curSelData.entryType" :label="2">否</el-radio>
				</template>
			</el-form-item>
			<el-form-item label="入口图片">
          <el-col :span="18">
						<el-input v-model="curSelData.entryImg" placeholder="请输入logo地址">
							<template slot="append"><span style="cursor: pointer"
									@click="selImg('ggk', 'entryImg')">选择图片</span></template>
						</el-input>
					</el-col>
					<el-col :span="18">
					 <el-input v-model="curSelData.width" placeholder="请输入图片宽度" style="margin-top: 10px">
          	<template slot="append">图片宽度</template>
					 </el-input>
					</el-col>
					<el-col :span="18">
						<img height="100px" :src="curSelData.entryImg" alt="" class="imgClass" style="margin-top: 10px">
					</el-col>
      </el-form-item>
      <div class="tip">奖品请在中奖设置菜单中配置</div>
		</el-form>
	</div>
</template>
<script>
	// import BsSlider from '../module/BsSlider'
	// import BsColorPicker from '../module/BsColorPicker'

	// import SelIcon from '@/views/custompage/module/IconShow'
	export default {
		components: {
			// BsSlider,
			// BsColorPicker,
			// SelIcon
		},
		props: {
			curSelData: {
				type: Object,
				default: () => {}
			}
		},
		data() {
			return {
				inputValue: ''
			}
		},
		
		mounted() {
			
		},
		methods: {
			clearColor() {
				this.curSelData.bgColor = 'transparent'
			},
			clearTent() {
				this.curSelData.content = ''
			},
			chooseIcon() {
				this.$refs.SelIcon.dialogVisible = true
			},
			selectedIcon(val) {
				console.log('val', val)
				this.curSelData.textIcon = val
			},
			changeImg(val) {
				this.$emit('input', val)
				this.inputValue = val
				this.$refs.selImage.dialogVisible = false
			},
			selImg(type, attr) {
				this.$emit('selImg', type, attr)
			},
		}
	}
</script>
<style scoped>
.tip{
  font-size: 14px;
  color: #f00;
  padding-left: 25px;
}
</style>
