<template>
  <div class="rBox">
    <el-form label-width="90px">
      <el-form-item label="上下边距">
        <template>
          <bs-slider v-model="curSelData.paddTop" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="左右边距">
        <template>
          <bs-slider v-model="curSelData.paddLeft" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="图片位置">
        <template>
          <template>
            <el-radio v-model="curSelData.align" label="left">居左</el-radio>
            <el-radio v-model="curSelData.align" label="center">居中</el-radio>
            <el-radio v-model="curSelData.align" label="right">居右</el-radio>
          </template>
        </template>
      </el-form-item>
      <el-form-item label="是否显示" style="margin-top: 20px;">
        <el-radio-group v-model="curSelData.isShow">
          <el-radio :label="1">显示</el-radio>
          <el-radio :label="2">隐藏</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <el-card v-for="(item,index) in curSelData.picList" :key="item.url + index" class="box-card" style="margin-top: 10px">
      <div slot="header" class="clearfix">
        <el-button style="float: right; padding: 3px 0" type="text" @click="delPic(index)">删除</el-button>
      </div>
      <el-row>
        <el-col :span="6">
          <el-image :src="item.url" style="display: block;height: 74px;width: 148px;max-width: 90%">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline" />
            </div>
          </el-image>
        </el-col>
        <el-col :span="18">
          <el-input v-model="item.url" placeholder="请输入图片地址">
            <template slot="append"><span style="cursor: pointer" @click="selImg(index)">选择图片</span></template>
          </el-input>
          <el-input v-model="item.link" placeholder="请输入链接" style="margin-top: 10px">
            <template slot="append">选择链接</template>
          </el-input>
        </el-col>
        <el-input v-model="item.width" placeholder="请输入图片宽度" style="margin-top: 10px">
          <template slot="append">图片宽度</template>
        </el-input>
      </el-row>
    </el-card>
    <el-button v-if="!singleImgae" style="margin:20px auto;width: 80%;display: block" @click="addItem">添加</el-button>
  </div>
</template>
<script>
import BsSlider from '../module/BsSlider'
export default {
  components: {
    BsSlider
  },
  props: {
    curSelData: {
      type: Object,
      required: true
    },
    singleImgae: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    }
  },
  methods: {
    delPic(index) {
      const width = (100 / (this.curSelData.picList.length - 1)).toFixed(2)
      this.curSelData.picList.forEach(item => {
        item.width = width + '%'
      })
      this.curSelData.picList.splice(index, 1)
    },
    selImg(index) {
      this.$emit('selImg', 'mulImage', 'picList', index)
    },
    addItem() {
      const width = (100 / (this.curSelData.picList.length + 1)).toFixed(2)
      this.curSelData.picList.forEach(item => {
        item.width = width + '%'
      })
      this.curSelData.picList.push({ width: width + '%', url: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/custom/14abe8ed67758411564838a4387608d0.jpeg' })
    }
  }
}
</script>
