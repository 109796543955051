<template>
  <div class="rBox">
    <el-form label-width="90px">
        <el-form-item label="标题">
            <el-input v-model="curSelData.title" placeholder="请输入标题">
            </el-input>
        </el-form-item>
        <el-form-item label="顶级查询">
            <template>
                <el-radio v-model="curSelData.jumpStyle" :label="1">不显示</el-radio>
                <el-radio v-model="curSelData.jumpStyle" :label="2">风格一</el-radio>
            </template>
        </el-form-item>
        <el-form label-width="90px">
          <div>功能特点：</div>
          <el-card v-for="(item,index) in curSelData.gnList" :key="item.content" class="box-card"
            style="margin-top: 10px">
            <div slot="header" class="clearfix">
              <el-button style="float: right; padding: 3px 0" type="text" @click="delItem(1,index)">删除</el-button>
            </div>

            <el-form-item label="内容">
              <el-input v-model="item.content" />
            </el-form-item>

          </el-card>
          <el-button style="margin:20px auto;width: 80%;display: block" @click="addItem(1,index)">添加功能项</el-button>
        </el-form>

        <!---->
        <el-form label-width="90px">
          <div>参数：</div>
          <el-card v-for="(item,index) in curSelData.parList" :key="item.content" class="box-card"
            style="margin-top: 10px">
            <div slot="header" class="clearfix">
              <el-button style="float: right; padding: 3px 0" type="text" @click="delItem(2,index)">删除</el-button>
            </div>
            <el-form-item label="标题">
              <el-input v-model="item.title" />
            </el-form-item>
            <el-form-item label="内容">
              <el-input v-model="item.content" />
            </el-form-item>
            <el-form-item label="宽度（%）">
              <el-input v-model="item.with" />
            </el-form-item>
          </el-card>
          <el-button style="margin:20px auto;width: 80%;display: block" @click="addItem(1,index)">添加功能项</el-button>
        </el-form>

        <el-card class="box-card" style="margin-top: 10px">
          <div slot="header" class="clearfix">
              证书图片
          </div>
          <el-form label-width="90px">
              <el-row>
                  <el-col :span="6">
                      <el-image :src="curSelData.zsImg"
                          style="display: inline-block;height: 74px;width: 148px;max-width: 90%">
                          <div slot="error" class="image-slot">
                              <i class="el-icon-picture-outline" />
                          </div>
                      </el-image>
                  </el-col>
                  <el-col :span="18">
                      <el-input v-model="curSelData.zsImg" placeholder="请输入图片地址">
                          <template slot="append"><span style="cursor: pointer"
                                  @click="selImg('equipment', 'zsImg')">选择图片</span></template>
                      </el-input>
                  </el-col>
              </el-row>
          </el-form>
      </el-card>
    </el-form>
  </div>
</template>
<script>
// import BsSlider from '../module/BsSlider'
// import BsColorPicker from '../module/BsColorPicker'
export default {
  components: {
    // BsSlider,
    // BsColorPicker
  },
  props: {
    curSelData: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => {}
    }
  },
  data() {
    return {

    }
  },
  methods: {
    selImg(type, attr) {
      this.$emit('selImg', type, attr)
    },
    addItem(type) {
      if(type === 1) {
				this.curSelData.gnList.push({
          content: '内容'
        })
      }else if(type === 2) {
        this.curSelData.parList.push({
          title:'标题',
          content: '内容',
          with: '100%',
        })
      }

    },
    delItem(type,index) {
      if(type === 1) {
        this.curSelData.gnList.splice(index, 1)
      }else  if(type === 2) {
        this.curSelData.parList.splice(index, 1)
      }
    },
  }
}
</script>
<style scoped>

</style>
