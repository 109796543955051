<template>
  <div class="rBox">
    <el-form label-width="90px">
      <el-form-item label="底边框">
        <template>
          <template>
            <el-radio v-model="curSelData.isShowLine" :label="true">显示</el-radio>
            <el-radio v-model="curSelData.isShowLine" :label="false">隐藏</el-radio>
          </template>
        </template>
      </el-form-item>
      <el-card v-for="(item,index) in curSelData.list" :key="item.label+index" class="box-card" style="margin-top: 10px">
        <div slot="header" class="clearfix">
          <el-button style="float: right; padding: 3px 0" type="text" @click="delItem(index)">删除</el-button>
        </div>
        <el-form-item label="标题">
          <el-input v-model="item.label" />
        </el-form-item>
        <el-form-item label="内容">
          <el-input v-model="item.content" />
        </el-form-item>
      </el-card>
      <el-button style="margin:20px auto;width: 80%;display: block" @click="addItem">添加</el-button>
    </el-form>
  </div>
</template>
<script>
// import BsSlider from '@/views/custompage/module/BsSlider'
// import BsColorPicker from '@/views/custompage/module/BsColorPicker'

// import SelIcon from '@/views/custompage/module/IconShow'
export default {
  // components: { BsSlider, BsColorPicker, SelIcon },
  props: {
    curSelData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      inputValue: ''
    }
  },
  methods: {
    addItem(){
      this.curSelData.list.push({
        label: '标题：',
        content: '内容'
      })
    },
    delItem(index){
      this.curSelData.list.splice(index, 1)
    }
  }
}
</script>
