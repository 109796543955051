<template>
	<div class="rBox">
		<el-form label-width="90px">
			<el-form-item label="左右边距">
				<template>
					<bs-slider v-model="curSelData.paddTB" :min="0" :max="100" />
				</template>
			</el-form-item>
			<el-form-item label="上下边距">
				<template>
					<bs-slider v-model="curSelData.paddLR" :min="0" :max="100" />
				</template>
			</el-form-item>
			<el-form-item label="证书类型">
			  <el-radio v-model="curSelData.flag" :label="1">日化协会证书</el-radio>
              <el-radio v-model="curSelData.flag" :label="2">工业互联网协会证书</el-radio>
			</el-form-item>
		</el-form>
		
		<el-card v-for="(item,index) in curSelData.picList" :key="item.title" class="box-card" style="margin-top: 10px">
			<div slot="header" class="clearfix">
				<el-button style="float: right; padding: 3px 0" type="text" @click="delPic(index)">删除</el-button>
			</div>
			<el-form label-width="90px">
				<el-row>
					<el-col :span="6">
						<el-image :src="item.url"
							style="display: inline-block;height: 74px;width: 148px;max-width: 90%">
							<div slot="error" class="image-slot">
								<i class="el-icon-picture-outline" />
							</div>
						</el-image>
					</el-col>
					<el-col :span="18">
						<el-input v-model="item.url" placeholder="请输入图片地址">
							<template slot="append"><span style="cursor: pointer"
									@click="selImg('qkPage', 'picList', index)">选择图片</span></template>
						</el-input>
						<el-input v-model="item.link" placeholder="请输入链接" style="margin-top: 10px">
							<template slot="append">选择链接</template>
						</el-input>
					</el-col>
					<el-input v-if="!singleImgae" v-model="item.width" placeholder="请输入图片宽度" style="margin-top: 10px">
						<template slot="append">图片宽度</template>
					</el-input>
				</el-row>
			</el-form>
		</el-card>
		<el-button v-if="!singleImgae" style="margin:20px auto;width: 80%;display: block" @click="addItem">添加
		</el-button>

		<el-card v-for="(item,index) in curSelData.contentImg" :key="item.index" class="box-card"
			style="margin-top: 10px">
			<!-- <div slot="header" class="clearfix">
				<el-button style="float: right; padding: 3px 0" type="text" @click="delPic1(index)">删除</el-button>
			</div> -->
			<el-form label-width="90px">
				<el-row>
					<el-col :span="6">
						<el-image :src="item.url"
							style="display: inline-block;height: 74px;width: 148px;max-width: 90%">
							<div slot="error" class="image-slot">
								<i class="el-icon-picture-outline" />
							</div>
						</el-image>
					</el-col>
					
					<el-col :span="18">
						<el-input v-model="item.url" placeholder="请输入图片地址">
							<template slot="append"><span style="cursor: pointer"
									@click="selImg('qkPage', 'url', index)">选择图片</span></template>
						</el-input>
						<el-input v-model="item.link" placeholder="请输入链接" style="margin-top: 10px">
							<template slot="append">选择链接</template>
						</el-input>
					</el-col>
					<el-input v-if="!singleImgae" v-model="item.width" placeholder="请输入图片宽度" style="margin-top: 10px">
						<template slot="append">图片宽度</template>
					</el-input>
				</el-row>

				<el-row style="margin-top: 10px;">
					<el-col :span="6" style="margin: 0 auto;text-align: center;width: 100%;">
						<el-image :src="item.headImg"
							style="width: 120px;height: 150px;">
							<div slot="error" class="image-slot">
								<i class="el-icon-picture-outline" />
							</div>
						</el-image>
					</el-col>
					<el-row class="myRow">
						<el-form-item label="头像上边距">
							<bs-slider v-model="item.marginTop" :min="0" :max="100" />
						</el-form-item>
					</el-row>
					<el-row class="myRow">
						<el-form-item label="头像左边距">
							<bs-slider v-model="item.marginLeft" :min="0" :max="100" />
						</el-form-item>
					</el-row>
					<el-row>
						<el-col :span="24">
							<el-input v-model="item.name" placeholder="请输入姓名" style="margin-top: 10px">
								<template slot="append">姓名</template>
							</el-input>
						</el-col>
					</el-row>
					<el-row v-if="curSelData.flag ==1">
						<el-col :span="24">
							<el-input v-model="item.post" placeholder="请输入职务" style="margin-top: 10px">
								<template slot="append">职务</template>
							</el-input>
						</el-col>
					</el-row>
					<el-col :span="24" style="margin-top: 10px;">
						<el-input v-model="item.headImg" placeholder="请输入图片地址">
							<template slot="append"><span style="cursor: pointer"
									@click="selImg('qkPage', 'headImg', index)">选择图片</span></template>
						</el-input>
					</el-col>
					<el-input v-if="!singleImgae" v-model="item.width" placeholder="请输入图片宽度" style="margin-top: 10px">
						<template slot="append">图片宽度</template>
					</el-input>
					
						<el-row>
							<el-col :span="24">
								<el-input v-model="item.date" placeholder="请输入证书日期" style="margin-top: 10px">
									<template slot="append">证书日期</template>
								</el-input>
							</el-col>
						</el-row>
					<template v-if="curSelData.flag == 1">
						<el-row class="myRow">
							<el-form-item label="日期行高">
								<bs-slider v-model="item.dateHeight" :min="0" :max="100" />
							</el-form-item>
						</el-row>
						<el-row class="myRow">
							<el-form-item label="日期尺寸">
								<bs-slider v-model="item.dateSize" :min="0" :max="100" />
							</el-form-item>
						</el-row>
					</template>

					<el-row>
						<el-col :span="24">
							<el-input v-model="item.content" placeholder="请输入证书内容" style="margin-top: 10px">
								<template slot="append">证书内容</template>
							</el-input>
						</el-col>
					</el-row>
					<el-row class="myRow">
						<el-form-item label="内容尺寸">
							<bs-slider v-model="item.contentSize" :min="0" :max="100" />
						</el-form-item>
					</el-row>
					<el-row class="myRow">
						<el-form-item label="内容上边距">
							<bs-slider v-model="item.contentTop" :min="0" :max="200" />
						</el-form-item>
					</el-row>
					<el-row class="myRow">
						<el-form-item label="内容右边距">
							<bs-slider v-model="item.contentRight" :min="0" :max="200" />
						</el-form-item>
					</el-row>
					<el-row>
						<el-col :span="24">
							<el-input v-model="item.number" placeholder="请输入证书编号" style="margin-top: 10px">
								<template slot="append">证书编号</template>
							</el-input>
						</el-col>
					</el-row>
					<el-row class="myRow">
						<el-form-item label="编号行高">
							<bs-slider v-model="item.numberHeight" :min="0" :max="100" />
						</el-form-item>
					</el-row>
					<el-row class="myRow">
						<el-form-item label="编号尺寸">
							<bs-slider v-model="item.numberSize" :min="0" :max="100" />
						</el-form-item>
					</el-row>
				</el-row>
			</el-form>
		</el-card>
		
		<el-form label-width="90px">
			<el-row>
				<el-col :span="24">
					<el-input v-model="curSelData.text" placeholder="请输入文本内容" style="margin-top: 10px">
						<template slot="append">文本内容</template>
					</el-input>
				</el-col>
			</el-row>
			<el-form-item label="文字大小">
				<template>
					<bs-slider v-model="curSelData.textsize" :min="0" :max="50" />
				</template>
			</el-form-item>
			<el-row class="myRow">
				<el-form-item label="文字颜色">
					<bs-color-picker v-model="curSelData.textColor" />
				</el-form-item>
			</el-row>
			<el-row class="myRow">
				<el-form-item label="背景颜色">
					<bs-color-picker v-model="curSelData.textBg" />
				</el-form-item>
			</el-row>
		</el-form>
		
		<el-form label-width="90px">
			<el-row>
				<el-col :span="24">
					<el-input v-model="curSelData.title" placeholder="请输入标题内容" style="margin-top: 10px">
						<template slot="append">标题内容</template>
					</el-input>
				</el-col>
			</el-row>
			<el-form-item label="标题大小">
				<template>
					<bs-slider v-model="curSelData.titleSize" :min="0" :max="50" />
				</template>
			</el-form-item>
			<el-row>
				<el-col :span="24">
					<el-input v-model="curSelData.bottomWords" placeholder="请输入底部内容" style="margin-top: 10px">
						<template slot="append">底部内容</template>
					</el-input>
				</el-col>
			</el-row>
			<el-form-item label="文字大小">
				<template>
					<bs-slider v-model="curSelData.wordsize" :min="0" :max="50" />
				</template>
			</el-form-item>
			<el-row class="myRow">
				<el-form-item label="文字颜色">
					<bs-color-picker v-model="curSelData.wordsColor" />
				</el-form-item>
			</el-row>
			<el-row class="myRow">
				<el-form-item label="背景颜色">
					<bs-color-picker v-model="curSelData.bottomWordsColor" />
				</el-form-item>
			</el-row>
		</el-form>
		
		<el-card v-for="(item,index) in curSelData.reportImg" :key="item.title" class="box-card" style="margin-top: 10px">
			<div slot="header" class="clearfix">
				<el-button style="float: right; padding: 3px 0" type="text" @click="delPic2(index)">删除</el-button>
			</div>
			<el-form label-width="90px">
				<el-row>
					<el-col :span="6">
						<el-image :src="item.url"
							style="display: inline-block;height: 74px;width: 148px;max-width: 90%">
							<div slot="error" class="image-slot">
								<i class="el-icon-picture-outline" />
							</div>
						</el-image>
					</el-col>
					<el-col :span="18">
						<el-input v-model="item.url" placeholder="请输入图片地址">
							<template slot="append"><span style="cursor: pointer"
									@click="selImg('qkPage', 'reportImg', index)">选择图片</span></template>
						</el-input>
						<el-input v-model="item.link" placeholder="请输入链接" style="margin-top: 10px">
							<template slot="append">选择链接</template>
						</el-input>
					</el-col>
					<el-input v-if="!singleImgae" v-model="item.width" placeholder="请输入图片宽度" style="margin-top: 10px">
						<template slot="append">图片宽度</template>
					</el-input>
				</el-row>
			</el-form>
		</el-card>
		<el-button v-if="!singleImgae" style="margin:20px auto;width: 80%;display: block" @click="addItem1">添加
		</el-button>
		
		<el-card v-for="(item,index) in curSelData.wayImg" :key="item.title" class="box-card" style="margin-top: 10px">
			<div slot="header" class="clearfix">
				<el-button style="float: right; padding: 3px 0" type="text" @click="delPic3(index)">删除</el-button>
			</div>
			<el-form label-width="90px">
				<el-row>
					<el-col :span="6">
						<el-image :src="item.url"
							style="display: inline-block;height: 74px;width: 148px;max-width: 90%">
							<div slot="error" class="image-slot">
								<i class="el-icon-picture-outline" />
							</div>
						</el-image>
					</el-col>
					<el-col :span="18">
						<el-input v-model="item.url" placeholder="请输入图片地址">
							<template slot="append"><span style="cursor: pointer"
									@click="selImg('qkPage', 'wayImg', index)">选择图片</span></template>
						</el-input>
						<el-input v-model="item.link" placeholder="请输入链接" style="margin-top: 10px">
							<template slot="append">选择链接</template>
						</el-input>
					</el-col>
					<el-input v-if="!singleImgae" v-model="item.width" placeholder="请输入图片宽度" style="margin-top: 10px">
						<template slot="append">图片宽度</template>
					</el-input>
				</el-row>
			</el-form>
		</el-card>
		<el-button v-if="!singleImgae" style="margin:20px auto;width: 80%;display: block" @click="addItem2">添加
		</el-button>
	</div>
</template>
<style lang="scss" scoped>
	.myRow {
		line-height: 30px;
		font-size: 12px;
		margin: 10px 0;
	}

	.formsItem {
		>label {
			position: relative;
			top: 10px;
		}
	}
</style>
<script>
	import BsSlider from '../module/BsSlider'
	import BsColorPicker from '../module/BsColorPicker'
	export default {
		components: {
			BsSlider,
			BsColorPicker
		},
		props: {
			curSelData: {
				type: Object,
				required: true
			},
			singleImgae: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {

			}
		},
		methods: {
			delPic(index) {
				// this.curSelData.picList.splice(index, 1)
				// this.curSelData.picList.map((item, index) => {
				// 	item.title = '这是标题' + (index + 1)
				// })
				if (this.curSelData.picList.length == 1) {
					return this.$message({
						message: '至少要保留一张！',
						type: 'warning'
					});
				}
				this.curSelData.picList.splice(index, 1)
			},
			delPic1(index) {
				console.log(index)
				this.curSelData.contentImg.splice(index, 1)
				// this.curSelData.contentImg.splice(index, 1)
			},
			delPic2(index) {
				if (this.curSelData.reportImg.length == 1) {
					return this.$message({
						message: '至少要保留一张！',
						type: 'warning'
					});
				}
				this.curSelData.reportImg.splice(index, 1)
			},
			delPic3(index) {
				if (this.curSelData.wayImg.length == 1) {
					return this.$message({
						message: '至少要保留一张！',
						type: 'warning'
					});
				}
				this.curSelData.wayImg.splice(index, 1)
			},
			delText(index) {
				this.curSelData.content.splice(index, 1)
			},
			addText() {
				this.curSelData.content.push({
					text: ''
				})
			},
			selImg(type, attr, index) {
				this.$emit('selImg', type, attr, index)
			},
			addItem() {
				let str = '这是标题' + (this.curSelData.picList.length ? (this.curSelData.picList.length + 1) : '1')
				this.curSelData.picList.push({
					url: 'https://86122m.com/attachment/images/1/2022/02/lDud6zbu6qDUsTComQwl6Zm0Lb6LC0.png',
					width: '100%', // 图片宽度
					link: '',
					title: str,
					titleColor: '#000',
					titleSize: 18,

					subTitle: '这是小标题',
					subTitleColor: '#1D211D',
					subTitleSize: 14,
				})

			},
			addItem1() {
				this.curSelData.reportImg.push({
					url: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/custom/a3120a63c0e951bf07cea8442f64f090.png',
					width: '100%', // 图片宽度
				})
			},
			addItem2() {
				this.curSelData.wayImg.push({
					url: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/custom/a3120a63c0e951bf07cea8442f64f090.png',
					width: '100%', // 图片宽度
				})
			},
			clearColor(attr) {
				this.curSelData[attr] = 'transparent'
			},
		}
	}
</script>
