<template>
    <div class="videoWrap" :style="{paddingTop: item.paddTop+'px', paddingBottom: item.paddBottom+'px', 'borderBottom': item.bottomshow ? '1px solid #F0F2F7' : ''}">
      <div v-for="(it, index) in item.videoList" :key="index">
        <div class="videoBlock">
          <video class="myVideo" controls="false" :poster="it.poster" @pause="pauseVideo" @play="playVideo(index)" :id="'video'+index" play-btn-position="center"	show-play-btn="false" object-fit="cover" :src="it.url"></video>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      autoplay: true,
      videoList: [],
      videoElementList: []
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  mounted() {
    // this.init()
    
  },
  methods:{
    init() {
      for(var i=0;i<this.videoList.length;i++){
        // var videoItem=this.videoList[i];
        // this.videoElementList[i]=uni.createVideoContext(videoItem.videoId+'',this)
        var videoId = 'video'+i
        this.videoElementList[i]=uni.createVideoContext(videoId+'',this)
      }
    },
    pauseVideo() {
    //  this.autoplay = true
     
    },
    playVideo(index){
      // this.autoplay = false
      // for (var i=0;i<this.videoElementList.length;i++){
      //    if(i!==index){
      //      this.videoElementList[i].pause()
      //    }
      // }
    },
  }
}
</script>
<style scoped>
  .videoWrap{
    width: 100%;
    /* border: 1px solid #f00; */
    box-sizing: border-box;
    padding-left: 20px;
    display: flex;
    align-items: center;
  }
  .videoBlock{
    width: 154px;
    height: 86px;
    box-sizing: border-box;
    /* border: 1px solid #00f; */
    margin-right: 10px;
  }
  .myVideo{
    width: 154px;
    height: 86px;
    border-radius: 5px;
    overflow: hidden;
  }
</style>