<template>
  <div>
   <el-row>
     <el-col :span="20">
       <el-form-item label="动态字段">
         <el-switch v-model="item.dynamic"></el-switch>
       </el-form-item>
     </el-col>
     <el-col :span="4">
       <el-button type="text" style="color: red" @click="$emit('del')" v-if="showDel">删除</el-button>
     </el-col>
   </el-row>
  <el-row>
    <el-col :span="24" v-if="item.dynamic">
      <el-form-item label="字段">
        <el-select v-model="item.nameEn">
          <el-option v-for="modelItem in modelArray" :key="modelItem.nameEn" :label="modelItem.nameCh" :value="modelItem.nameEn"/>
        </el-select>
      </el-form-item>
    </el-col>
    <el-col :span="12" v-if="!item.dynamic">
      <el-form-item label="标题">
        <el-input v-model="item.title"/>
      </el-form-item>
    </el-col>
    <el-col :span="12" v-if="!item.dynamic">
      <el-form-item label="内容">
        <el-input v-model="item.content"/>
      </el-form-item>
    </el-col>
  </el-row>
    <el-divider/>
  </div>
</template>
<script>
export default {
  props: {
    item: {},
    modelArray: {},
    showDel:{

    }
  }
}
</script>