<template>
  <div class="rBox">
    <el-form label-width="100px">
      <!-- <el-form-item label="高度">
        <template>
          <bs-slider v-model="curSelData.height" :min="0" :max="1000" />
        </template>
      </el-form-item> -->
      <el-form-item label="距离单位">
          <template>
            <el-radio v-model="curSelData.unit" :label="1">百分比</el-radio>
            <el-radio v-model="curSelData.unit" :label="2">px</el-radio>
          </template>
      </el-form-item>
      <el-form-item label="按钮距左距离">
        <template>
          <bs-slider v-model="curSelData.left" :min="0" :max="500" :unitText="getUnitText(curSelData.unit)"/>
        </template>
      </el-form-item>
      <el-form-item label="按钮风格">
          <template>
            <el-radio v-model="curSelData.btnStyle" :label="1">风格一</el-radio>
            <el-radio v-model="curSelData.btnStyle" :label="2">风格二</el-radio>
          </template>
      </el-form-item>
      <el-form-item label="设置咨询字段">
        <el-input v-model="curSelData.zx_key" placeholder="请输入字段名" style="margin-top: 10px" :disabled="true">
        </el-input>
      </el-form-item>
      <el-form-item label="指定客服">
        <el-row>
          <el-col :span="12">
            <el-input v-model="curSelData.zx_name" placeholder="指定客服">
              <template slot="append"><span style="cursor: pointer" @click="selKf">指定客服</span></template>
            </el-input>
          </el-col>
        </el-row>
      </el-form-item>

    </el-form>
    <el-card class="box-card" style="margin-top: 10px">
      <el-row>
        <el-col :span="24">
          <el-input v-model="curSelData.imgUrl" placeholder="请输入图片地址">
            <template slot="append"><span style="cursor: pointer" @click="selImg('')">选择图片</span></template>
          </el-input>
        </el-col>
      </el-row>
      <el-row style="margin-top: 20px;">
        <el-col :span="24">
          <img :src="curSelData.bgImg" style="width: 100%;"/>
          <!-- <el-image :src="curSelData.imgUrl" style="display: block;height: 74px;width: 148px;max-width: 90%">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline" />
            </div>
          </el-image> -->
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
import BsSlider from '../module/BsSlider'
export default {
  components: {
    BsSlider
  },
  props: {
    curSelData: {
      type: Object,
      required: true
    },
  },
  data() {
    return {

    }
  },
  methods: {
    delPic(index) {
      const width = (100 / (this.curSelData.picList.length - 1)).toFixed(2)
      this.curSelData.picList.forEach(item => {
        item.width = width + '%'
      })
      this.curSelData.picList.splice(index, 1)
    },
    selImg() {
      this.$emit('selImg')
    },
    selKf() {
      this.$emit('selKf')
    },
    addItem() {
      const width = (100 / (this.curSelData.picList.length + 1)).toFixed(2)
      this.curSelData.picList.forEach(item => {
        item.width = width + '%'
      })
      this.curSelData.picList.push({ width: width + '%', url: '' })
    },
    getUnitText(unit) {
      let str = ''
      if(unit === 1) {
        str="%"
      }else {
        str = 'px'
      }
      return str
    }
  }
}
</script>
