import { render, staticRenderFns } from "./PackingCodeEdit.vue?vue&type=template&id=1dbd2352&scoped=true&"
import script from "./PackingCodeEdit.vue?vue&type=script&lang=js&"
export * from "./PackingCodeEdit.vue?vue&type=script&lang=js&"
import style0 from "./PackingCodeEdit.vue?vue&type=style&index=0&id=1dbd2352&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dbd2352",
  null
  
)

export default component.exports