<template>
 <div class="my_select_img_wrap">
  <el-dialog
    append-to-body
    :close-on-click-modal="false"
    :visible="dialogVisible"
    :before-close="closeDialog"
    title="上传缩略图"
    width="900px"
  >
    <!--copy start-->
    <el-upload
      :action="upUrl"
      :headers="upParams"
      list-type="picture-card"
      :on-success="handleUploadSuccess"
      :on-error="handleUploadError"
      name="file"
      :show-file-list="false"
      style="display: inline-block; vertical-align: top;margin-left: 85px;"
    >
      <el-image v-if="!pre" :src="pre">
        <div slot="error" class="image-slot">
          <i class="el-icon-plus" />
        </div>
      </el-image>
      <div v-else class="image">
        <el-image :src="pre" :style="`width:150px;height:150px;`" fit="fill"/>
        <div class="mask">
          <div class="actions">
            <span title="移除" @click.stop="removeImage">
              <i class="el-icon-delete" />
            </span>
          </div>
        </div>
      </div>
    </el-upload>
    <div style="margin-top: 10px;">
      <!-- <el-radio v-model="type" :label="0">用户页面</el-radio>
      <el-radio v-model="type" :label="1">内部页面</el-radio> -->
      <!-- <el-form label-width="85px">
        <el-form-item label="页面类型：">
          <el-select v-model="classId" placeholder="请选择">
            <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form> -->
      <el-form label-width="100px">
        <el-form-item label="产品类别：">
            <el-cascader
            class="myCascader"
            v-model="myClassId"
            :options="optionsData"
            :props="{'checkStrictly': true, 'label': 'name', 'value': 'id'}"
            @change="handleChange">
          </el-cascader>
        </el-form-item>
      </el-form>
      <el-form label-width="100px">
        <el-form-item label="备注：">
          <el-input v-model="myRemark" style="width: 30%;" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <!-- <el-form label-width="160px">
        <el-form-item label="是否是箱码：">
          <el-radio-group v-model="isBox">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form> -->
      
    </div>
    <div>
      <el-button @click="confirmThumb" style="margin-top: 20px;position: relative;z-index: 99">确定</el-button>
    </div>
    <!--copy end-->
  </el-dialog>
 </div>

</template>

<script>
// import { serverUrl } from '@/utils/server.js'
// import {noPage} from "../../../api/customPageClass";
import { pageClassList } from '@/api/customPageClass.js'
import {checkPermission} from "@/directives/permission";
let baseUrl = 'https://api.86122m.com'
export default {
  components: {  },
  props: ['ThumbPath','remark', 'classId'],
  data() {
    let token = JSON.parse(localStorage.getItem('info')).token
    return {
      dialogVisible: false,
      upUrl: this.$store.state.uploadCustomUrl,
      ossUrl: this.$store.state.ossUrl,
      upParams: {'token': token, type: 'pc'},
      pre:null,
      myRemark: '',
      isBox: false,
      myClassId:null,
      typeList:[],
      optionsData: [], // 产品类型
      // uploadImgUrl: baseUrl + 'file/uploadObjectOSSs', // 上传的图片服务器地址
      // headers: {
      //   type: 'admin',
      //   token: token,
      // },
    }
  },
  mounted() {
     this.init()
  },
  methods: {
    checkPermission,
    init(){
      // noPage({type:0}).then(response=>{
      //   if (response.data){
      //     this.typeList = response.data
      //   }
      // })
      // 获取产品类型数据
      let params = {
        pageNum: 1,
        pageSize: 999,
      }
      pageClassList(params).then(res => {
        this.optionsData = res.data.list
        // 最后一层空children删除
        this.cascaderList()
      })
    },
    cascaderList(){
      function fn(arr) {
        return arr.map(item => {
          if (item.children&&item.children.length > 0) {
            fn(item.children)
          } else {
            delete item.children
          }
          return item;
        })
      }
      this.optionsData = fn(this.optionsData);
    },
    /**
     * 根据子级类型查找所有匹配的父级类型
     * id: 子级ID
     * data: 匹配数据
     * prop: 匹配的类型,默认用ID匹配
     */
    getFathersById(id, data, prop = 'id') {
      var arrRes = []
      const rev = (data, nodeId) => {
        for (var i = 0, length = data.length; i < length; i++) {
          const node = data[i]
          if (node[prop] === nodeId) {
            arrRes.unshift(node[prop])
            return true
          } else {
            if (node.children && node.children.length) {
              if (rev(node.children, nodeId)) {
                arrRes.unshift(node[prop])
                return true
              }
            }
          }
        }
        return false
      }
      rev(data, id)
      return arrRes
    },
    /**
     * 处理传给后台ID数据,只取最后一级id
     * @param {*} arr 需要处理的数据
     * @param {*} type 1单选 2多选
     */
    handleId(arr, type) {
      if (type === 1) {
        if (arr.length) {
          return arr[arr.length - 1]
        } else return ''
      } else {
        if (arr.length) {
          const newArr = []
          arr.some(item => {
            newArr.push(item[item.length - 1])
          })
          return newArr
        } else return []
      }
    },
    handleChange() {

    },
    closeDialog(){
      this.dialogVisible=false;
    },
    openDialog(thumbnailImg) {
      this.dialogVisible=true;
      this.myRemark = this.remark
      this.myClassId = this.getFathersById(this.classId, this.optionsData)
    },
    handleUploadSuccess(res) {
			console.log('-------------' + JSON.stringify(res))
      // todo $emit
      this.pre = this.ossUrl +res.data[0]
    },
    handleUploadError() {
      // this.pre = 'https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/crm/VR.png'
      this.$message({
        type: "error",
        message: "上传失败",
      });
    },
    removeImage() {
      // todo 删除接口
      this.pre = null;
    },
    confirmThumb() {
      // if(!this.pre) {
      //   this.$message({
      //   type: "warning",
      //     message: "请上传缩略图",
      //   });
      //   return
      // }
      if(checkPermission(['crkStatistics'])) { // 出入库管理，保存时候 产品类别必填
        if(!this.myClassId.length ) {
          this.$message({
            type: 'error',
            message: '请选择产品类别'
          });
          return 
        }
      }

       let data = {
        path: this.pre,
        remark: this.myRemark,
        classId: this.myClassId && this.myClassId.length ? this.myClassId[this.myClassId.length-1] : ''
      }
      this.$emit('getUploadThumb', data) 
    }
  },
  watch: {
    dialogVisible (val) {
      if(val) {
        this.pre= this.ThumbPath
      }else {

      }
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.image {
  position: relative;
  .mask {
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.3s;
  }
  &:hover .mask {
    opacity: 1;
  }
}

//  .myCascader /deep/ .el-cascader-panel .el-radio{
// 		width: 100%;
// 		height: 100%;
// 		z-index: 10;
// 		position: absolute;
// 		top: 10px;
// 		right: 10px;
// 	}
//   .myCascader /deep/  .el-cascader-panel .el-radio__input{
// 		visibility: hidden;
// 	}
//   .myCascader /deep/ .el-cascader-panel .el-cascader-node__postfix {
// 		top: 10px;
//   }
//  .el-cascader-node>.el-radio {
//     display: none!important;
//   }
</style>
