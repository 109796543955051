<template>
  <div class="rBox">
    <el-form label-width="90px">
      <el-form-item label="图片高度">
        <template>
          <bs-slider v-model="curSelData.imgHeight" :min="0" :max="200" />
        </template>
      </el-form-item>
      <el-form-item label="标题">
        <el-input
          v-model="curSelData.title"
          type="text"
          placeholder="请输入标题">
          <el-button slot="append" @click="clearTit">清除</el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="小标题">
        <el-input
          v-model="curSelData.subTitle"
          type="text"
          placeholder="请输入小标题">
          <el-button slot="append" @click="clearSubTit">清除</el-button>
        </el-input>
      </el-form-item>

      <el-form-item label="标题">
        <template>
          <template>
            <el-radio v-model="curSelData.titleVisible" :label="true">显示</el-radio>
            <el-radio v-model="curSelData.titleVisible" :label="false">不显示</el-radio>
          </template>
        </template>
      </el-form-item>

      <el-form-item label="小标题">
        <template>
          <template>
            <el-radio v-model="curSelData.subTitleVisible" :label="true">显示</el-radio>
            <el-radio v-model="curSelData.subTitleVisible" :label="false">不显示</el-radio>
          </template>
        </template>
      </el-form-item>

      <el-form-item label="列表样式">
        <template>
          <template>
            <el-radio v-model="curSelData.listStyle" :label="1">单列显示</el-radio>
            <el-radio v-model="curSelData.listStyle" :label="2">双列显示</el-radio>
            <el-radio v-model="curSelData.listStyle" :label="3">列表显示</el-radio>
          </template>
        </template>
      </el-form-item>

      <el-form-item label="背景颜色" class="formsItem">
        <template>
          <bs-color-picker v-model="curSelData.bgColor" style="float:left;" />
          <el-button type="primary" class="btnClear" @click="clearColor('bgColor')">清除</el-button>

        </template>
      </el-form-item>

      <el-form-item label="商品背景色" class="formsItem">
        <template>
          <bs-color-picker v-model="curSelData.proBgColor" style="float:left;" />
          <el-button type="primary" class="btnClear" @click="clearColor('proBgColor')">清除</el-button>

        </template>
      </el-form-item>

      <el-form-item label="背景图片">
        <el-col :span="18">
          <el-input v-model="curSelData.bgUrl" placeholder="请输入图片地址">
            <template slot="append"><span style="cursor: pointer" @click="selImg('productGroup','bgUrl')">选择图片</span></template>
          </el-input>
        </el-col>
        <el-col :span="18">
          <img :src="curSelData.bgUrl" alt="" class="imgClass">
        </el-col>
      </el-form-item>

      <el-form-item label="商品名称">
          <template>
            <el-radio v-model="curSelData.goodsNameVisible" :label="true">显示</el-radio>
            <el-radio v-model="curSelData.goodsNameVisible" :label="false">不显示</el-radio>
        </template>
      </el-form-item>

      <el-form-item label="名称颜色">
          <template>
            <bs-color-picker v-model="curSelData.goodsNameColor" style="float:left;" />
            <el-button type="primary" class="btnClear" @click="clearColor('goodsNameColor')">清除</el-button>

        </template>
      </el-form-item>

      <el-form-item label="商品价格">
          <template>
            <el-radio v-model="curSelData.priceVisible" :label="true">显示</el-radio>
            <el-radio v-model="curSelData.priceVisible" :label="false">不显示</el-radio>
          </template>
      </el-form-item>

      <el-form-item label="价格颜色">
          <template>
            <bs-color-picker v-model="curSelData.priceColor" style="float:left;" />
            <el-button type="primary" class="btnClear" @click="clearColor('priceColor')">清除</el-button>

          </template>
      </el-form-item>

      <el-form-item label="购买按钮">
          <template>
            <el-radio v-model="curSelData.buyButtonStyle" :label="0">不显示</el-radio>
            <el-radio v-model="curSelData.buyButtonStyle" :label="1">样式一</el-radio>
            <el-radio v-model="curSelData.buyButtonStyle" :label="2">样式二</el-radio>
            <el-radio v-model="curSelData.buyButtonStyle" :label="3">样式三</el-radio>
          </template>
      </el-form-item>

      <el-form-item label="按钮颜色">
          <template>
            <bs-color-picker v-model="curSelData.buyButtonColor" style="float:left;" />
            <el-button type="primary" class="btnClear" @click="clearColor('buyButtonColor')">清除</el-button>

          </template>
      </el-form-item>

       <el-form-item label="商品图标">
        <template>
          <el-radio v-model="curSelData.topIconStyleType" :label="0">不显示</el-radio>
          <el-radio v-model="curSelData.topIconStyleType" :label="1">系统图标</el-radio>
        </template>
      </el-form-item>

      <el-form-item label="图标样式" v-if="curSelData.topIconStyleType === 1">
          <template >
            <el-radio v-model="curSelData.topIconStyle" :label="1">样式一</el-radio>
            <el-radio v-model="curSelData.topIconStyle" :label="2">样式二</el-radio>
            <el-radio v-model="curSelData.topIconStyle" :label="3">样式三</el-radio>
          </template>
      </el-form-item>

      <el-form-item label="图标属性" v-if="curSelData.topIconStyleType === 1">
          <template>
            <el-radio v-model="curSelData.topIconTxt" label="推荐">推荐</el-radio>
            <el-radio v-model="curSelData.topIconTxt" label="新上">新上</el-radio>
            <el-radio v-model="curSelData.topIconTxt" label="包邮">包邮</el-radio>
          </template>
      </el-form-item>
      
      <!---->
      <el-card v-for="(item,index) in curSelData.productList" :key="item.name" class="box-card" style="margin-top: 10px">
        <div slot="header" class="clearfix">
          <el-button style="float: right; padding: 3px 0" type="text" @click="delItem(index)">删除</el-button>
        </div>
        <el-row>
          <el-col :span="6" style="position:relative;">
            <el-image :src="item.goodPhoto" style="display: block;height: 74px; width: 80%;">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline" />
              </div>
            </el-image>
            <div class="selProductButton" @click="selProduct(index)">选择商品</div>
          </el-col>
          <el-col :span="18">
            <el-input :disabled="true" v-model="item.goodsName" placeholder="这里是商品标题">
              <template slot="append"><span style="cursor: pointer">名称</span></template>
            </el-input>
            <el-input :disabled="true" v-model="item.price" placeholder="0" style="margin-top: 10px">
              <template slot="append">价格</template>
            </el-input>
          </el-col>
        </el-row>
      </el-card>
      <el-button style="margin:20px auto;width: 80%;display: block" @click="addItem">添加</el-button>
    </el-form>
  </div>
</template>
<script>
import BsColorPicker from '../module/BsColorPicker'
import BsSlider from '../module/BsSlider'
export default {
  components: {
    BsSlider,
    BsColorPicker
  },
  props: {
    curSelData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      inputValue: ''
    }
  },
  methods: {
    addItem(){
      let str='商品名称' + (this.curSelData.productList.length ? this.curSelData.productList.length + 1 : '1')
      this.curSelData.productList.push(
        {
          goodsName: str,
          price: '',
          goodPhoto: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/goods-2.jpeg',
        },
      )
    },
    delItem(index){
      this.curSelData.productList.splice(index, 1)
      // this.curSelData.productList.map((item, index) => {
      //    item.goodsName = '商品名称' + (index+1)
      //  })
    },
    selProduct(index) {
      this.$emit('selProduct', index)
    },
    clearColor(attr) {
      this.curSelData[attr] = 'transparent'
    },
    selImg(type, attr) {
       this.$emit('selImg', type, attr)
    },
    clearTit() {
      this.curSelData.title = ''
    },
    clearSubTit() {
      this.curSelData.subTitle = ''
    }
    
  }
}
</script>
<style scoped>
  .imgClass {
    max-width: 100%;
    margin-top: 20px;
  }
  .selProductButton{
    position: absolute;
    height: 20px;
    left: 0;
    right: 20%;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    color: #fff;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    position: absolute;
    cursor: pointer;
  }
</style>
