<template>
    <div class="rBox">
        <el-form label-width="90px">
            <!-- <el-form-item label="风格">
                <template>
                  <el-radio v-model="curSelData.scanStyle" :label="1">风格一</el-radio>
                  <el-radio v-model="curSelData.scanStyle" :label="2">风格二</el-radio>
                </template>
            </el-form-item> -->
            <el-form-item label="上下边距">
                <template>
                    <bs-slider v-model="curSelData.paddTop" :min="0" :max="100" />
                </template>
            </el-form-item>
            <el-form-item label="左右边距">
                <template>
                    <bs-slider v-model="curSelData.paddLeft" :min="0" :max="100" />
                </template>
            </el-form-item>
            <el-form-item label="二维码背景">
                <el-input v-model="curSelData.scanBg" placeholder="请输入图片地址">
                    <template slot="append"><span style="cursor: pointer" @click="selImg('scanEight','scanBg')">选择图片</span></template>
                </el-input>
            </el-form-item>
			<el-form-item label="二维码背景高度">
			    <template>
			        <bs-slider v-model="curSelData.scanBgHeight" :min="80" :max="200" />
			    </template>
			</el-form-item>
            <el-form-item label="背景颜色" class="formsItem">
                <template>
                    <bs-color-picker v-model="curSelData.bgColor" style="float:left;" />
                    <el-button type="primary" class="btnClear" @click="clearColor('bgColor')">清除</el-button>
                </template>
            </el-form-item>

            <el-form-item label="背景图片">
                <el-input v-model="curSelData.boxBg" placeholder="请输入图片地址">
                    <template slot="append"><span style="cursor: pointer" @click="selImg('scanEight','boxBg')">选择图片</span></template>
                </el-input>
                <el-col :span="18">
                    <img :src="curSelData.boxBg" alt="" class="imgClass">
                </el-col>
            </el-form-item>
			<el-form-item label="上边距" v-show="curSelData.showVerCode === 1">
			    <template>
			        <bs-slider v-model="curSelData.rightTStyle.marginTop" :min="0" :max="100" />
			    </template>
			</el-form-item>
			<el-form-item label="文字大小" v-show="curSelData.showVerCode === 1">
			    <template>
			        <bs-slider v-model="curSelData.rightTStyle.fontSize" :min="0" :max="100" />
			    </template>
			</el-form-item>
			<el-form-item label="对齐方式">
			  <template>
			    <el-radio v-model="curSelData.align" label="left">左对齐</el-radio>
			    <el-radio v-model="curSelData.align" label="center">居中</el-radio>
			    <el-radio v-model="curSelData.align" label="right">右对齐</el-radio>
			  </template>
			</el-form-item>
        </el-form>
    </div>
</template>
<script>
    import BsSlider from '../module/BsSlider'
    import BsColorPicker from '../module/BsColorPicker'
    export default {
        components: {
            BsSlider,
            BsColorPicker
        },
        props: {
            curSelData: {
                type: Object,
                // eslint-disable-next-line vue/require-valid-default-prop
                default: () => {}
            }
        },
        data() {
            return {

            }
        },
        mounted() {
            console.log(this.curSelData)
        },
        methods: {
			clearTent() {
			  this.curSelData.content = ''
			},
            clearColor(attr) {
                this.curSelData[attr] = 'transparent'
            },
            selImg(type, attr) {
                this.$emit('selImg', type, attr)
            },
            addItem() {
                const width = (100 / (this.curSelData.picList.length + 1)).toFixed(2)
                this.curSelData.picList.forEach(item => {
                    item.width = width + '%'
                })
                this.curSelData.picList.push({ width: width + '%', url: '' })
            },
            delPic(index) {
                const width = (100 / (this.curSelData.picList.length - 1)).toFixed(2)
                this.curSelData.picList.forEach(item => {
                    item.width = width + '%'
                })
                this.curSelData.picList.splice(index, 1)
            },
        }
    }
</script>
<style scoped>
    .imgClass{
        width: 200px;
        height: auto;
        margin: 15px 0;
    }
</style>
