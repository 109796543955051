<template>
 <div class="my_select_img_wrap">
  <el-dialog
    append-to-body
    :close-on-click-modal="false"
    :visible="dialogVisible"
    :before-close="closeDialog"
    title="选择商品"
    width="900px"
  >
  <el-table
      :data="tableData"
      border
      style="width: 100%">
      <el-table-column
        prop="goodsName"
        label="商品名称"
        align="center"
       >
      </el-table-column>
      <el-table-column
        prop="price"
        label="价格"
        align="center"
       >
        <template slot-scope="scope">
          <span>¥{{scope.row.price.toFixed(2)}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="stockNum"
        label="库存"
        align="center"
        >
      </el-table-column>
      <el-table-column
        align="center"
        label="操作"
      >
        <template slot-scope="scope">
           <el-button @click="selProduct(scope.row)">选择</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :page-size.sync="params.pageSize"
      :total="total"
      :current-page.sync="params.pageNum"
      style="margin-top: 8px;"
      :page-sizes=pageSizes
      layout="total, prev, pager, next, sizes"
      @size-change="pageSizeChange($event)"
      @current-change="pageChange($event)"
    />
  </el-dialog>
 </div>

</template>

<script>

// import {get} from "@/utils/request";
import axios from 'axios'

import { fetchProductList } from '@/api/customPage'
import {baseImgUrl} from "@/utils/constant";
export default {
  components: {  },
  props: {

  },
  data() {
    return {
      dialogVisible: false,
      params: {pageNum: 1,pageSize: 12},
      total: 0,
      pageSizes: [12,16,20,24],
      tableData: [],
    }
  },
  mounted() {
     this.init()
  },
  methods: {
    pageChange(value){
      this.params.pageNum=value
      this.init()
    },
    pageSizeChange(value){
      this.params.pageSize=value
      this.init()
    },
    selImage(url){
      this.$parent.changeImg(url)
    },
    selProduct(data) {
      this.$parent.changeProduct(data)
    },
    closeDialog(){
      this.dialogVisible=false;
    },
    async init(){
      let result = await fetchProductList(this.params)
      if(result.data) {
        this.total = result.data.total
        this.tableData = result.data.list.map(item => {
          item.price = item.price/100
          item.goodPhoto = baseImgUrl + item.goodPhoto
          return item
        })
      }
    },
    handleClick(value){
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
/deep/ .el-upload--text {
    width: 80px;
    height: 32px;
    text-align: center;
}
/deep/ .el-tabs__nav {
  margin-left: 0!important;
}
</style>
