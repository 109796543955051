<template>
    <div class="ewmBoxWrap" :style="[{backgroundColor:data.bgColor},{ padding: data.paddTop+'px' +' '+ data.paddLeft+'px' },{ backgroundImage: `url(${data.boxBg})`},{ backgroundRepeat: 'no-repeat'}, {backgroundSize: '100% 100%'}]">
       <div class="ewmBoxTitle">
         <span :style="[{color:data.title1Color}]">{{data.title1}}</span><span :style="[{color:data.title2Color}]">{{data.title2}}</span>
        </div>
       <div class="ewmBox">
         <!--左边-->
          <div class="ewmLeftBox2" :style="{backgroundImage: `url(${data.scanBg})`}">
          <div class="ewmImgBox2">
            <vue-qr
              :size="80"
              :margin="0"
              :auto-color="true"
              :dot-scale="1"
              :text="codeUrl"
            />
          </div>
          <div class="yzCode">1111</div>
          </div>
        <!--右边-->
        <div class="ewmRightBox">
          <div class="borderBoder">
            <div v-for="item in data.list" :key="item.name" class="listItem">
              <span :style="[{color:data.keyColor}]" class="keyName">{{item.name}}</span>
              <span :style="[{color:data.valueColor}]" class="valueText">{{item.value}}</span>
            </div>
          </div>
          <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/knf/blueArrow.png" class="arrow"/>

        </div>
       </div>
       <div class="mainInfo">
         <div class="main1"><span>1</span>比对实物标签与照片</div>
         <div class="main2"><span>2</span>观察图片中的文字、位置、编码是否相符</div>
         <div class="main3">以上特征均相符，则是真品，否则谨防假冒</div>

         <div class="style2_msgBox2">
          <div><img src="https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/interImg.png" /></div>
          <div class="rightContent">
            <p class="rightText1">工业互联网标识码：</p>
            <p class="rightText2">{{handle? handle : '---'}}</p>
          </div>
        </div>
         <div class="style2_msgBox3">由国家工信部授权工业互联网标识解析二级节点（86.122）签发</div>

         <div class="yzResBox">
          <div class="style2_msgBox4">初次查询时间为：<span class="yellow pl10">-----</span></div>
          <div class="style2_msgBox5">初次查询地点为：<span class="yellow pl10">-----</span></div>
          <div class="style2_msgBox6">
            <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/knf/knf_search.png" />
               现在是第<span class="yellow">--</span>次查询
          </div>
          <div class="style2_msgBox7">*若查询扫码次数过多，标识码可能被盗用，谨防假冒产品！</div>
         </div>

       </div>
       <div class="h56"></div>
      </div>

</template>

<script>

import VueQr from 'vue-qr'
// import $ from 'jquery'
export default {
  name: 'ScanFive',
  components: {
    VueQr
  },
  props: {
    data: {
      type: Object
    },
    verState: Number,
    handleLink: String,
    handle: String,
    query: {
      type: String
    },
    h: {
      String
    },
    codeData:{
      type: Object
    }
  },
  data() {
    return {
      codeUrl: '',
      veridicalCode: '', // 防伪码
      errTip: false,
      // verState: 0, // 0 未验证 1 验证成功 2 验证失败
      txmDialogVisible: false,
    }
  },
  methods: {
    setCode() {
      if(this.handleLink) {
        this.codeUrl = this.handleLink
      }else {
        this.codeUrl = 'https://api.86122m.com/h?h=aaamz'
      }
    },
    // 点击验证
    toVeridical() {
      if(!this.veridicalCode.trim()) {
        this.errTip = true
        this.$emit('veridicalCode', this.veridicalCode)
      }else {
        this.errTip = false
        // 调接口
        this.$emit('veridicalCode', this.veridicalCode)
      }
    },
    openTxmDialog() {
      this.txmDialogVisible = true
    },
    closeTxmDialog() {
      this.txmDialogVisible = false
    }
  },
  mounted() {
    this.setCode()
  },
  computed: {
    imgStyle(picItem,item) {
      return (n, item) => {
        var wid
        var pos
        var str
        // pos = item.align
        if (n.width) {
          wid = n.width
        } else {
          wid = '100%'
        }
        str = 'width: ' + wid
        if (pos === 'left') {
          console.log('pos', pos)
          str = `width: ${wid};float: ${pos}`
        } else if (pos === 'right') {
          console.log('pos', pos)
          str = `width: ${wid};float: ${pos}`
        } else if (pos === 'center') {
          console.log('pos', pos)
          str = `width: ${wid};margin: 0 auto;`
        }
        return str
      }
    }
  }
}
</script>

<style scoped>

  .ewmBoxWrap {
    width: 100%;
    height: auto;
    background-size: cover;
    position: relative;
    box-sizing: border-box;
    padding: 0 30px;
  }
  .ewmBoxTitle{
    text-align: center;
    font-size: 27px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    padding: 66px 0 0px;
    margin-bottom: 36px;
  }
  .ewmBox{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 23px;
  }
  .ewmLeftBox2{
    width: 115px;
    background-size: 100% 100%;
    height: 173px;
    display: border-box;
    position: relative;
  }
  .ewmLeftBox2 .yzCode {
    position: absolute;
    bottom: 7px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
  }
  .ewmLeftBox2 .ewmImgBox2 {
    margin-top: 60px;
  }

  .ewmRightBox{
    width: 175px;
    display: border-box;
    text-align: left;
  }
  .ewmRightBox .keyName{
    font-size: 12rpx;
    font-family: Source Han Sans CN;
    font-weight: 400;
    padding-right: 5px;
  }
  .borderBoder{
    background: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/knf/knf_border2.png') top center no-repeat;
    background-size: 100% 100%;
    padding: 10px;
    
  }
  .ewmRightBox .valueText{
    font-size: 12rpx;
    font-family: Source Han Sans CN;
    font-weight: 400;
  }
  .ewmRightBox .arrow {
    width: 35px;
    height: 12px;
  }


 .mainInfo{
  width: 325px;
  /* height: 369px; */
  background: #0A2C7A;
  box-sizing: border-box;
  background: #0A2C7A;
  text-align: left;
  padding: 24px 20px 20px;
  margin: 0 auto;

 }
 .main1, .main2{
   font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    vertical-align: middle;
    margin-bottom: 5px;
 }
 .main1 span, .main2 span{
    display: inline-block;
    width: 16px;
    height: 16px;
    color: #393937;
    border-radius: 50%;
    background-color: #EBC995;
    margin-right: 10px;
    text-align: center;

 }
  .main3{
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #EBC995;
    padding-top: 5px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    margin-bottom: 21px;
  }
  
  .style2_msgBox2{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
    width: 100%;
    position: relative;
    box-sizing: border-box;
  }
  .style2_msgBox2 img {
    width: 30px;
    height: 30px;
  }
  .style2_msgBox2 .rightContent .rightText1{
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #FFF;
    line-height: 20px;
    padding-left: 10px;
    text-align: left;
  }
  .style2_msgBox2 .rightContent .rightText2{
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: #78B6EF;
    line-height: 20px;
    padding-left: 10px;
    text-align: left;
  }
  .style2_msgBox3{
    font-size: 12px;
    font-weight: 400;
    color: rgba(255,255,255,0.5);
    line-height: 20px;
    text-align: left;
    margin-bottom: 20px;
  }
  .yzResBox{
    background: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/knf/knf_border.png') top center no-repeat;
    background-size: 100% 100%;
    width: 285px;
    /* height: 133px; */
    margin: 0 auto;
    padding: 21px 15px;
    box-sizing: border-box;
  }

 .style2_msgBox4, .style2_msgBox5{
    font-size: 12px;
    font-weight: 400;
    color: #B3B3B3;
    text-align: left;
    margin-bottom: 14px;
    text-align: left;
  }
  .yellow{
    color: #EBC995;
  }
  .pl10{
    padding-left: 5px;
  }

   .style2_msgBox6{
    font-size: 14px;
    font-weight: 400;
    color: #FFF;
    text-align: left;
  }
  .style2_msgBox6 img {
    width: 14px;
    height: 14px;
    vertical-align: middle;
    margin-right: 5px;
  }

  .style2_msgBox7{
    font-size: 12px;
    font-weight: 400;
    color: rgba(235, 201, 149, 0.6);
    text-align: left;
    padding-top: 19px;
  }
 
  .red{
    color: #f00;
  }
  .h56{
    height: 56px;
  }
    .listItem{
      margin-bottom: 5px;
    }
</style>
