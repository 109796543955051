<template>
  <div :style="{ 'borderBottom': item.bottomshow ? '5px solid #F0F2F7' : '' }">
    <div>
      <!-- <div class="subTitle">{{item.subTitle}}</div> -->
      <div class="top">
        <img class="icon" :src="item.icon" alt="">
        <div class="title">{{ item.title }}</div>
      </div>

        <div class="productWrapper2 clearfix">
          <div class="productBlock" v-for="proItem in item.productList" :key="proItem.goodsName"
            @click="goDetailPage(proItem)" :style="{ backgroundColor: `${item.proBgColor}` }">
            <template v-if="item.topIconStyleType !== 0">
              <div v-if="item.topIconStyle === 1" class="topIcon1">{{ item.topIconTxt }}</div>
              <div v-if="item.topIconStyle === 2" class="topIcon2">{{ item.topIconTxt }}</div>
              <div v-if="item.topIconStyle === 3" class="topIcon3">{{ item.topIconTxt }}</div>
            </template>
            <div class="imageWrap"
              :style="[{ backgroundImage: `url(${proItem.goodPhoto})`, height: `${item.imageHeight}px` }]">
            </div>
            <div class="spinfo">
              <div class="info">
                <div class="hot">{{ proItem.type }}</div>
                <div class="goosName" v-if="item.goodsNameVisible" :style="{ color: item.goodsNameColor }">
                  {{ proItem.goodsName }}</div>
              </div>
              <div class="spname">{{ proItem.spname }}</div>
            </div>

            <!-- <div class="goosPrice" v-if="item.priceVisible" :style="{color: item.priceColor}"><span class="symbol">￥</span>{{proItem.price}}</div> -->
            <!-- <div class="buyButtonWrap" v-if="item.buyButtonStyle!==0">
                        <div class="button1" v-if="item.buyButtonStyle===1" :style="{borderColor: item.buyButtonColor, color: item.buyButtonColor}">询问</div>
                        <div class="button2" v-if="item.buyButtonStyle===2" :style="{borderColor: item.buyButtonColor, backgroundColor: item.buyButtonColor}">询问</div>
                        <div class="button3" v-else-if="item.buyButtonStyle===3" :style="{borderColor: item.buyButtonColor, backgroundColor: item.buyButtonColor}">立即抢购</div>
                      </div> -->
          </div>
        </div>

    </div>
  </div>
</template>
<script>

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    styleObject() {
      return {
        // padding: this.item.paddTop + 'px' + ' ' + this.item.paddLeft + 'px',
        // textAlign: this.item.align,
        // fontSize: this.item.fontSize + 'px',
        // fontWeight: this.item.weight,
        // color: this.item.textColor
        backgroundColor: this.item.bgColor,
        backgroundImage: `url(${this.item.bgUrl})`,
        backgroundImageSize: '100% 100%',
        backgroundRepeat: 'no-repeat'
      }
    }
  },
  methods: {
    toMiniProgram(data, url, appId) {
      let { cardId, goodsId } = data
      console.log('cardId', cardId)
      console.log('goodsId', goodsId)
      wx.navigateToMiniProgram({
        appId: appId || "wx19adaa0665cc0c6a", // 要跳转的小程序的appid // wx1c06c4e49bf6040e
        path: `pages/prodDetail/main?goodsId=${goodsId}&cardId=${cardId}&from=111`, // 跳转的目标页面
        // envVersion: 'develop', // 仅在当前小程序为开发版或体验版时此参数有效。如果当前小程序是正式版，则打开的小程序必定是正式版。
        extarData: {
          // goodsId: 2,
          // cardId: 17785,
          // from: 111, // 111 代表小程序标记
        },
        success(res) { },
        fail(res) {
          console.log(res);
        }
      });
    },
    goDetailPage(data) {
      let { cardId, goodsId } = data
      console.log(data)
      uni.navigateTo({
        url: `/pages/productDetail/index?goodsId=${goodsId}&cardId=${cardId}&from=1`
      });
    }
  }
}
</script>

<style scoped>
.page {
  border-bottom: 5px solid #F0F2F7;
}
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
  border-bottom: 1px solid #F5F6FA;
}

.spinfo {
  padding: 10px;
  background: #fff;
  border-radius: 0 0 10px 10px;
  border: 1px solid #F5F6FA;
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.spname {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #A2ADB2;
  text-align: left;
  padding: 10px 0 0;
}

.hot {
  width: 56px;
  height: 20px;
  border-radius: 10px;
  background: red;
  font-size: 9px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 20px;
}

.icon {
  width: 22px;
  height: 25px;
  margin-right: 10px;
}

.subTitle {
  font-size: 12px;
  padding: 10px 0 0 0;
  color: #333;
}

.title {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  padding: 10px 0;
}

.productBlock {
  position: relative;
  overflow: hidden;
  border-radius: 2px;
}

.productBlock .imageWrap {
  width: 100%;
  height: 168px;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

/* .productBlock .imageWrap image {
    width: 100%;
    height: 168px;
  } */
.productBlock .goosName {
  line-height: 22px;
  /* width: 100%; */
  /* padding-top: 10px; */
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  /* 这里是超出几行省略 */
  /* height: 40px; */
  overflow: hidden;

  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #26353D;
  flex: 1;

}

.productBlock .goosPrice {
  font-size: 16px;
}

.productBlock .goosPrice .symbol {
  font-size: 12px;
}

.buyButtonWrap .button1 {
  font-size: 12px;
  border: 1px solid #f00;
  color: #f00;
  padding: 2px 8px;
  display: inline-block;
  border-radius: 3px;
}

.buyButtonWrap .button2 {
  font-size: 12px;
  border: 1px solid #f00;
  background-color: #f00;
  color: #fff;
  padding: 2px 8px;
  display: inline-block;
  border-radius: 3px;
}

.button3 {
  width: 80%;
  margin: 0 auto;
  border-radius: 27px;
  color: #fff;
  padding: 3px 0;
  margin-bottom: 10px;
  margin-top: 10px;
}

.topIcon1 {
  position: absolute;
  width: 3.39rem;
  height: 2.4rem;
  background: #ff0000;
  z-index: 0;
  transform-origin: left bottom;
  -webkit-transform-origin: left bottom;
  transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  color: #fff;
  line-height: 3.7rem;
  font-size: 0.6rem;
  text-align: center;
}

.topIcon2 {
  position: absolute;
  height: 1rem;
  width: 3.4rem;
  background: #ff7104;
  top: 1.4rem;
  color: #fff;
  font-size: 0.6rem;
  line-height: 1rem;
  text-align: center;
  transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom;
}

.topIcon3 {
  position: absolute;
  width: 2.25rem;
  height: 1.2rem;
  background: #04a6f8;
  z-index: 0;
  text-align: center;
  color: #fff;
  font-size: 0.6rem;
  line-height: 1rem;
}

/*排版*/
.productWrapper1,
.productWrapper2,
.productWrapper3 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
}

.productWrapper1 .productBlock {
  width: 98%;
  margin: 5px auto;
  box-sizing: border-box;
}

.productWrapper1 .productBlock .imageWrap {
  height: 210px;
}

.productWrapper2 .productBlock {
  box-sizing: border-box;
  width: 46%;
  margin: 5px;
  display: inline-block;
  float: left;
}

.productWrapper3 .productBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.productWrapper3 .productBlock .imageWrap {
  max-width: 60%;
}

.productWrapper3 .productBlock .rightBox {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-sizing: border-box;
  padding-right: 10px;
}

.productWrapper3 .productBlock .goosName {
  padding-top: 0;
}

.productWrapper1 .productBlock .imageWrap {
  background-size: 100% 100%;
}

.productWrapper3 .productBlock .imageWrap {
  background-size: 100% 100%;
}
.bottom1 {
  border-bottom: 5px solid #F0F2F7;
}
</style>