<template>
  <div>
    <el-card>
      <el-row>
        <el-col :span="6">
          <el-image :src="curSelData.homeImg" style="display: block;height: 74px;width: 148px;max-width: 90%">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline" />
            </div>
          </el-image>
        </el-col>
        <el-col :span="18">
          <el-input v-model="curSelData.homeImg" placeholder="请输入图片地址">
            <template slot="append"><span style="cursor: pointer" @click="selImg('BLDWine', 'homeImg')">选择图片</span></template>
          </el-input>
          <!-- <el-input v-model="item.link" placeholder="请输入链接" style="margin-top: 10px">
            <template slot="append">选择链接</template>
          </el-input> -->
        </el-col>
        <!-- <el-input v-model="item.width" placeholder="请输入图片宽度" style="margin-top: 10px">
          <template slot="append">图片宽度</template>
        </el-input> -->
      </el-row>

      <!--子页面-->
      <el-form>
       <el-card v-for="(item,index) in curSelData.tabList" :key="item.name" class="pageCard">
          <div slot="header" class="clearfix">
            <el-button style="float: right; padding: 3px 0" type="text" @click="delPage(index)">删除</el-button>
          </div>
          <el-row>
              <el-form-item label="按钮名称">
                  <el-input v-model="item.name" placeholder="请输入按钮名称" style="margin-top: 10px">
                  </el-input>
              </el-form-item>
              <!--子页面轮播 start-->
              <el-form-item label="">
                <el-row v-for="(pItem, pIndex) in item.picList" :key="pItem.img" class="mb10">
                  <el-col :span="24">
                    <span class="delBtn" @click="delImg(index, pIndex)">删除</span>
                  </el-col>
                  <el-col :span="6">
                    <el-image :src="pItem.img" style="display: block;height: 74px;width: 148px;max-width: 90%">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline" />
                      </div>
                    </el-image>
                  </el-col>
                  <el-col :span="18">
                    <el-input v-model="pItem.img" placeholder="请输入图片地址">
                      <template slot="append"><span style="cursor: pointer" @click="selImg('BLDWine', 'picList', pIndex, index)">选择图片</span></template>
                    </el-input>
                    <!-- <el-input v-model="item.link" placeholder="请输入链接" style="margin-top: 10px">
                      <template slot="append">选择链接</template>
                    </el-input> -->
                  </el-col>

                  <!-- <el-input v-model="item.width" placeholder="请输入图片宽度" style="margin-top: 10px">
                    <template slot="append">图片宽度</template>
                  </el-input> -->
                </el-row>
              </el-form-item>
              <el-button style="margin:20px auto;width: 80%;display: block" @click="addImg(index)" v-if="!item.isSwipe">添加图片</el-button>
              <!--子页面轮播 end-->

              <el-form-item label="是否有底部轮播">
                  <el-radio v-model="item.isSwipe" :label="true">是</el-radio>
                  <el-radio v-model="item.isSwipe" :label="false">否</el-radio>
              </el-form-item>
              <!--底部轮播图片编辑 start-->
              <div v-if="item.isSwipe" class="ml10">
                <el-row v-for="(bImgItem, bImgIndex) in item.swiperList" :key="bImgItem.img">
                  <el-col :span="24">
                    <span class="delBtn" @click="delBotImg(index, bImgIndex)">删除</span>
                  </el-col>
                  <el-col :span="6">
                    <el-image :src="bImgItem.img" style="display: block;height: 74px;width: 148px;max-width: 90%">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline" />
                      </div>
                    </el-image>
                  </el-col>
                  <el-col :span="18">
                    <el-input v-model="bImgItem.img" placeholder="请输入图片地址">
                      <template slot="append"><span style="cursor: pointer" @click="selImg('BLDWine', 'swiperList', bImgIndex, index)">选择图片</span></template>
                    </el-input>
                  </el-col>
                </el-row>
                <el-button style="margin:20px auto;width: 80%;display: block" @click="addBotImg(index)" v-if="item.isSwipe">添加底部轮播图片</el-button>
              </div>
              <!--底部轮播图片编辑 end-->

              
          </el-row>
       </el-card>
     
      </el-form>
      <el-button style="margin:20px auto;width: 80%;display: block" @click="addPage">添加按钮</el-button>
    </el-card>
    <!-- <el-card v-for="(item,index) in curSelData.picList" :key="item.url + index" class="box-card" style="margin-top: 10px">
      <div slot="header" class="clearfix">
        <el-button style="float: right; padding: 3px 0" type="text" @click="delPic(index)">删除</el-button>
      </div>
      <el-row>
        <el-col :span="6">
          <el-image :src="item.url" style="display: block;height: 74px;width: 148px;max-width: 90%">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline" />
            </div>
          </el-image>
        </el-col>
        <el-col :span="18">
          <el-input v-model="item.url" placeholder="请输入图片地址">
            <template slot="append"><span style="cursor: pointer" @click="selImg(index)">选择图片</span></template>
          </el-input>
          <el-input v-model="item.link" placeholder="请输入链接" style="margin-top: 10px">
            <template slot="append">选择链接</template>
          </el-input>
        </el-col>
        <el-input v-model="item.width" placeholder="请输入图片宽度" style="margin-top: 10px">
          <template slot="append">图片宽度</template>
        </el-input>
      </el-row>
    </el-card> -->


    <!-- <el-card v-for="(item,index) in curSelData.picList" :key="item.url+index+'edit'" class="box-card" style="margin-top: 10px">
      <div slot="header" class="clearfix">
        <el-button style="float: right; padding: 3px 0" type="text" @click="delPic(index)">删除</el-button>
      </div>
      <el-row>
        <el-col :span="6">
          <el-image :src="item.url" style="display: block;height: 74px;width: 148px;max-width: 90%">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline" />
            </div>
          </el-image>
        </el-col>
        <el-col :span="18">
          <el-input v-model="item.url" placeholder="请输入图片地址">
            <template slot="append"><span style="cursor: pointer" @click="selImg('swipe','picList',index,curSelData.imgType)">选择图片</span></template>
          </el-input>
          <el-input v-model="item.link" placeholder="请输入内容" style="margin-top: 10px">
            <template slot="append">选择链接</template>
          </el-input>
        </el-col>
      </el-row>
    </el-card> -->

  </div>
</template>
<script>
export default {
  props: {
    curSelData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      OnePageDefautData: {
        name: '',
        picList:[],
        isSwipe: false,
        swiperList: [

        ]
      },
      OneImgDefaultData: {
        img: ''
      }
    }
  },
  methods: {
    selImg(type, attr, index, pIdx) {
      this.$emit('selImg', type, attr, index, pIdx)
    },
    addPage() {
      this.curSelData.tabList.push(this.OnePageDefautData)
    },
    delPage(index) {
       this.curSelData.tabList.splice(index, 1)
    },
    addImg(index) {
      this.curSelData.tabList[index].picList.push(this.OneImgDefaultData)
    },
    delImg(index, pIndex) {
      this.curSelData.tabList[index].picList.splice(pIndex, 1)
    },
    delBotImg(index, pIndex) {
      this.curSelData.tabList[index].swiperList.splice(pIndex, 1)
    },
    addBotImg(index) {
      this.curSelData.tabList[index].swiperList.push(this.OneImgDefaultData)
    }
  }
}
</script>
<style scoped>
.pageCard{
  margin-top: 20px;
}
.mb10{
  margin-bottom: 10px;
}
.delBtn{
  color: #f00;
  text-align: right;
  cursor: pointer;
  float: right;
}
.ml10{
  margin-left: 10px;
}

</style>