<template>
  <div>
    <el-form label-width="100px">

      <el-form-item label="距底部距离">
        <template>
          <bs-slider v-model="curSelData.bottom" :min="0" :max="1000" />
        </template>
      </el-form-item>
      <el-form-item label="头像图片">
       <el-input v-model="curSelData.imgUrl" placeholder="请输入图片地址">
          <template slot="append"><span style="cursor: pointer" @click="selImg('chatFix','imgUrl')">选择图片</span></template>
        </el-input>
      </el-form-item>
      <el-form-item label="文字">
        <template>
          <el-input v-model="curSelData.text" placeholder="请输入文字"></el-input>
        </template>
      </el-form-item>
      <el-form-item label="按钮颜色">
        <bs-color-picker v-model="curSelData.btnBgColor" style="float: left" />
        <el-button type="primary" class="btnClear" @click="clearColor('btnBgColor')">清除</el-button>
      </el-form-item>
      <el-form-item label="咨询字段">
        <el-input v-model="curSelData.zx_key" placeholder="请输入字段名" style="margin-top: 10px" :disabled="true">
        </el-input>
      </el-form-item>
      <el-form-item label="指定客服">
        <el-row>
          <el-col :span="12">
            <el-input v-model="curSelData.zx_name" placeholder="指定客服">
              <template slot="append"><span style="cursor: pointer" @click="selKf">指定客服</span></template>
            </el-input>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
    
  </div>
</template>
<script>
import BsSlider from '../module/BsSlider'
import BsColorPicker from '../module/BsColorPicker'
export default {
  components: {
    BsSlider,
    BsColorPicker
  },
  props: {
    curSelData: {
      type: Object,
      required: true
    },
  },
  methods: {
    selImg(type, attr, index) {
      this.$emit('selImg', type, attr, index)
    },
    clearColor(attr) {
      this.curSelData[attr] = 'transparent'
    },
    selKf() {
      this.$emit('selKf')
    },
  }
}
</script>