import httpUtil from "@/utils/httpUtil";

/***添加页面类别 */
export const addPageClass = async params => httpUtil.post("/pageClass", params);

/**修改页面类别**/
export const updatePageClass = async params => httpUtil.post("/pageClass", params);

/***列表 */
export const pageClassList = async params => httpUtil.get("/pageClass", params);

export const noPage = async params => httpUtil.get("/pageClass/noPage", params);

/***删除 */
export function deleteCustomPageClass(id) {
    return httpUtil.delete(`/pageClass/delete/${id}`)
}

/****分类绑定页面/图片****/
export const bindPageClass = async params=> httpUtil.post("/pageClass/bind", params);

/***新增/编辑部门  ****/
export const fetchAddOrEditDep = async params => httpUtil.post("/department/addOrupdate", params);

/***部门列表****/
export const fetchDepList = async params => httpUtil.get("/department", params);

