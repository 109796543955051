<template>
	<div>
		<el-card class="box-card" style="margin-top: 10px">
			<el-form label-width="90px">
				<el-row>
					<el-col :span="6">
						<el-image :src="curSelData.url"
							style="display: inline-block;height: 74px;width: 148px;max-width: 90%">
							<div slot="error" class="image-slot">
								<i class="el-icon-picture-outline" />
							</div>
						</el-image>
					</el-col>
					<el-col :span="18">
						<el-input v-model="curSelData.url" placeholder="请输入图片地址">
							<template slot="append"><span style="cursor: pointer"
									@click="selImg('userInfo', 'url', index)">选择图片</span></template>
						</el-input>
						<el-input v-model="curSelData.link" placeholder="请输入链接" style="margin-top: 10px">
							<template slot="append">选择链接</template>
						</el-input>
					</el-col>
				</el-row>
			</el-form>
		</el-card>
		<el-form label-width="90px">
			<el-card v-for="(item,index) in curSelData.info" :key="index" class="box-card"
				style="margin-top: 10px">
				<div slot="header" class="clearfix">
					<el-button style="float: right; padding: 3px 0" type="text" @click="delItem(index)">删除</el-button>
				</div>
				<el-form-item label="标题">
					<el-input v-model="item.lable" />
				</el-form-item>
				<el-form-item label="内容">
					<el-input v-model="item.text" />
				</el-form-item>
			</el-card>
			<el-button style="margin:20px auto;width: 80%;display: block" @click="addItem">添加</el-button>
		</el-form>
		<el-form label-width="90px">
			<el-row>
				<el-col :span="24">
					<el-input v-model="curSelData.handle" placeholder="请输入标识" style="margin-top: 10px">
						<template slot="append">标识</template>
					</el-input>
				</el-col>
			</el-row>
		</el-form>
		<el-form label-width="90px">
			<el-row>
				<el-col :span="24">
					<el-input v-model="curSelData.erwm" placeholder="请输入二维码地址" style="margin-top: 10px">
						<template slot="append">二维码地址</template>
					</el-input>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<script>

	export default {
		components: {
		},
		props: {
			curSelData: {
				type: Object,
				required: true
			}
		},
		data() {
			return {

			}
		},
		methods: {
			selImg(type, attr, index) {
				this.$emit('selImg', type, attr, index)
			},
			addItem() {
				this.curSelData.info.push({
					lable: '标题',
					text: '内容'
				})
			},
			delItem(index) {
				this.curSelData.info.splice(index, 1)
			}
		}
	}
</script>

<style>
</style>
