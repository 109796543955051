<template>
	<div class="page">
		<div class="topC"
			:style="[{ backgroundImage: `url(${item.topUrl})`},{ backgroundRepeat: 'no-repeat'}, {backgroundSize: '100% 100%'}]">
			<div class="title" :style="[{fontSize: item.sfontSize+'px'}, {color: item.scolor}]">{{item.sTitle}}</div>
			<div class="title" :style="[{fontSize: item.bfontSize+'px'}, {color: item.bcolor}]">{{item.bTitle}}</div>
			<div style="box-sizing: border-box;margin-top: 35px;">
				<van-swipe :autoplay="3000" indicator-color="white">
					<van-swipe-item v-for="(picItem,index) in item.picList" :key="picItem.url+index">
						<div class="itemTitle"
							:style="[{ backgroundImage: `url(${item.filletImg})`},{ backgroundRepeat: 'no-repeat'}, {backgroundSize: '100% 100%'}, {width: '80%'}, {margin: '0 auto 10px'}, {padding: '10px 20px'}, {fontSize: picItem.fontSize+'px'}, {color: picItem.color}]">
							{{picItem.title}}
						</div>
						<van-image width="100%" :src="picItem.url" class="vanImgs" />
					</van-swipe-item>
				</van-swipe>
			</div>
		</div>
		<div>
			<div>
				<div class="otitle"
					:style="[{ backgroundImage: `url(${item.bgImg})`},{ backgroundRepeat: 'no-repeat'}, {backgroundSize: '100% 100%'}, {width: '100%', height: '80px', textAlign: 'left'}]">
					<div style="width: 100%">
						<div style="display: flex;align-items: center;justify-content: space-between;">
							<div :style="[{fontSize: item.oCFontSize+'px'}, {color: item.oCColor}, {fontWeight: '800'}]">
								{{item.oCTitle}}</div>
							<div style="display: flex;align-items: center;">
								<img style="width: 110px;" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/zlgl/line@2x.png"
									alt="">
								<img @click="flag = !flag" style="width:20px;height:20px"
									src="https://mzmpic.oss-cn-beijing.aliyuncs.com/zlgl/zk@2x.png" alt="">
							</div>
						</div>
				
						<div :style="[{fontSize: item.oEFontSize+'px'}, {color: item.oEColor}]">{{item.oETitle}}</div>
					</div>
				
				</div>
				<div v-if="flag" style="position: relative;top: -20px;">
					<div v-for="(data, index) in item.listData" :key="index" class="list">
						<div class="line"></div>
						<div class="name">
							<div>
								<span class="block"></span>
								<span :style="[{fontSize: data.nfontSize+'px'}, {color: data.ncolor}]">{{data.name}}</span>
							</div>
							<img style="width: 45px;height: 33px;" :src="data.numImg" />
						</div>
						<div :style="[{fontSize: data.cfontSize+'px'}, {color: data.ccolor}]" class="content" v-html="data.content">
						</div>
					</div>
				</div>
			</div>
			<div>
				<!--流程图标题-->
				<div class="otitle"
					:style="[{ backgroundImage: `url(${item.bgImg})`},{ backgroundRepeat: 'no-repeat'}, {backgroundSize: '100% 100%'}, {width: '100%', height: '80px', textAlign: 'left'}]">
					<div style="width: 100%">
						<div style="display: flex;align-items: center;justify-content: space-between;">
							<div :style="[{fontSize: item.tCFontSize+'px'}, {color: item.tCColor}, {fontWeight: '800'}]">
								{{item.tCTitle}}</div>
							<div style="display: flex;align-items: center;">
								<img style="width: 110px;" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/zlgl/line@2x.png"
									alt="">
								<img style="width:20px;height:20px" @click="flag2 = !flag2"
									src="https://mzmpic.oss-cn-beijing.aliyuncs.com/zlgl/zk@2x.png" alt="">
							</div>
						</div>
						<div :style="[{fontSize: item.tEFontSize+'px'}, {color: item.tEColor}]">{{item.tETitle}}</div>
					</div>
          <!--装饰图-->
					<img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/fangyuanzhengshu/zs1.png" class="zs1"/>
				</div>
				<!--流程图-->
				<div v-if="flag2" class="lctImgBox">
					<img :src="item.tImg" class="lctImg" />
				</div>
			</div>

			<div>
				<!--档案标题-->
				<div class="otitle"
					:style="[{ backgroundImage: `url(${item.bgImg})`},{ backgroundRepeat: 'no-repeat'}, {backgroundSize: '100% 100%'}, {width: '100%', height: '80px', textAlign: 'left'}]">
					<div style="width: 100%">
						<div style="display: flex;align-items: center;justify-content: space-between;">
							<div :style="[{fontSize: item.dCFontSize+'px'}, {color: item.dCColor}, {fontWeight: '800'}]">
								{{item.dCTitle}}</div>
							<div style="display: flex;align-items: center;">
								<img style="width: 210px;" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/zlgl/line@2x.png"
									alt="">
							</div>
							<!--两个装饰图-->
							<img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/fangyuanzhengshu/zs1.png" class="zs2"/>
							<img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/fangyuanzhengshu/zs2.png" class="zs3"/>
						</div>
						<div :style="[{fontSize: item.dEFontSize+'px'}, {color: item.dEColor}]">{{item.dETitle}}</div>
					</div>
				</div>
				
				<div class="diImgBox">
				  <img :src="item.dImg" class="dImg"/>
				</div>
				<!--档案轮播图-->
				<div class="lbtWrap">
					<van-swipe :autoplay="3000" indicator-color="white">
						<van-swipe-item v-for="(picItem,index) in item.picList2" :key="picItem.url+index">
							<van-image :src="picItem.url" class="lbtImg" />
						</van-swipe-item>
				  </van-swipe>
				</div>
			</div>

      <div>
				<!--企业介绍-->
				<div class="otitle"
					:style="[{ backgroundImage: `url(${item.bgImg})`},{ backgroundRepeat: 'no-repeat'}, {backgroundSize: '100% 100%'}, {width: '100%', height: '80px', textAlign: 'left'}]">
					<div style="width: 100%">
						<div style="display: flex;align-items: center;justify-content: space-between;">
							<div :style="[{fontSize: item.qCFontSize+'px'}, {color: item.qCColor}, {fontWeight: '800'}]">
								{{item.qCTitle}}</div>
							<div style="display: flex;align-items: center;">
								<img style="width: 210px;" src="https://mzmpic.oss-cn-beijing.aliyuncs.com/zlgl/line@2x.png"
									alt="">
							</div>
							<!--一个装饰图-->
							<img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/fangyuanzhengshu/zs1.png" class="zs4"/>
						</div>
						<div :style="[{fontSize: item.qEFontSize+'px'}, {color: item.qEColor}]">{{item.qETitle}}</div>
					</div>
				</div>

				<div class="qyWrap">
					<div class="qyTitBox">
					{{item.qyTit}}</div>
					<div class="qyLogoWrap">
						<img class="qyLogo" :src="item.qyLogo" />
					</div>
					<div v-html="item.qyContent" class="qyContent"></div>
					<div>
						<img :src="item.qyImg" class="qyImg" />
					</div>
				</div>

     </div>

		</div>
	</div>
</template>
<style lang="scss" scoped>
	.page {
		width: 100%;
		background: #BBD9FF;
		padding-bottom: 20px;
	}

	.topC {
		width: 100%;
		height: 562px;
		padding: 50px;
		box-sizing: border-box;
	}

	.title {
		box-sizing: border-box;
		padding: 5px 0;
	}

	.itemTitle {
		font-family: Source Han Sans CN;
		font-weight: 500;
	}

	.otitle {
		display: flex;
		align-items: center;
		box-sizing: border-box;
		padding: 0 30px;
		background: linear-gradient(90deg, #DBEBFF 0%, #BBD9FF 100%);
		position: relative;
	}
	.otitle .zs1 {
		position: absolute;
		width: 40px;
		height: 40px;
		top: 20px;
		left: 180px;
	}
	.otitle .zs2{
		position: absolute;
		width: 40px;
		height: 40px;
		top: 25px;
		left:130px;
	}

	.otitle .zs3{
		position: absolute;
		width: 20px;
    top: 15px;
		right:10px;
	}
	.otitle .zs4{
		position: absolute;
		width: 40px;
    top: 40px;
		right:30px;
	}

	.otitle>div {
		display: flex;
		align-items: left;
		flex-direction: column;
	}

	.otitle>div>div {
		padding: 2px 0;
	}

	.list {
		background: #E3EFFF;
		border: 2px solid #FFFFFF;
		box-shadow: 0px 4px 49px 0px rgba(154, 188, 230, 0.6);
		border-radius: 20px;
		padding: 15px 5px;
		box-sizing: border-box;
		margin: 10px;
		position: relative;
	}

	.line {
		position: absolute;
		left: 0px;
		top: 15px;
		z-index: 1;
		width: 110px;
		height: 20px;
		background: linear-gradient(120deg, #87B4FF 0%, #E3EFFF 100%);
	}

	.name {
		z-index: 9;
		width: 100%;
		height: 36px;
		background: #FF9F07;
		border: 2px solid #FFF085;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		box-sizing: border-box;
		padding: 0 10px;
		position: relative;
		top: 5px;
	}

	.block {
		width: 8px;
		height: 8px;
		background: #FFFFFF;
		display: inline-block;
		margin-right: 5px;
	}

	.content {
		margin: 20px 0 10px 0;
		text-align: left;
		line-height: 20px;
	}
	.lctImgBox{
		position: relative;
		top: -10px;
	}
	.lctImg{
		width: 90%;
	}
	
	.lbtWrap {
		border: 2px solid #FFFFFF;
		box-shadow: 0px 4px 49px 0px rgba(154,188,230,0.6);
		border-radius: 20px;
		padding: 20px 15px;
		background: rgba(227, 239, 255, 1);
		width: 97%;
		margin: 0 auto;
		box-sizing: border-box;
		position: relative;
		top: -10px;
	}
	.lbtwrap .lbtImg{
		width: 100%;
	}

	.qyWrap{
		border: 2px solid #FFFFFF;
		box-shadow: 0px 4px 49px 0px rgba(154,188,230,0.6);
		border-radius: 20px;
		padding: 20px 15px;
		background: rgba(227, 239, 255, 1);
		width: 97%;
		margin: 0 auto;
		box-sizing: border-box;
		position: relative;
		top: -10px;
	}
	.qyTitBox{
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 500;
		color: #2E3033;
		padding: 14px 32px;
		background: linear-gradient(92deg, rgba(107, 134, 255, 0.2) 0%, rgba(237, 245, 255, 0.2) 100%);
		position: relative;
		display: flex;
	}
	.qyTitBox::after{
		content: "";
		display: block;
		width: 10px;
		position: absolute;
		top:0;
		left:0;
		bottom: 0;
		background: #3C65E5;
		border-radius: 0px 6px 6px 0px;
	}
	.qyLogo{
		width: 151px;
		margin-top: 32px;
		margin-bottom: 10px;
	}
	.qyLogoWrap{
		display: flex;
	}
	.qyContent{
		font-size: 12rpx;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #43464A;
		margin-bottom: 26px;
		text-align: left;
	}
	.qyImg{
		width: 100%;
		margin-bottom: 10px;
	}
	.diImgBox{
		width: 100%;
		padding: 0 10px;
		box-sizing: border-box;
		position: relative;
		top: -15px;
	}
	.dImg{
		width: 100%;
	}
</style>
<script>
	import {
		Image as VanImage,
		Swipe,
		SwipeItem
	} from 'vant'
	export default {
		data() {
			return {
				show: false,
				flag: true,
				flag2: true,
			}
		},
		components: {
			[Swipe.name]: Swipe,
			[SwipeItem.name]: SwipeItem,
			[VanImage.name]: VanImage
		},
		props: {
			item: {
				type: Object,
				required: true
			}
		},
		mounted() {
			console.log(this.item)
		},
		methods: {
			toShow() {
				this.show = !this.show
			}
		},
		computed: {

		},
	}
</script>
