<template>
  <div>
    <el-form label-width="90px">
      <el-form-item label="上边距">
          <template>
          <bs-slider v-model="curSelData.paddTop" :min="0" :max="100" />
          </template>
      </el-form-item>
      <el-form-item label="下边距">
          <template>
          <bs-slider v-model="curSelData.paddBottom" :min="0" :max="100" />
          </template>
      </el-form-item>
      <el-form-item label="底边框">
          <template>
              <el-radio v-model="curSelData.bottomshow" :label="true">显示</el-radio>
              <el-radio v-model="curSelData.bottomshow" :label="false">不显示</el-radio>
          </template>
      </el-form-item>
    </el-form>
     <el-card v-for="(item,index) in curSelData.videoList" :key="item.url + index" class="box-card" style="margin-top: 10px">
      <div slot="header" class="clearfix">
        <el-button style="float: right; padding: 3px 0" type="text" @click="delVideo(index)">删除</el-button>
      </div>
      <el-row>
        <el-col>
          <el-input v-model="item.url" placeholder="请输入视频链接">
            <template slot="append"><span style="cursor: pointer" @click="selVideo(index)">选择视频</span></template>
          </el-input>
        </el-col>
      </el-row>
      <el-row style="margin-top: 10px;">
          <el-input v-model="item.poster" placeholder="请输入图片地址">
            <template slot="append"><span style="cursor: pointer" @click="selImg('swiperVideo','poster',index)">选择封面</span></template>
          </el-input>
          <el-col :span="18">
            <img :src="item.poster" alt="" class="imgClass">
          </el-col>
      </el-row>
    </el-card>
    <el-button style="margin:20px auto;width: 80%;display: block" @click="addItem">添加</el-button>
  </div>
</template>
<script>
import BsSlider from '../module/BsSlider'
// import BsColorPicker from '../module/BsColorPicker'
export default {
  components: {
    BsSlider,
    // BsColorPicker
  },
  props: {
    curSelData: {
      type: Object,
      required: true
    },
  },
  methods: {
    addItem() {
      this.curSelData.videoList.push({
        url: '',
        poster: '',
      })
    },
    delVideo(index) {
      this.curSelData.videoList.splice(index, 1)
    },
    selVideo(index) {
      this.$emit('selVideo', 'swiperVideo', 'videoList', index)
    },
    selImg(type, attr, index) {
      this.$emit('selImg', type, attr,index)
    },
    
  }
}
</script>
<style scoped>
.imgClass{
  width: 80%;
  margin: 5% 0 0 15%;
}
</style>