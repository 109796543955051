<template>
  <div class="rBox">
    <el-divider content-position="left">页面设置</el-divider>
    <el-form label-width="90px">
      <el-form-item label="页面名称">
        <template>
          <el-input
            v-model="curSelData.pageName"
            type="text"
            placeholder="请输入页面名称"
          />
        </template>
      </el-form-item>
      <el-form-item label="上下边距">
        <template>
          <bs-slider v-model="curSelData.paddTop" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="左右边距">
        <template>
          <bs-slider v-model="curSelData.paddLeft" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="背景颜色">
        <template>
          <bs-color-picker v-model="curSelData.bgColor" />
        </template>
      </el-form-item>
      <el-form-item label="字体颜色">
        <template>
          <bs-color-picker v-model="curSelData.textColor" />
        </template>
      </el-form-item>
      <el-form-item label="字体大小">
        <template>
          <bs-slider v-model="curSelData.fontSize" :min="9" :max="30" />
        </template>
      </el-form-item>
      <el-form-item label="字体粗细">
        <template>
          <el-radio v-model="curSelData.weight" label="normal">正常</el-radio>
          <el-radio v-model="curSelData.weight" label="bold">加粗</el-radio>
          <el-radio v-model="curSelData.weight" label="lighter">细</el-radio>
        </template>
      </el-form-item>
      <el-form-item label="对齐方式">
        <template>
          <el-radio v-model="curSelData.align" label="left">左对齐</el-radio>
          <el-radio v-model="curSelData.align" label="center">居中</el-radio>
          <el-radio v-model="curSelData.align" label="right">右对齐</el-radio>
        </template>
      </el-form-item>
      <el-form-item label="文本内容">
        <el-input
          v-model="curSelData.content"
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
        />
      </el-form-item>
      <el-form-item label="背景图片">
        <el-col :span="18" v-for="(item,index) in curSelData.picList" :key="item.url">
          <el-input v-model="item.url" placeholder="请输入图片地址">
            <template slot="append"><span style="cursor: pointer" @click="selImg(index)">选择图片</span></template>
          </el-input>
          <el-input v-model="curSelData.link" placeholder="请输入链接" style="margin-top: 10px">
            <template slot="append">选择链接</template>
          </el-input>
        </el-col>
        <el-col :span="18">
          <img :src="item.url" alt="" class="imgClass" v-for="(item,index) in curSelData.picList" :key="item.url+index">
        </el-col>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import BsSlider from '../module/BsSlider'
import BsColorPicker from '../module/BsColorPicker'
export default {
  components: {
    BsSlider,
    BsColorPicker
  },
  props: {
    curSelData: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => {}
    }
  },
  data() {
    return {

    }
  },
  methods: {
    selImg(index) {
      this.$emit('selImg', index)
    }
  }
}
</script>
<style scoped>
  .imgClass{
    width: 200px;
    height: auto;
    margin: 15px 0;
  }
</style>
