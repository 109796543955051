<template>
  <div class="rBox">
    <el-form label-width="90px">
      <el-form-item label="左右边距">
        <template>
          <bs-slider v-model="curSelData.paddLeft" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="上下边距">
        <template>
          <bs-slider v-model="curSelData.paddTop" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="背景颜色">
        <template>
          <bs-color-picker v-model="curSelData.bgColor" style="float:left" />
          <el-button type="primary" class="btnClear" @click="clearColor('bgColor')">清除</el-button>
        </template>
      </el-form-item>
      <el-form-item label="标题">
          <el-input v-model="curSelData.title" placeholder="请输入标题"></el-input>
      </el-form-item>
      <!-- <el-form-item label="标题颜色">
        <bs-color-picker v-model="curSelData.titColor" />
      </el-form-item>
      <el-form-item label="内容颜色">
        <bs-color-picker v-model="curSelData.conColor" />
      </el-form-item>
      
      <el-form-item label="报告标题">
        <template>
          <el-input v-model="curSelData.reportTitle" placeholder="请输入报告标题" style="margin-top: 10px"></el-input>
        </template>
      </el-form-item> -->
      <!-- <el-form-item label="报告上传">
        <el-upload
            :action="uploadImgUrl"
            :headers="headers"
            name="file"
            :show-file-list="false"
            :on-success="init"
            :before-upload="beforeVideoUpload"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">请上传PDF文件，且不超过5MB</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="pdf地址">
        <template>
          <el-input readonly v-model="curSelData.src" placeholder="请上传PDF" style="margin-top: 10px">
          </el-input>
        </template>
      </el-form-item>
      <el-form-item label="检验中心">
        <template>
          <el-input v-model="curSelData.reportUnit" placeholder="请输入检验中心" style="margin-top: 10px"></el-input>
        </template>
      </el-form-item>
      <el-form-item label="报告日期">
        <template>
          <el-date-picker v-model="curSelData.reportTime" type="date" format="yyyy年MM月dd日" placeholder="请选择日期"></el-date-picker>
        </template>
      </el-form-item> -->
      <el-form-item label="背景图片">
          <el-input v-model="curSelData.bgUrl" placeholder="请输入图片地址">
            <template slot="append"><span style="cursor: pointer" @click="selImg('proInfo1','bgUrl')">选择图片</span></template>
          </el-input>
        <el-col :span="18">
          <img :src="curSelData.bgUrl" alt="" class="imgClass">
        </el-col>
      </el-form-item>

      <!--描述项-->
      <el-card v-for="(item,index) in curSelData.describeList" :key="item.title" class="box-card" style="margin-top: 10px">
        <div slot="header" class="clearfix">
          <el-button style="float: right; padding: 3px 0" type="text" @click="delItem(index)">删除</el-button>
        </div>
        <el-row>
          <el-col :span="24">
            <el-input v-model="item.title" placeholder="请输入描述项标题" style="margin-top: 10px">
              <template slot="append">描述项标题</template>
            </el-input>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-input v-model="item.content" placeholder="请输入描述项内容" style="margin-top: 10px">
              <template slot="append">描述项内容</template>
            </el-input>
          </el-col>
        </el-row>
      </el-card>
      <el-button style="margin:20px auto;width: 80%;display: block" @click="addItem">添加</el-button>
    </el-form>
  </div>
</template>
<style lang="scss" scoped>
.myRow {
  line-height: 30px;
  font-size: 12px;
  margin: 10px 0;
}
.formsItem{
  > label {
    position: relative;
    top:10px;
  }
}
.imgClass{
  width: 100%;
}
::v-deep .el-upload--text{
  width: auto!important;
  height: auto!important;
}
</style>
<script>
import BsSlider from '../module/BsSlider'
import BsColorPicker from '../module/BsColorPicker'
export default {
  components: {
    BsSlider,
    BsColorPicker
  },
  props: {
    curSelData: {
      type: Object,
      required: true
    },
    singleImgae: {
      type: Boolean,
      default: false
    }
  },
  data() {
    let token = localStorage.getItem("token")
    return {
      uploadImgUrl: '/api/file/uploadObjectOSS', // 上传的视频服务器地址
      ossUrl: this.$store.state.ossUrl,
      headers: {
        token: token
      },
    }
  },
  methods: {
    init(res) {
      if(res.code === 200 ) {
        this.$message.success('上传成功!');
        this.curSelData.src = this.ossUrl +res.data
      }else {
        this.$message.error(res.message);
      }
      
    },
    beforeVideoUpload(file) {
      let fileType = file.name.substring(file.name.lastIndexOf('.'))
      console.log(fileType)
      if(fileType!== '.pdf') {
        this.$message.error('请上传pdf格式文件!')
        return false
      }else {
        const isLt20M = file.size / 1024 / 1024 < 5;
        if (!isLt20M) {
          this.$message.error('上传PDF不能超过5MB!');
        }
        return isLt20M;       
      }
    },
    clearColor(attr) {
      this.curSelData[attr] = 'transparent'
    },
    addItem() {
      let str= '描述项标题' + (this.curSelData.describeList.length ? this.curSelData.describeList.length +1 : '1')
      this.curSelData.describeList.push(
        {
          title: str,
          content: '描述项内容',
        }
      )
    },
    delItem(index) {
      this.curSelData.describeList.splice(index, 1)
      // this.curSelData.describeList.map((item, index) => {
      //   item.title = '描述项标题'+( index + 1)
      //   item.content = '描述项内容'
      // })
    },
    selImg(type, attr) {
      this.$emit('selImg', type, attr)
    },
  }
}
</script>

