<template>
  <div>
    <el-form label-width="90px">
        <el-form-item label="标题1">
            <el-input v-model="curSelData.title1" placeholder="请输入标题">
            </el-input>
        </el-form-item>
        <el-form-item label="标题1">
            <el-input v-model="curSelData.title1sub" placeholder="请输入标题">
            </el-input>
        </el-form-item>
        <el-form-item label="背景图1">
          <el-input v-model="curSelData.bgImg1" placeholder="请输入图片地址">
            <template slot="append"><span style="cursor: pointer" @click="selImg('addKNF', 'bgImg1')">选择图片</span></template>
          </el-input>
        </el-form-item>
         <el-card>
          <el-row v-for="(item,index) in curSelData.imgsList1" :key="item.url">
            <el-col :span="6">
              <el-image :src="item.url" style="display: block;height: 74px;width: 148px;max-width: 90%">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline" />
                </div>
              </el-image>
            </el-col>
            <el-col :span="18">
              <el-input v-model="item.url" placeholder="请输入图片地址">
                <template slot="append"><span style="cursor: pointer" @click="selImg('addKNF', 'imgsList1',index)">选择图片</span></template>
              </el-input>
            </el-col>
          </el-row>
         </el-card>

        <el-form-item label="标题2">
            <el-input v-model="curSelData.title2" placeholder="请输入标题">
            </el-input>
        </el-form-item>
        <el-form-item label="标题2">
            <el-input v-model="curSelData.title2sub" placeholder="请输入标题">
            </el-input>
        </el-form-item>
        <el-form-item label="背景图2">
          <el-input v-model="curSelData.bgImg1" placeholder="请输入图片地址">
            <template slot="append"><span style="cursor: pointer" @click="selImg('addKNF', 'bgImg2')">选择图片</span></template>
          </el-input>
        </el-form-item>

        <el-card>
          <el-row v-for="(item,index) in curSelData.imgsList2" :key="item.url">
            <el-col :span="6">
              <el-image :src="item.url" style="display: block;height: 74px;width: 148px;max-width: 90%">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline" />
                </div>
              </el-image>
            </el-col>
            <el-col :span="18">
              <el-input v-model="item.url" placeholder="请输入图片地址">
                <template slot="append"><span style="cursor: pointer" @click="selImg('addKNF', 'imgsList2',index)">选择图片</span></template>
              </el-input>
            </el-col>
          </el-row>
         </el-card>

         <el-form-item label="内容2">
          <!-- <el-input type="textarea" v-model="curSelData.content" placeholder="请输入内容2">
          </el-input> -->
          <el-card v-for="(item,index) in curSelData.contentList" :key="item.content">
             <div slot="header" class="clearfix">
              <span class="delBtn" @click="delItem(index, 'contentList')">删除</span>
            </div>
            <el-row >
              <el-input v-model="item.content" placeholder="请输入内容" />
            </el-row>
           </el-card>
           <el-button @click="addItem('contentList')" class="addBtn">添加一项</el-button>
        </el-form-item>

       <el-form-item label="标题3"> 
            <el-input v-model="curSelData.title3" placeholder="请输入标题">
            </el-input>
        </el-form-item>
       <el-form-item label="标题3">
            <el-input v-model="curSelData.title3sub" placeholder="请输入标题">
            </el-input>
        </el-form-item>
        <el-form-item label="背景图3">
          <el-input v-model="curSelData.bgImg3" placeholder="请输入图片地址">
            <template slot="append"><span style="cursor: pointer" @click="selImg('addKNF', 'bgImg3')">选择图片</span></template>
          </el-input>
        </el-form-item>

        <el-card v-for="(item,index) in curSelData.imgsList3" :key="item.url">
          <div slot="header" class="clearfix">
            <span class="delBtn" @click="delItem(index, 'imgsList3')">删除</span>
          </div>
          <el-row>
            <el-col :span="6">
              <el-image :src="item.url" style="display: block;height: 74px;width: 148px;max-width: 90%">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline" />
                </div>
              </el-image>
            </el-col>
            <el-col :span="18">
              <el-input v-model="item.url" placeholder="请输入图片地址">
                <template slot="append"><span style="cursor: pointer" @click="selImg('addKNF', 'imgsList3',index)">选择图片</span></template>
              </el-input>
            </el-col>
          </el-row>
         </el-card>
         <el-button @click="addItem('imgsList3')" class="addBtn">添加一项</el-button>

        <el-form-item label="内容3图片">
          <!-- <el-input v-model="curSelData.contentImg" placeholder="请输入图片地址">
            <template slot="append"><span style="cursor: pointer" @click="selImg('addKNF', 'contentImg')">选择图片</span></template>
          </el-input> -->
          <el-row>
            <el-col :span="6">
              <el-image :src="curSelData.contentImg" style="display: block;height: 74px;width: 148px;max-width: 90%">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline" />
                </div>
              </el-image>
            </el-col>
            <el-col :span="18">
              <el-input v-model="curSelData.contentImg" placeholder="请输入图片地址">
                <template slot="append"><span style="cursor: pointer" @click="selImg('addKNF', 'contentImg')">选择图片</span></template>
              </el-input>
            </el-col>
          </el-row>
        </el-form-item>

         <el-form-item label="标题4">
            <el-input v-model="curSelData.title4" placeholder="请输入标题">
            </el-input>
        </el-form-item>
        <el-form-item label="标题4">
            <el-input v-model="curSelData.title4sub" placeholder="请输入标题">
            </el-input>
        </el-form-item>

        <el-form-item label="背景图4">
          <el-input v-model="curSelData.bgImg4" placeholder="请输入图片地址">
            <template slot="append"><span style="cursor: pointer" @click="selImg('addKNF', 'bgImg4')">选择图片</span></template>
          </el-input>
        </el-form-item>

        <el-card>
          <el-row v-for="(item,index) in curSelData.imgsList4" :key="item.url">
            <el-col :span="6">
              <el-image :src="item.url" style="display: block;height: 74px;width: 148px;max-width: 90%">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline" />
                </div>
              </el-image>
            </el-col>
            <el-col :span="18">
              <el-input v-model="item.url" placeholder="请输入图片地址">
                <template slot="append"><span style="cursor: pointer" @click="selImg('addKNF', 'imgsList4',index)">选择图片</span></template>
              </el-input>
            </el-col>
          </el-row>
         </el-card>
    </el-form>

  </div>
</template>
<script>
export default {
  props: {
    curSelData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      OnePageDefautData: {
        name: '',
        picList:[],
        isSwipe: false,
        swiperList: [

        ]
      },
      OneImgDefaultData: {
        img: ''
      }
    }
  },
  methods: {
    selImg(type, attr, index, pIdx) {
      this.$emit('selImg', type, attr, index, pIdx)
    },
    delItem(index, key) {
      this.curSelData[key].splice(index, 1)
    },
    addItem(key) {
      if(key === 'contentList') {
       this.curSelData[key].push({
         content: ''
       })
      }else if(key === 'imgsList3') {
       this.curSelData[key].push({
         url: ''
       })
      }
    }
    // addPage() {
    //   this.curSelData.tabList.push(this.OnePageDefautData)
    // },
    // delPage(index) {
    //    this.curSelData.tabList.splice(index, 1)
    // },
    // addImg(index) {
    //   this.curSelData.tabList[index].picList.push(this.OneImgDefaultData)
    // },
    // delImg(index, pIndex) {
    //   this.curSelData.tabList[index].picList.splice(pIndex, 1)
    // },
    // delBotImg(index, pIndex) {
    //   this.curSelData.tabList[index].swiperList.splice(pIndex, 1)
    // },
    // addBotImg(index) {
    //   this.curSelData.tabList[index].swiperList.push(this.OneImgDefaultData)
    // }
  }
}
</script>
<style scoped>
.pageCard{
  margin-top: 20px;
}
.mb10{
  margin-bottom: 10px;
}
.delBtn{
  color: #f00;
  text-align: right;
  cursor: pointer;
  float: right;
}
.ml10{
  margin-left: 10px;
}
.addBtn{
  width: 80%;
  margin: 10px auto;
  display: block
}

</style>