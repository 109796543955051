<template>
  <div class="rBox">
    <el-form label-width="90px">
      <el-form-item label="按钮名称">
          <el-input v-model="curSelData.name" placeholder="请输入按钮名称">
          </el-input>
      </el-form-item>
      <el-form-item label="公众号链接">
            <el-input v-model="curSelData.link" placeholder="请输入公众号链接地址"/>
       </el-form-item>
    </el-form>
  </div>
</template>
<script>
// import BsSlider from '../module/BsSlider'
// import BsColorPicker from '../module/BsColorPicker'
export default {
  components: {
    // BsSlider,
    // BsColorPicker
  },
  props: {
    curSelData: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => {}
    }
  },
  data() {
    return {

    }
  },
  methods: {
    // clearColor(attr) {
    //   this.curSelData[attr] = 'transparent'
    // },
    // selImg(type, attr) {
    //   this.$emit('selImg', type, attr)
    // },
    // addItem() {
    //   const width = (100 / (this.curSelData.picList.length + 1)).toFixed(2)
    //   this.curSelData.picList.forEach(item => {
    //     item.width = width + '%'
    //   })
    //   this.curSelData.picList.push({ width: width + '%', url: '' })
    // },
    // delPic(index) {
    //   const width = (100 / (this.curSelData.picList.length - 1)).toFixed(2)
    //   this.curSelData.picList.forEach(item => {
    //     item.width = width + '%'
    //   })
    //   this.curSelData.picList.splice(index, 1)
    // },
  }
}
</script>
<style scoped>
  .imgClass{
    width: 200px;
    height: auto;
    margin: 15px 0;
  }
</style>
