<template>
  <div>
    <el-row>
    <el-form>
      <el-form-item label="上边距">
        <template>
          <bs-slider v-model="curSelData.paddTop" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="下边距">
        <template>
          <bs-slider v-model="curSelData.paddBottom" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="左边距">
        <template>
          <bs-slider v-model="curSelData.paddLeft" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="右边距">
        <template>
          <bs-slider v-model="curSelData.paddRight" :min="0" :max="100" />
        </template>
      </el-form-item>
    </el-form>
    </el-row>
    视频编辑
    <el-card class="box-card" style="margin-top: 10px">
      <!-- <div slot="header" class="clearfix">
        <el-button style="float: right; padding: 3px 0" type="text" @click="delPic(index)">删除</el-button>
      </div> -->
      <!-- <el-row>
        <el-form label-width="90px">
          <el-form-item label="视频宽度">
          <el-col :span="24">
            <el-input v-model="curSelData.videoWidth" placeholder="例如: 100%, 100px">
            </el-input>
          </el-col>
          </el-form-item>
          <el-form-item label="视频高度">
          <el-col :span="24">
            <el-input v-model="curSelData.videoHeight" placeholder="例如: 100px">
            </el-input>
          </el-col>
          </el-form-item>
        </el-form>
      </el-row> -->
     
      <el-row>
        <el-col :span="24">
          <el-input v-model="curSelData.src" placeholder="请输入视频地址">
            <template slot="append"><span style="cursor: pointer" @click="selVideo">选择视频</span></template>
          </el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
              <el-input v-model="curSelData.poster" placeholder="请输入图片地址">
                <template slot="append"><span style="cursor: pointer" @click="selImg('oneVideo','poster')">选择图片</span></template>
              </el-input>
              <img :src="curSelData.poster" class="posterImg" />
        </el-col>
      </el-row>
      <el-row>
       <el-col :span="24" style="margin-top: 20px;">
         <video width="100%" :src="curSelData.src" height=""></video>
        </el-col>
      </el-row>
      <el-form>
      <el-form-item label="底边框">
            <template>
                <el-radio v-model="curSelData.bottomshow" :label="true">显示</el-radio>
                <el-radio v-model="curSelData.bottomshow" :label="false">不显示</el-radio>
            </template>
        </el-form-item>
      </el-form>

    </el-card>

  </div>
</template>
<script>
import BsSlider from '../module/BsSlider'
// import BsColorPicker from '../module/BsColorPicker'
export default {
  components: {
    BsSlider,
    // BsColorPicker
  },
  props: {
    curSelData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
    }
  },
  methods: {
    selVideo() {
      this.$emit('selVideo')
    },
    selImg(type, attr, index) {
      this.$emit('selImg', type, attr, index)
    },
  }
}
</script>
<style scoped>
.posterImg{
  width: 80%;
  margin: 0 20%;
}
</style>
