<template>
  <div class="rBox">
    <el-form label-width="120px">
      <el-form-item label="上下边距">
        <template>
          <bs-slider v-model="curSelData.paddTop" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="左右边距">
        <template>
          <bs-slider v-model="curSelData.paddLeft" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="背景颜色" class="formsItem">
        <template>
          <bs-color-picker v-model="curSelData.bgColor" style="float:left" />
          <el-button type="primary" class="btnClear" @click="clearColor('bgColor')">清除</el-button>
        </template>
      </el-form-item>
      <el-form-item label="主标题颜色">
        <template>
          <bs-color-picker v-model="curSelData.titleColor" style="float:left" />
          <el-button type="primary" class="btnClear" @click="clearColor('titleColor')">清除</el-button>
        </template>
      </el-form-item>
      <el-form-item label="主标题大小">
        <template>
          <bs-slider v-model="curSelData.titleSize" :min="9" :max="30" />
        </template>
      </el-form-item>
      <el-form-item label="副标题颜色">
        <template>
          <bs-color-picker v-model="curSelData.subTitleColor" style="float:left" />
          <el-button type="primary" class="btnClear" @click="clearColor('subTitleColor')">清除</el-button>
          
        </template>
      </el-form-item>
      <el-form-item label="副标题大小">
        <template>
          <bs-slider v-model="curSelData.subTitleSize" :min="9" :max="30" />
        </template>
      </el-form-item>
      <!-- <el-form-item label="字体粗细">
        <template>
          <el-radio v-model="curSelData.weight" label="normal">正常</el-radio>
          <el-radio v-model="curSelData.weight" label="bold">加粗</el-radio>
          <el-radio v-model="curSelData.weight" label="lighter">细</el-radio>
        </template>
      </el-form-item> -->
      <el-form-item label="标题位置">
        <template>
          <el-radio v-model="curSelData.titleAlign" label="left">左对齐</el-radio>
          <el-radio v-model="curSelData.titleAlign" label="center">居中</el-radio>
          <el-radio v-model="curSelData.titleAlign" label="right">右对齐</el-radio>
        </template>
      </el-form-item>
      <el-form-item label="二维码背景">
          <el-input v-model="curSelData.scanBg" placeholder="请输入图片地址">
            <template slot="append"><span style="cursor: pointer" @click="selImg('scanTwo', 'scanBg',index)">选择图片</span></template>
          </el-input>
      </el-form-item>
	  <el-form-item label="二维码上下边距">
	    <template>
	      <bs-slider v-model="curSelData.scanTop" :min="0" :max="100" />
	    </template>
	  </el-form-item>
	  <el-form-item label="二维码左右边距">
	    <template>
	      <bs-slider v-model="curSelData.scanLeft" :min="0" :max="100" />
	    </template>
	  </el-form-item>
	  <el-form-item label="二维码背景高度">
	    <template>
	      <bs-slider v-model="curSelData.scanBgHeight" :min="100" :max="200" />
	    </template>
	  </el-form-item>
	  <el-form-item label="二维码背景宽度">
	    <template>
	      <bs-slider v-model="curSelData.scanBgWidth" :min="120" :max="150" />
	    </template>
	  </el-form-item>
      <el-form-item label="背景图片">
          <el-input v-model="curSelData.boxBg" placeholder="请输入图片地址">
            <template slot="append"><span style="cursor: pointer" @click="selImg('scanTwo', 'boxBg',index)">选择图片</span></template>
          </el-input>
        <el-col :span="18">
          <img :src="curSelData.boxBg"  :key="curSelData.boxBg" class="imgClass" alt="">
        </el-col>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import BsSlider from '../module/BsSlider'
import BsColorPicker from '../module/BsColorPicker'
export default {
  components: {
    BsSlider,
    BsColorPicker
  },
  props: {
    curSelData: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => {}
    }
  },
  data() {
    return {

    }
  },
  methods: {
    clearColor(attr) {
      this.curSelData[attr] = 'transparent'
    },
    selImg(type, attr, index) {
      this.$emit('selImg', type, attr,index)
    }
  }
}
</script>
<style scoped>
  .imgClass{
    width: 200px;
    height: auto;
    margin: 15px 0;
  }
</style>
