<template>
  <div class="rBox">
    <el-form label-width="90px">
      <el-form-item label="标题">
        <el-input v-model="curSelData.title" type="text" placeholder="请输入标题">
          <el-button slot="append" @click="clearTit">清除</el-button>
        </el-input>
      </el-form-item>
      <!-- <el-form-item label="小标题">
          <el-input
            v-model="curSelData.subTitle"
            type="text"
            placeholder="请输入小标题">
            <el-button slot="append" @click="clearSubTit">清除</el-button>
          </el-input>
        </el-form-item> -->

      <el-form-item label="标题图标">
        <el-col :span="18">
          <el-input v-model="curSelData.icon" placeholder="请输入标题图标地址">
            <template slot="append"><span style="cursor: pointer"
                @click="selImg('productZone', 'icon')">选择图片</span></template>
          </el-input>
        </el-col>
        <el-col :span="18">
          <img :src="curSelData.icon" alt="" class="imgClass">
        </el-col>
      </el-form-item>

      <el-form-item label="底边框">
        <template>
          <el-radio v-model="curSelData.bottomshow" :label="true">显示</el-radio>
          <el-radio v-model="curSelData.bottomshow" :label="false">不显示</el-radio>
        </template>
      </el-form-item>

      <el-card v-for="(item, index) in curSelData.productList" :key="item.name" class="box-card" style="margin-top: 10px">
        <div slot="header" class="clearfix">
          <el-button style="float: right; padding: 3px 0" type="text" @click="delItem(index)">删除</el-button>
        </div>
        <el-row>
          <el-col :span="6" style="position:relative;">
            <el-image :src="item.goodPhoto" style="display: block;height: 74px; width: 80%;">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline" />
              </div>
            </el-image>
            <div class="selProductButton" @click="selProduct(index, item)">选择商品</div>
          </el-col>
          <el-col :span="18">
            <el-input v-model="item.goodsName" placeholder="这里是商品标题">
              <template slot="append"><span style="cursor: pointer">名称</span></template>
            </el-input>
            <el-input v-model="item.spname" placeholder="商品介绍" style="margin-top: 10px">
              <template slot="append">商品介绍</template>
            </el-input>
            <el-input v-model="item.type" placeholder="商品属性" style="margin-top: 10px">
              <template slot="append">商品属性</template>
            </el-input>
          </el-col>
        </el-row>
      </el-card>
      <el-button style="margin:20px auto;width: 80%;display: block" @click="addItem">添加</el-button>
    </el-form>
  </div>
</template>
<script>
// import BsColorPicker from '../module/BsColorPicker'
// import BsSlider from '../module/BsSlider'
export default {
  components: {
    // BsSlider,
    // BsColorPicker
  },
  props: {
    curSelData: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      inputValue: ''
    }
  },
  methods: {
    addItem() {
      let str = '商品名称' + (this.curSelData.productList.length ? this.curSelData.productList.length + 1 : '1')
      this.curSelData.productList.push(
        {
          goodsName: str,
          spname: '---系统',
          type: '爆品',
          goodPhoto: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/goods-2.jpeg',
        },
      )
    },
    delItem(index) {
      this.curSelData.productList.splice(index, 1)
      this.curSelData.productList.map((item, index) => {
        item.goodsName = '商品名称' + (index + 1)
      })
    },
    selProduct(index,item) {
      this.$emit('selProduct', index,item)
    },
    clearColor(attr) {
      this.curSelData[attr] = 'transparent'
    },
    selImg(type, attr) {
      this.$emit('selImg', type, attr)
    },
    clearTit() {
      this.curSelData.title = ''
    },
    clearSubTit() {
      this.curSelData.subTitle = ''
    }

  }
}
</script>
<style scoped>
.imgClass {
  max-width: 100%;
  margin-top: 20px;
}

.selProductButton {
  position: absolute;
  height: 20px;
  left: 0;
  right: 20%;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  position: absolute;
  cursor: pointer;
}
</style>
