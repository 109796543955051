<template>
    <div :style="{ 'borderBottom': item.bottomshow ? `${item.borderHeight ? item.borderHeight : 5}px solid #F0F2F7` : '', marginTop: item.marginTop+'px', marginBottom: item.marginBottom+'px' }">
        <div>
            <!-- <div class="subTitle">{{item.subTitle}}</div> -->
            <div :style="{ display: item.titleshow ? 'block' : 'none' }">
                <div class="top">
                    <img class="icon" :src="item.icon" alt="">
                    <div class="title">{{ item.title }}</div>
                </div>
            </div>

            <div class="content">
                <div class="list" v-for="(i, index) in item.productList" :key="index"
                    :style="{ flexDirection: i.imgAlign }">
                    <div v-if="i.imgAlign == 'row'" class="left">
                        <div class="introduce">{{ i.introduce }}</div>
                        <div class="bcont">
                            <div>
                                <img class="huo" :src="i.icon" alt="" srcset="">
                                <div class="heat">热度{{i.hotNum}}</div>
                            </div>
                            <div>
                                <div class="date">{{ i.date }}</div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="left1">
                        <div class="introduce">{{ i.introduce }}</div>
                        <div class="bcont">
                            <div>
                                <img class="huo" :src="i.icon" alt="" srcset="">
                                <div class="heat">热度{{i.hotNum}}</div>
                            </div>
                            <div>
                                <div class="date">{{ i.date }}</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <img class="photo" :src="i.photo">
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>
<script>

export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        styleObject() {
            return {
                // padding: this.item.paddTop + 'px' + ' ' + this.item.paddLeft + 'px',
                // textAlign: this.item.align,
                // fontSize: this.item.fontSize + 'px',
                // fontWeight: this.item.weight,
                // color: this.item.textColor
                backgroundColor: this.item.bgColor,
                backgroundImage: `url(${this.item.bgUrl})`,
                backgroundImageSize: '100% 100%',
                backgroundRepeat: 'no-repeat'
            }
        }
    },
    methods: {
        toMiniProgram(data, url, appId) {
            let { cardId, goodsId } = data
            console.log('cardId', cardId)
            console.log('goodsId', goodsId)
            wx.navigateToMiniProgram({
                appId: appId || "wx19adaa0665cc0c6a", // 要跳转的小程序的appid // wx1c06c4e49bf6040e
                path: `pages/prodDetail/main?goodsId=${goodsId}&cardId=${cardId}&from=111`, // 跳转的目标页面
                // envVersion: 'develop', // 仅在当前小程序为开发版或体验版时此参数有效。如果当前小程序是正式版，则打开的小程序必定是正式版。
                extarData: {
                    // goodsId: 2,
                    // cardId: 17785,
                    // from: 111, // 111 代表小程序标记
                },
                success(res) { },
                fail(res) {
                    console.log(res);
                }
            });
        },
        goDetailPage(data) {
            let { cardId, goodsId } = data
            console.log(data)
            uni.navigateTo({
                url: `/pages/productDetail/index?goodsId=${goodsId}&cardId=${cardId}&from=1`
            });
        }
    }
}
</script>
<style scoped>
.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 20px;
    border-bottom: 1px solid #F5F6FA;
}

.spinfo {
    padding: 10px;
    background: #fff;
    border-radius: 0 0 10px 10px;
    border: 1px solid #F5F6FA;
}

.list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #F5F6FA;
}

.list>.left {
    margin-right: 10px;
}

.list>.left1 {
    margin-left: 20px;
}

.info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.bcont {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
}

.bcont>div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.date {
    margin-left: 20px;
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #B0B9BD;
}

.spname {
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #A2ADB2;
    text-align: left;
    padding: 10px 0 0;
}

.hot {
    width: 56px;
    height: 20px;
    border-radius: 10px;
    background: red;
    font-size: 9px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 20px;
}


.introduce {
    text-align: left;
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #26353D;
}

.heat {
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FF293A;
    margin-left: 10px;
}

.icon {
    width: 22px;
    height: 25px;
    margin-right: 10px;
}

.subTitle {
    font-size: 12px;
    padding: 10px 0 0 0;
    color: #333;
}

.title {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    padding: 10px 0;
}

.photo {
    width: 100px;
    height: 70px;
    border-radius: 10px;
}

.huo {
    width: 14px;
    height: 16px;
}
</style>