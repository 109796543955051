<template>
  <div>
    <!--一物一码板块-->
    <div class="wrap ywymWrap" :style="{'borderBottom': item.bottomshow ? '5px solid #F0F2F7' : '',marginTop: item.marginTop+'px', marginBottom: item.marginBottom+'px'}">
      <div class="wrapTitle">
        <img class="wrapIcon" :src="item.icon" mode="widthFix"/>
        <span>{{item.title}}</span>
      </div>
       <div class="swiperContainer dataList">
           
           <div class="dataItem">
              <img v-if="item.showAvatar" src="" mode="widthFix" class="avatar" />
              <div>
                <div class="itemTit"><template v-if="item.showTitle">产品(扫码) </template><span v-if="item.showAddr">上海市</span></div>
                <div class="itemInfo">
                  <template v-if="item.showHandle">
                    <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/zhuoxizhiye/handleI.png" mode="widthFix" class="infoIcon" />
                    <span class="t1">86.122.2/xh345652</span>
                  </template>
                  <template v-if="item.showTime">
                    <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/zhuoxizhiye/time.png" mode="widthFix" class="infoIcon" />
                    <span>2023-02-15  15:45:00</span>
                  </template>
                </div>
              </div>
            </div>

            <div class="dataItem">
              <img v-if="item.showAvatar" src="" mode="widthFix" class="avatar" />
              <div>
                <div class="itemTit"><template v-if="item.showTitle">产品(扫码) </template><span v-if="item.showAddr">上海市</span></div>
                <div class="itemInfo">
                  <template v-if="item.showHandle">
                    <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/zhuoxizhiye/handleI.png" mode="widthFix" class="infoIcon" />
                    <span class="t1">86.122.2/xh345652</span>
                  </template>
                  <template v-if="item.showTime">
                    <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/zhuoxizhiye/time.png" mode="widthFix" class="infoIcon" />
                    <span>2023-02-15  15:45:00</span>
                  </template>
                </div>
              </div>
            </div>

            <div class="dataItem">
              <img v-if="item.showAvatar" src="" mode="widthFix" class="avatar" />
              <div>
                <div class="itemTit"><template v-if="item.showTitle">产品(扫码) </template><span v-if="item.showAddr">上海市</span></div>
                <div class="itemInfo">
                  <template v-if="item.showHandle">
                    <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/zhuoxizhiye/handleI.png" mode="widthFix" class="infoIcon" />
                    <span class="t1">86.122.2/xh345652</span>
                  </template>
                  <template v-if="item.showTime">
                    <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/zhuoxizhiye/time.png" mode="widthFix" class="infoIcon" />
                    <span>2023-02-15  15:45:00</span>
                  </template>
                </div>
              </div>
            </div>


       </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {

    }
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
<style scoped lang="scss">
.swiperContainer{
  width: 100%;
  height: 250px;
}
.wrap{
  padding-left: 20px;
  background: #fff;
  padding-top: 23px;
  .wrapTitle {
    display: flex;
    align-items: center;
    font-size: 17px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #26353D;
    border-bottom: 1px solid #F5F6FA;
    padding-bottom: 18px;
    .wrapIcon{
      width: 23px;
      height: 23px;
      margin-right: 15px;
    }
  }
  .dataList{
    .dataItem {
    display: flex;
    align-items: center;
    padding-top: 15px;
    border-bottom: 1px solid #F5F6FA;
    padding-bottom: 17px;
    .avatar{
      width: 48px;
      height: 48px;
      height: 48px;
      border-radius: 24px;
      overflow: hidden;
      border: 1px solid #ccc;
      margin-right: 8px;
      display: block;
    }
    .itemTit{
      font-size: 15px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #3B4D56;
      padding-bottom: 10px;
      text-align: left;
      span{
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #0099D6;
        background: #F5FCFE;
        border: 1px solid #8AD8F0;
        padding: 2px 9px;
        margin-left: 13px;
        border-radius: 10px;
      }
    }
    .itemInfo {
      font-size: 12px;
      font-family: Arial;
      font-weight: 400;
      color: #576B76;
      display: flex;
      align-items: center;
      
      .infoIcon{
        width: 12px;
        height: 12px;
        margin-right: 8px;
      }
      .t1{
        padding-right: 25px;
        position: relative;
        &::after{
          content: "";
          display: block;
          width: 1px;
          height: 10px;
          background: #9CA7AC;
          position: absolute;
          right: 13px;
          top: 1px;
        }
      }
    } 
  }
  }
  
}


</style>