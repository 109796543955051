<template>
  <div class="rBox">
    <el-form label-width="90px">
      <el-form-item label="左右边距">
        <template>
          <bs-slider v-model="curSelData.paddLeft" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="上下边距">
        <template>
          <bs-slider v-model="curSelData.paddTop" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="背景颜色">
        <template>
          <bs-color-picker v-model="curSelData.bgColor" style="float:left" />
          <el-button type="primary" class="btnClear" @click="clearColor('bgColor')">清除</el-button>

        </template>
      </el-form-item>
      <el-form-item label="分割线显示">
        <el-radio v-model="curSelData.isShowLine" :label="true">显示</el-radio>
        <el-radio v-model="curSelData.isShowLine" :label="false">隐藏</el-radio>
      </el-form-item>
      <template v-if="curSelData.isShowLine == true">
      <el-form-item label="分割线颜色">
          <bs-color-picker v-model="curSelData.lineColor" />
      </el-form-item>
      <el-form-item label="分割线宽度">
          <bs-slider v-model="curSelData.lineWidth" :min="0" :max="100" />
      </el-form-item>
      </template>
    </el-form>
    <el-card v-for="(item,index) in curSelData.picList" :key="item.title" class="box-card" style="margin-top: 10px">
      <div slot="header" class="clearfix">
        <el-button style="float: right; padding: 3px 0" type="text" @click="delPic(index)">删除</el-button>
      </div>
      <el-form label-width="90px">
      <el-row>
        <el-col :span="6">
          <el-image :src="item.url" style="display: block;height: 74px;width: 148px;max-width: 90%">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline" />
            </div>
          </el-image>
        </el-col>
        <el-col :span="18">
          <el-input v-model="item.url" placeholder="请输入图片地址">
            <template slot="append"><span style="cursor: pointer" @click="selImg('imgText2', 'picList', index)">选择图片</span></template>
          </el-input>
          <el-input v-model="item.link" placeholder="请输入链接" style="margin-top: 10px">
            <template slot="append">选择链接</template>
          </el-input>
        </el-col>
        <el-input v-if="!singleImgae" v-model="item.width" placeholder="请输入图片宽度" style="margin-top: 10px">
          <template slot="append">图片宽度</template>
        </el-input>
      </el-row>
     <!--标题-->
      <el-row>
        <el-col :span="24">
          <el-input v-model="item.title" placeholder="请输入标题" style="margin-top: 10px">
            <template slot="append">标题</template>
          </el-input>
        </el-col>
      </el-row>
      <el-row class="myRow">
        <el-form-item label="标题颜色">
          <bs-color-picker v-model="item.titleColor" />
        </el-form-item>
      </el-row>
      <el-row class="myRow">
        <el-form-item label="标题尺寸">
          <bs-slider v-model="item.titleSize" :min="0" :max="100" />
        </el-form-item>
      </el-row>
      <!--小标题-->
      <el-row>
        <el-col :span="24">
          <el-input v-model="item.subTitle" placeholder="请输入小标题" style="margin-top: 10px">
            <template slot="append">小标题</template>
          </el-input>
        </el-col>
      </el-row>
      <el-row class="myRow">
        <el-form-item label="小标题颜色">
          <bs-color-picker v-model="item.subTitleColor" />
        </el-form-item>
      </el-row>
      <el-row class="myRow">
        <el-form-item label="小标题尺寸">
          <bs-slider v-model="item.subTitleSize" :min="0" :max="100" />
        </el-form-item>
      </el-row>
     </el-form>
    </el-card>
    <el-button v-if="!singleImgae" style="margin:20px auto;width: 80%;display: block" @click="addItem">添加</el-button>
  </div>
</template>
<style lang="scss" scoped>
  .myRow {
    line-height: 30px;
    font-size: 12px;
    margin: 10px 0;
  }
  .formsItem{
    > label {
      position: relative;
      top:10px;
    }
  }
</style>
<script>
import BsSlider from '../module/BsSlider'
import BsColorPicker from '../module/BsColorPicker'
export default {
  components: {
    BsSlider,
    BsColorPicker
  },
  props: {
    curSelData: {
      type: Object,
      required: true
    },
    singleImgae: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    }
  },
  methods: {
    delPic(index) {
      this.curSelData.picList.splice(index, 1)
      // this.curSelData.picList.map((item, index) => {
      //   item.title = '这是标题' + (index + 1)
      // })
    },
    selImg(type, attr, index) {
      this.$emit('selImg', type, attr,index)
    },
    addItem() {
      let str='这是标题'+ (this.curSelData.picList.length? (this.curSelData.picList.length + 1) : '1' )
      this.curSelData.picList.push(
        {
            url: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/custom/ec6368a65159228c41f8c73fc09ca20b.jpeg',
            width: '100%', // 图片宽度
            link: '',
            title: str,
            titleColor: '#000',
            titleSize: 18,

            subTitle: '这是小标题',
            subTitleColor: '#1D211D',
            subTitleSize: 14,
          }
      )

    },
    clearColor(attr) {
      this.curSelData[attr] = 'transparent'
    },
  }
}
</script>
