<template>
	<div class="rBox">
    <div class="pageTitle">{{fySelItem.title}}页面</div>
   
    <el-form label-width="90px">
      <div v-if="selIndex === 0">
        <el-form-item label="logo">
          <el-col :span="18">
						<el-input v-model="curSelData.zsxx.logo" placeholder="请输入logo地址">
							<template slot="append"><span style="cursor: pointer"
									@click="selImg('FangYuan', 'logo')">选择图片</span></template>
						</el-input>
					</el-col>
					<el-col :span="18">
						<img height="100px" :src="curSelData.zsxx.logo" alt="" class="imgClass">
					</el-col>
        </el-form-item>
        <el-form-item label="标题">
				  <el-input v-model="curSelData.zsxx.title" type="text" placeholder="请输入标题"/>
        </el-form-item>
        <!-- <el-form-item label="标识标题">
				  <el-input v-model="curSelData.zsxx.handleTitle" type="text" placeholder="请输入标识标题"/>
        </el-form-item> -->
        <el-card>
          <div v-for="(item, index) in curSelData.zsxx.picList" :key="item.index" class="box-card"
          style="margin-top: 10px">
          <div slot="header" class="clearfix">
            <el-button style="float: right; padding: 3px 0" type="text" @click="delLunboItem(index)">删除</el-button>
          </div>
          <el-form-item label="证书图片">
            <el-col :span="18">
              <el-input v-model="item.url" placeholder="请输入内容图片地址">
                <template slot="append"><span style="cursor: pointer"
                    @click="selImg('FangYuan', 'picList', index)">选择图片</span></template>
              </el-input>
            </el-col>
            <el-col :span="18">
              <img height="100px" :src="item.url" alt="" class="imgClass">
            </el-col>
          </el-form-item>
          </div>
          <el-button style="margin:20px auto;width: 80%;display: block;" @click="addLunboItem(index)">添加证书</el-button>
        </el-card>
         <el-form-item label="标题" style="margin-top: 20px;">
				  <el-input v-model="curSelData.zsxx.infoTit" type="text" placeholder="请输入标题"/>
         </el-form-item>
        <el-form-item label="英文标题">
				  <el-input v-model="curSelData.zsxx.infoEng" type="text" placeholder="请输入英文标题"/>
        </el-form-item>

        <el-card>
          <div v-for="(item,index) in curSelData.zsxx.infoMain" :key="index">
            <div slot="header" class="clearfix">
              <el-button style="float: right; padding: 3px 0" type="text" @click="delInfoMainOne(index)">删除一行</el-button>
            </div>
            <el-form-item label="标题" style="margin-top: 20px;">
              <el-input v-model="item.title" type="text" placeholder="请输入标题"/>
            </el-form-item>
            <el-form-item label="类型" style="margin-top: 20px;">
              <el-radio-group v-model="item.type">
                <el-radio :label="1">内容</el-radio>
                <el-radio :label="2">内容+链接</el-radio>
                <el-radio :label="3">多数据</el-radio>
              </el-radio-group>
            </el-form-item>
            <div v-if="item.type === 1">
              <el-form-item label="内容">
                <el-input v-model="item.msg"/>
              </el-form-item>
              <el-form-item label="字体颜色">
                  <bs-color-picker v-model="item.msgColor" />
              </el-form-item>
            </div>
            <div v-if="item.type === 2">
              <el-form-item label="链接图片">
                <el-col :span="18">
                  <el-input v-model="item.linkImg" placeholder="请输入内容图片地址">
                    <template slot="append"><span style="cursor: pointer"
                        @click="selImg('FangYuan', 'linkImg', index)">选择图片</span></template>
                  </el-input>
                </el-col>
                <el-col :span="18">
                  <img height="50px" :src="item.linkImg" alt="" class="imgClass">
                </el-col>
              </el-form-item>
              <el-form-item label="内容">
                <el-input v-model="item.msg"/>
              </el-form-item>
              <el-form-item label="字体颜色">
                  <bs-color-picker v-model="item.msgColor" />
              </el-form-item>
            </div>
            <div v-if="item.type === 3">
              <div v-for="(sItem, sIndex) in item.siteList" :key="sIndex">
                <div @click="delSItem(index, sIndex)" style="text-align:right; padding: 3px 0; color: #409EFF;">删除一项</div>
                <el-form-item label="数据项" v-for="(ssItem , ssIndex) in sItem.siteInfo" :key="ssIndex">
                  <div class="" @click="delSSItem(index, sIndex,ssIndex)" style="text-align:right; color: #409EFF;">删除</div>
                  <el-form-item label="标题">
                    <el-input v-model="ssItem.name" placeholder="请输入数据项标题"/>
                  </el-form-item>
                  <el-form-item label="内容" style="margin-top: 20px;">
                    <el-input v-model="ssItem.value" placeholder="请输入数据项内容"/>
                  </el-form-item>
                </el-form-item>
			          <el-button style="margin:20px auto;width: 80%;display: block;" @click="addSSItem(index, sIndex, ssIndex)">添加数据</el-button>
              </div>
			        <el-button style="margin:20px auto;width: 80%;display: block;" @click="addSItem(index, sIndex)">添加一项</el-button>
            </div>
          </div>
			    <el-button style="margin:20px auto;width: 80%;display: block;" @click="addInfoMainOne(index)">添加一行</el-button>
        </el-card>
        <el-card>
          <div v-for="(item,index) in curSelData.zsxx.infoMain2" :key="index">
            <div slot="header" class="clearfix">
              <el-button style="float: right; padding: 3px 0" type="text" @click="delInfoMain2One(index)">删除</el-button>
            </div>
            <el-form-item label="标题1" style="margin-top: 20px;">
              <el-input v-model="item.box1.title" type="text" placeholder="请输入标题"/>
            </el-form-item>
             <el-form-item label="内容1" style="margin-top: 20px;">
              <el-input v-model="item.box1.msg" type="text" placeholder="请输入标题"/>
            </el-form-item>
            
            <!-- <el-form-item label="内容1颜色" style="margin-top: 20px;">
              <bs-color-picker v-model="item.box1.msgColor" />
            </el-form-item> -->
             <el-form-item label="标题2" style="margin-top: 20px;">
              <el-input v-model="item.box2.title" type="text" placeholder="请输入标题"/>
            </el-form-item>
            <el-form-item label="内容2" style="margin-top: 20px;">
              <template v-if="item.box2.status">
                <el-form-item>
                  <el-radio-group v-model="item.box2.status">
                    <el-radio :label="1">有效</el-radio>
                    <el-radio :label="2">暂停</el-radio>
                    <el-radio :label="3">撤销</el-radio>
                    <el-radio :label="4">过期</el-radio>
                  </el-radio-group>
                </el-form-item>
              </template>
              <template v-else>
              <el-input v-model="item.box2.msg" type="text" placeholder="请输入内容"/>
              </template>
            </el-form-item>
            <!-- <el-form-item label="内容2颜色" style="margin-top: 20px;">
              <bs-color-picker v-model="item.box2.msgColor" />
            </el-form-item> -->
          </div>
			    <el-button style="margin:20px auto;width: 80%;display: block;" @click="addInfoMain2One(index)">添加一行</el-button>

        </el-card>

      </div>
      <div v-if="selIndex === 1">
        <el-form-item label="logo">
          <el-col :span="18">
						<el-input v-model="curSelData.qyjj.logo" placeholder="请输入logo地址">
							<template slot="append"><span style="cursor: pointer"
									@click="selImg('FangYuan', 'logo')">选择图片</span></template>
						</el-input>
					</el-col>
					<el-col :span="18">
						<img height="100px" :src="curSelData.qyjj.logo" alt="" class="imgClass">
					</el-col>
        </el-form-item>
        <el-form-item label="标题">
				  <el-input v-model="curSelData.qyjj.title" type="text" placeholder="请输入标题"/>
        </el-form-item>
        <el-card>
         <div v-for="(item,index) in curSelData.qyjj.listData" :key="index">
           <div slot="header" class="clearfix">
              <el-button style="float: right; padding: 3px 0" type="text" @click="delJJ(index)">删除</el-button>
           </div>
          <el-form-item label="标题">
            <el-input v-model="item.name" type="text" placeholder="请输入标题"/>
          </el-form-item>
          <el-form-item label="英文标题">
            <el-input v-model="item.nameEng" type="text" placeholder="请输入标题"/>
          </el-form-item>
          <el-form-item label="类型">
            <el-radio-group v-model="item.type">
              <el-radio :label="1">文字</el-radio>
              <el-radio :label="2">图片</el-radio>
              <el-radio :label="3">图文</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="内容" v-if="item.type === 1">
            <el-input v-model="item.content" type="textarea" autosize placeholder="请输入内容"/>
          </el-form-item>
          <el-form-item label="图片" v-if="item.type === 2">
            <el-col :span="18">
              <el-input v-model="item.contentImg" placeholder="请输入图片地址">
                <template slot="append"><span style="cursor: pointer"
                    @click="selImg('FangYuan', 'contentImg', index)">选择图片</span></template>
              </el-input>
            </el-col>
            <el-col :span="18">
              <img height="100px" :src="item.contentImg" alt="" class="imgClass">
            </el-col>
          </el-form-item>
          <el-form-item label="图片" v-if="item.type === 3">
            <el-row>
              <el-col :span="18">
                <el-input v-model="item.contentImg" placeholder="请输入图片地址">
                  <template slot="append"><span style="cursor: pointer"
                      @click="selImg('FangYuan', 'contentImg', index)">选择图片</span></template>
                </el-input>
              </el-col>
              <el-col :span="18">
                <img height="100px" :src="item.contentImg" alt="" class="imgClass">
              </el-col>
            </el-row>
            <el-row>
              <el-form-item label="内容">
                <el-input v-model="item.content" type="textarea" autosize placeholder="请输入内容"/>
              </el-form-item>
            </el-row>
          </el-form-item>
         </div>
         <el-button style="margin:20px auto;width: 80%;display: block;" @click="addJJ">添加一项</el-button>
        </el-card>

      </div>

      <div v-if="selIndex === 2">
        <el-form-item label="logo">
          <el-col :span="18">
						<el-input v-model="curSelData.txjs.logo" placeholder="请输入logo地址">
							<template slot="append"><span style="cursor: pointer"
									@click="selImg('FangYuan', 'logo')">选择图片</span></template>
						</el-input>
					</el-col>
					<el-col :span="18">
						<img height="100px" :src="curSelData.txjs.logo" alt="" class="imgClass">
					</el-col>
        </el-form-item>
        <el-form-item label="标题">
				  <el-input v-model="curSelData.txjs.title" type="text" placeholder="请输入标题"/>
        </el-form-item>
        <el-form-item label="图片">
          <el-col :span="18">
            <el-input v-model="curSelData.txjs.qyImg" placeholder="请输入内容图片地址">
              <template slot="append"><span style="cursor: pointer"
                  @click="selImg('FangYuan', 'qyImg')">选择图片</span></template>
            </el-input>
          </el-col>
          <el-col :span="18">
            <img height="100px" :src="curSelData.txjs.qyImg" alt="" class="imgClass">
          </el-col>
        </el-form-item>
       <!-- <el-form-item label="标题" style="margin-top: 20px;">
				  <el-input v-model="curSelData.txjs.infoTit" type="text" placeholder="请输入标题"/>
         </el-form-item>
        <el-form-item label="英文标题">
				  <el-input v-model="curSelData.txjs.infoEng" type="text" placeholder="请输入英文标题"/>
        </el-form-item> -->
        <el-card>
         <div v-for="(item,index) in curSelData.txjs.listData" :key="index">
           <div slot="header" class="clearfix">
              <el-button style="float: right; padding: 3px 0" type="text" @click="delTx(index)">删除</el-button>
           </div>
          <el-form-item label="标题">
            <el-input v-model="item.name" type="text" placeholder="请输入标题"/>
          </el-form-item>
          <el-form-item label="英文标题">
            <el-input v-model="item.nameEng" type="text" placeholder="请输入标题"/>
          </el-form-item>
          <el-form-item label="数字图片">
            <el-col :span="18">
              <el-input v-model="item.numImg" placeholder="请输入图片地址">
                <template slot="append"><span style="cursor: pointer"
                    @click="selImg('FangYuan', 'numImg', index)">选择图片</span></template>
              </el-input>
            </el-col>
            <el-col :span="18">
              <img height="100px" :src="item.numImg" alt="" class="imgClass">
            </el-col>
          </el-form-item>
          <el-form-item label="类型">
            <el-radio-group v-model="item.type">
              <el-radio :label="1">文字</el-radio>
              <el-radio :label="2">图片</el-radio>
              <el-radio :label="3">图文</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="内容" v-if="item.type === 1">
            <el-input v-model="item.content" type="textarea" autosize placeholder="请输入内容"/>
          </el-form-item>
          <el-form-item label="图片" v-if="item.type === 2">
            <el-col :span="18">
              <el-input v-model="item.contentImg" placeholder="请输入图片地址">
                <template slot="append"><span style="cursor: pointer"
                    @click="selImg('FangYuan', 'contentImg', index)">选择图片</span></template>
              </el-input>
            </el-col>
            <el-col :span="18">
              <img height="100px" :src="item.contentImg" alt="" class="imgClass">
            </el-col>
          </el-form-item>
          <el-form-item label="图片" v-if="item.type === 3">
            <el-row>
              <el-col :span="18">
                <el-input v-model="item.contentImg" placeholder="请输入图片地址">
                  <template slot="append"><span style="cursor: pointer"
                      @click="selImg('FangYuan', 'contentImg', index)">选择图片</span></template>
                </el-input>
              </el-col>
              <el-col :span="18">
                <img height="100px" :src="item.contentImg" alt="" class="imgClass">
              </el-col>
            </el-row>
            <el-row>
              <el-form-item label="内容">
                <el-input v-model="item.content" type="textarea" autosize placeholder="请输入内容"/>
              </el-form-item>
            </el-row>
          </el-form-item>

         </div>
         <el-button style="margin:20px auto;width: 80%;display: block;" @click="addTx">添加一项</el-button>
        </el-card>
      </div>
      <div v-if="selIndex === 3">
        <el-form-item label="logo">
          <el-col :span="18">
						<el-input v-model="curSelData.gyfy.logo" placeholder="请输入logo地址">
							<template slot="append"><span style="cursor: pointer"
									@click="selImg('FangYuan', 'logo')">选择图片</span></template>
						</el-input>
					</el-col>
					<el-col :span="18">
						<img height="100px" :src="curSelData.gyfy.logo" alt="" class="imgClass">
					</el-col>
        </el-form-item>
        <el-form-item label="标题">
				  <el-input v-model="curSelData.gyfy.title" type="text" placeholder="请输入标题"/>
        </el-form-item>
        <el-form-item label="图片">
          <el-col :span="18">
            <el-input v-model="curSelData.gyfy.qyImg" placeholder="请输入内容图片地址">
              <template slot="append"><span style="cursor: pointer"
                  @click="selImg('FangYuan', 'qyImg')">选择图片</span></template>
            </el-input>
          </el-col>
          <el-col :span="18">
            <img height="100px" :src="curSelData.gyfy.qyImg" alt="" class="imgClass">
          </el-col>
        </el-form-item>
        <div v-for="(item,index) in curSelData.gyfy.listData" :key="index">
           <div slot="header" class="clearfix">
              <el-button style="float: right; padding: 3px 0" type="text" @click="delGy(index)">删除</el-button>
           </div>
          <el-form-item label="标题">
            <el-input v-model="item.name" type="text" placeholder="请输入标题"/>
          </el-form-item>
          <el-form-item label="英文标题">
            <el-input v-model="item.nameEng" type="text" placeholder="请输入标题"/>
          </el-form-item>
          <el-form-item label="类型">
            <el-radio-group v-model="item.type">
              <el-radio :label="1">文字</el-radio>
              <el-radio :label="2">图片</el-radio>
              <el-radio :label="3">图文</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="内容" v-if="item.type === 1">
            <el-input v-model="item.content" type="textarea" autosize placeholder="请输入内容"/>
          </el-form-item>
          <el-form-item label="图片" v-if="item.type === 2">
            <el-col :span="18">
              <el-input v-model="item.contentImg" placeholder="请输入图片地址">
                <template slot="append"><span style="cursor: pointer"
                    @click="selImg('FangYuan', 'contentImg', index)">选择图片</span></template>
              </el-input>
            </el-col>
            <el-col :span="18">
              <img height="100px" :src="item.contentImg" alt="" class="imgClass">
            </el-col>
          </el-form-item>
          <el-form-item label="图片" v-if="item.type === 3">
            <el-row>
              <el-col :span="18">
                <el-input v-model="item.contentImg" placeholder="请输入图片地址">
                  <template slot="append"><span style="cursor: pointer"
                      @click="selImg('FangYuan', 'contentImg', index)">选择图片</span></template>
                </el-input>
              </el-col>
              <el-col :span="18">
                <img height="100px" :src="item.contentImg" alt="" class="imgClass">
              </el-col>
            </el-row>
            <el-row>
              <el-form-item label="内容">
                <el-input v-model="item.content" type="textarea" autosize placeholder="请输入内容"/>
              </el-form-item>
            </el-row>
          </el-form-item>
         </div>
         <el-button style="margin:20px auto;width: 80%;display: block;" @click="addGy">添加一项</el-button>
      </div>
    </el-form>
    <div class="pageTitle pageTitle2">证书“背后的故事”页面</div>
    <el-form label-width="100px">
      <el-form-item label='展示照片'>
        <el-radio-group v-model="curSelData.showPhotos">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="2">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <div></div>
      <div v-if="curSelData.showPhotos === 1">
        <el-form-item label="logo">
          <el-col :span="18">
						<el-input v-model="curSelData.xchy.logo" placeholder="请输入logo地址">
							<template slot="append"><span style="cursor: pointer"
									@click="selImg('FangYuan', 'logo')">选择图片</span></template>
						</el-input>
					</el-col>
					<el-col :span="18">
						<img height="100px" :src="curSelData.xchy.logo" alt="" class="imgClass">
					</el-col>
        </el-form-item>
        <el-form-item label="标题">
				  <el-input v-model="curSelData.xchy.title" type="text" placeholder="请输入标题"/>
        </el-form-item>
        <el-card>
          <div slot="header" class="clearfix">
            图片
          </div>
          <div v-for="(item, index) in curSelData.xchy.photoList" :key="item.index" class="box-card"
          style="margin-top: 10px">
          <el-form-item label="照片">
            
            <el-form-item label="标题" style="margin-top: 20px;">
            <el-input v-model="curSelData.xchy.infoTit" type="text" placeholder="请输入标题"/>
          </el-form-item>
          <el-form-item label="英文标题">
            <el-input v-model="curSelData.xchy.infoEng" type="text" placeholder="请输入英文标题"/>
          </el-form-item>

            <el-col :span="18">
              <el-input v-model="item.url" placeholder="请输入内容图片地址">
                <template slot="append"><span style="cursor: pointer"
                    @click="selImg('FangYuan', 'photoList', index)">选择图片</span></template>
              </el-input>
            </el-col>
            <el-col :span="18">
              <img height="100px" :src="item.url" alt="" class="imgClass">
            </el-col>
          </el-form-item>
          </div>
        </el-card>

        <el-card class="box-card" style="margin-top: 10px">
          <div slot="header" class="clearfix">
            视频
          </div>
          <el-form-item label="标题" style="margin-top: 20px;">
            <el-input v-model="curSelData.xchy.videoTit" type="text" placeholder="请输入标题"/>
          </el-form-item>
          <el-form-item label="英文标题">
            <el-input v-model="curSelData.xchy.videoEng" type="text" placeholder="请输入英文标题"/>
          </el-form-item>
          <el-row>
            <el-col>
              <el-input v-model="curSelData.xchy.video" placeholder="请输入视频链接">
                <template slot="append"><span style="cursor: pointer" @click="selVideo">选择视频</span></template>
              </el-input>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10px;">
              <el-input v-model="curSelData.xchy.poster" placeholder="请输入图片地址">
                <template slot="append"><span style="cursor: pointer" @click="selImg('FangYuan','poster')">选择封面</span></template>
              </el-input>
              <el-col :span="18">
                <img height="100px" :src="curSelData.xchy.poster" alt="" class="imgClass">
              </el-col>
          </el-row>
        </el-card>
      <div>
      </div>
      </div>
    </el-form>
	</div>
</template>
<script>
  import BsColorPicker from '../module/BsColorPicker'
	export default {
		components: {
			BsColorPicker
		},
		props: {
			curSelData: {
				type: Object,
				default: () => {tabbar}
      },
      fySelItem: {

      }
    },
    computed: {
      selIndex() {
        let index = 0
        console.log(this.curSelData.tabbar.length)
        for(let i=0; i< this.curSelData.tabbar.length;i++) {
          if(this.curSelData.tabbar[i].title === this.fySelItem.title) {
            index = i
          }
        }
        return index
      }
    },
		data() {
			return {

      }
		},
		methods: {
      // 选择图片
      selImg(type, attr, index) {
				this.$emit('selImg', type, attr, index)
      },
      // 选择视频
      selVideo(index) {
        this.$emit('selVideo', 'video')
      },
      // 证书信息
      delSItem(index,sIndex) {
        this.curSelData.zsxx.infoMain[index].siteList.splice(sIndex, 1)
      },
      addSItem(index,sIndex) {
        this.curSelData.zsxx.infoMain[index].siteList.push({
          siteInfo: [
											{
												name:'',
												value: '',
                      }
                    ]
        })
      },
      delSSItem(index,sIndex, ssIndex) {
        this.curSelData.zsxx.infoMain[index].siteList[sIndex].siteInfo.splice(ssIndex, 1)
      },
      addSSItem(index,sIndex,) {
        this.curSelData.zsxx.infoMain[index].siteList[sIndex].siteInfo.push({
          name:'',
					value: '',
        })
      },
      addInfoMainOne() {
        this.curSelData.zsxx.infoMain.push({
          title: '·标题',
          msg: '内容',
          msgColor: '#1C3B9E',
          linkImg: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/fangyuan/rk1.png',
          linkHref: '',
          type: 1,
          siteList:[
            {
              siteInfo:[{
                	name:'名称',
									value: '内容',
              }]
            }
          ]
        })
      },
      delInfoMainOne(index) {
        this.curSelData.zsxx.infoMain.splice(index,1)
      },
      addLunboItem(index) {
        this.curSelData.zsxx.picList.push({
					url: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/fangyuan/zsTest.png',
        })
      },
      delLunboItem(index) {
        this.curSelData.zsxx.picList.splice(index, 1)
      },
      addInfoMain2One(index) {
        this.curSelData.zsxx.infoMain2.push({
          box1: {
									title: '标题1',
									msg: '内容1',
									msgColor: '#2E3033',
								},
								box2: {
									title: '标题2',
									msg: '内容2',
									msgColor: '#2E3033',
								}
        })
      },
      delInfoMain2One(index) {
        this.curSelData.zsxx.infoMain2.splice(index, 1)
      },
      addPhotoItem() {
        this.curSelData.xchy.photoList.push({
          url: ""
        })
      },
      delPhotoItem(index) {
        this.curSelData.xchy.photoList.splice(index, 1)
      },
      addTx() {
        this.curSelData.txjs.listData.push({
          name: '标题',
          nameEng: '英文标题',
          numImg: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/fangyuan/number01.png',
          type: 1,
          content: '内容'
        })
      },
      delTx(index) {
        this.curSelData.txjs.listData.splice(index,1)
      },
      addGy() {
        this.curSelData.gyfy.listData.push({
          name: '标题',
          nameEng: '英文标题',
          type: 1,
          content: '内容'
        })
      },
      delGy(index) {
        this.curSelData.gyfy.listData.splice(index,1)
      },
      addJJ() {
        this.curSelData.qyjj.listData.push({
          name: '标题',
          nameEng: '英文标题',
          type: 1,
          content: '内容'
        })
      },
      delJJ(index) {
        this.curSelData.qyjj.listData.splice(index,1)
        
      }
		}
	}
</script>

<style scoped>
.imgClass{
  margin-top: 10px;
}
.pageTitle{
  color: #20a0ff;
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
}

.pageTitle2{
  margin-top: 20px;
  border-top: 1px solid #20a0ff;
}

</style>
