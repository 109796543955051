<template>
	<div>
		<el-form>
		  <el-form-item label="按钮显示">
		    <el-radio v-model="curSelData.btn" :label="true">显示</el-radio>
		    <el-radio v-model="curSelData.btn" :label="false">隐藏</el-radio>
		  </el-form-item>
		  <el-form-item label="显示底边线" label-width="100px">
		    <el-radio v-model="curSelData.showBotBorder" :label="true">显示</el-radio>
		    <el-radio v-model="curSelData.showBotBorder" :label="false">隐藏</el-radio>
		  </el-form-item>
		  <el-form-item label="中文标题" label-width="100px">
		    <el-input v-model="curSelData.ChinaTitle" />
		  </el-form-item>
		  <el-form-item label="英文标题" label-width="100px">
		    <el-input v-model="curSelData.EngTitle" />
		  </el-form-item>
		</el-form>
		<el-form label-width="90px">
			<el-card v-for="(item,index) in curSelData.list" :key="item.label+index" class="box-card"
				style="margin-top: 10px">
				<div slot="header" class="clearfix">
					<el-button style="float: right; padding: 3px 0" type="text" @click="delItem(index)">删除</el-button>
				</div>
				<el-form-item label="标题">
					<el-input v-model="item.label" />
				</el-form-item>
				<el-form-item label="内容">
					<el-input v-model="item.content" />
				</el-form-item>
				<el-form-item label="内容1">
					<el-input v-model="item.text" />
				</el-form-item>
			</el-card>
			<el-button style="margin:20px auto;width: 80%;display: block" @click="addItem">添加参数</el-button>
		</el-form>
		<el-form>
		  <el-form-item label="表单显示">
		    <el-radio v-model="curSelData.nutrients.flag" :label="true">显示</el-radio>
		    <el-radio v-model="curSelData.nutrients.flag" :label="false">隐藏</el-radio>
		  </el-form-item>
		</el-form>
		<el-form label-width="90px">
			<el-card v-for="(item,index) in curSelData.nutrients.table" :key="item.label+index" class="box-card"
				style="margin-top: 10px">
				<div slot="header" class="clearfix">
					<el-button style="float: right; padding: 3px 0" type="text" @click="delItem1(index)">删除</el-button>
				</div>
				<el-form-item label="名称">
					<el-input v-model="item.name" />
				</el-form-item>
				<el-form-item label="重量">
					<el-input v-model="item.weight" />
				</el-form-item>
				<el-form-item label="比例">
					<el-input v-model="item.proportion" />
				</el-form-item>
			</el-card>
		<el-button style="margin:20px auto;width: 80%;display: block" @click="addNutrients">添加营养成分</el-button>
		</el-form>
	</div>
</template>

<script>
	export default {
		components: {
		},
		props: {
			curSelData: {
				type: Object,
				required: true
			}
		},
		data() {
			return {

			}
		},
		methods: {
			addNutrients() {
				this.curSelData.nutrients.table.push({
					name: '项目',
					weight: '每100克(g)',
					proportion: 'NRV%'
				})
			},
			addItem() {
				this.curSelData.list.push({
					label: '标题',
			  content: '内容'
				})
			},
			delItem(index) {
				this.curSelData.list.splice(index, 1)
			},
			delItem1(index) {
				this.curSelData.nutrients.table.splice(index, 1)
			}
		}
	}
</script>

<style>
</style>
