import { render, staticRenderFns } from "./SwiperVideo.vue?vue&type=template&id=024642e7&scoped=true&"
import script from "./SwiperVideo.vue?vue&type=script&lang=js&"
export * from "./SwiperVideo.vue?vue&type=script&lang=js&"
import style0 from "./SwiperVideo.vue?vue&type=style&index=0&id=024642e7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "024642e7",
  null
  
)

export default component.exports