<template>
  <img :src="item.icon" class="fixImg" />
</template>
<script>

export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
    },
    methods: {

    }
}
</script>
<style scoped>
  .fixImg{
    /* position: absolute;
    z-index: 999;
    right: 10px;
    bottom: 60px; */
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: block;
    /* position: absolute; */
  }
</style>