<template>
  <div class="rBox">
    <el-form label-width="90px">
      <el-form-item label="左右边距">
        <template>
          <bs-slider v-model="curSelData.paddLeft" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="上下边距">
        <template>
          <bs-slider v-model="curSelData.paddTop" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="背景颜色">
        <template>
          <bs-color-picker v-model="curSelData.bgColor" style="float:left" />
          <el-button type="primary" class="btnClear" @click="clearColor('bgColor')">清除</el-button>
        </template>
      </el-form-item>

      <el-form-item label="单位名称">
        <template>
          <el-input v-model="curSelData.depName" placeholder="请输入单位名称"></el-input>
        </template>
      </el-form-item>
      <el-form-item label="分支机构">
        <template>
          <el-input v-model="curSelData.branch" placeholder="请输入分支机构"></el-input>
        </template>
      </el-form-item>
      <el-form-item label="证书编号">
        <template>
          <el-input v-model="curSelData.ceNumber" placeholder="请输入单位名称"></el-input>
        </template>
      </el-form-item>
      <el-form-item label="级别">
        <template>
          <el-input v-model="curSelData.level" placeholder="请输入单位名称"></el-input>
        </template>
      </el-form-item>

      <!--模块一-->
      <div class="listWrap">
        <div class="listTitle">模块一</div>
        <el-card v-for="(item,index) in curSelData.model1" :key="item.title" class="box-card" style="margin-top: 10px">
          <div slot="header" class="clearfix">
            <el-button style="float: right; padding: 3px 0" type="text" @click="delItem(index, 1)">删除</el-button>
          </div>
          <el-row>
            <el-col :span="24">
              <el-input v-model="item.title" placeholder="请输入标题" style="margin-top: 10px">
                <template slot="append">标题</template>
              </el-input>
            </el-col>
          </el-row>
          <el-row style="margin-top: 20px">
            <el-col :span="6">
              <el-image :src="item.img" style="display: block;height: 74px;width: 148px;max-width: 90%">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline" />
                </div>
              </el-image>
            </el-col>
            <el-col :span="18">
              <el-input v-model="item.img" placeholder="请输入图片地址">
                <template slot="append"><span style="cursor: pointer" @click="selImg('xhCertificate2', 'model1', index)">选择图片</span></template>
              </el-input>
            </el-col>
          </el-row>
        </el-card>
        <el-button style="margin:20px auto;width: 80%;display: block" @click="addItem(1)">添加一项</el-button>        
      </div>

      <!--模块二-->
      <div class="listWrap">
        <div class="listTitle">模块二</div>
        <el-card v-for="(item,index) in curSelData.model2" :key="item.title" class="box-card" style="margin-top: 10px">
          <div slot="header" class="clearfix">
            <el-button style="float: right; padding: 3px 0" type="text" @click="delItem(index,2)">删除</el-button>
          </div>
          <el-row>
            <el-col :span="24">
              <el-input v-model="item.title" placeholder="请输入标题" style="margin-top: 10px">
                <template slot="append">标题</template>
              </el-input>
            </el-col>
          </el-row>
          <el-row style="margin-top: 20px">
            <el-col :span="6">
              <el-image :src="item.img" style="display: block;height: 74px;width: 148px;max-width: 90%">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline" />
                </div>
              </el-image>
            </el-col>
            <el-col :span="18">
              <el-input v-model="item.img" placeholder="请输入图片地址">
                <template slot="append"><span style="cursor: pointer" @click="selImg('xhCertificate2', 'model2', index)">选择图片</span></template>
              </el-input>
            </el-col>
          </el-row>
        </el-card>
        <el-button style="margin:20px auto;width: 80%;display: block" @click="addItem(2)">添加一项</el-button>        
      </div>
      
      <el-form-item label="地址">
        <template>
          <el-input v-model="curSelData.address" placeholder="请输入地址" ></el-input>
        </template>
      </el-form-item>
      <el-form-item label="网站">
        <template>
          <el-input v-model="curSelData.website" placeholder="请输入网站"></el-input>
        </template>
      </el-form-item>
      <el-form-item label="电话">
        <template>
          <el-input v-model="curSelData.tel" placeholder="请输入电话"></el-input>
        </template>
      </el-form-item>
      <el-form-item label="邮箱">
        <template>
          <el-input v-model="curSelData.mail" placeholder="请输入邮箱"></el-input>
        </template>
      </el-form-item>

      <el-form-item label="背景图片">
            <el-input v-model="curSelData.bgUrl" placeholder="请输入图片地址">
              <template slot="append"><span style="cursor: pointer" @click="selImg('xhCertificate2','bgUrl')">选择图片</span></template>
            </el-input>
          <el-col :span="18">
            <img :src="curSelData.bgUrl" alt="" class="imgClass">
          </el-col>
      </el-form-item>

    </el-form>
  </div>
</template>
<style lang="scss" scoped>
.myRow {
  line-height: 30px;
  font-size: 12px;
  margin: 10px 0;
}
.formsItem{
  > label {
    position: relative;
    top:10px;
  }
}
.imgClass{
  width: 100%;
  margin-top: 5px;
}
</style>
<script>
import BsSlider from '../module/BsSlider'
import BsColorPicker from '../module/BsColorPicker'
export default {
  components: {
    BsSlider,
    BsColorPicker
  },
  props: {
    curSelData: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
    }
  },
  methods: {
    init(res) {
      this.$message.success('上传成功!');
      console.log('上传成功',res);
      this.curSelData.src = this.ossUrl +res.data
    },
    beforeVideoUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 5;
      if (!isLt20M) {
        this.$message.error('上传PDF不能超过5MB!');
      }
      return isLt20M;
    },
    clearColor(attr) {
      this.curSelData[attr] = 'transparent'
    },
    addItem(type) {
      if(type === 1) {
        this.curSelData.model1.push(
          {
            title: '',
            img: '',
          }
        )
      }else {
        this.curSelData.model2.push(
          {
            title: '',
            img: '',
          }
        )
      }
      
    },
    delItem(index, type) {
      if(type === 1) {
        this.curSelData.model1.splice(index, 1)
      }else {
        this.curSelData.model2.splice(index, 1)
      }
    },
    selImg(type, attr, index) {
      this.$emit('selImg', type, attr, index)
    },
  }
}
</script>
<style>
.listWrap {
  border: 1px solid #ccc;
  background: #eee;
  border-radius: 5px;
  margin: 20px 0;
  padding: 5px;
}
.listWrap .listTitle {
  font-size: 16px;
  text-align: center;
}
</style>

