<template>
    <div class="rBox">

        <el-form label-width="90px">

            <el-form-item label="客服图片">
                <el-col :span="18">
                    <el-input v-model="curSelData.icon" placeholder="请输入标题图标地址">
                        <template slot="append"><span style="cursor: pointer"
                                @click="selImg('zxkf', 'icon')">选择图片</span></template>
                    </el-input>
                </el-col>
                <el-col :span="18">
                    <img :src="curSelData.icon" alt="" class="imgClass">
                </el-col>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
// import BsColorPicker from '../module/BsColorPicker'
// import BsSlider from '../module/BsSlider'
export default {
    components: {
        // BsSlider,
        // BsColorPicker
    },
    props: {
        curSelData: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
        }
    },
    methods: {
        selImg(type, attr, index) {
            this.$emit('selImg', type, attr, index)
        },
    }
}
</script>
<style scoped>
.imgClass {
    max-width: 100%;
    margin-top: 20px;
}

.selProductButton {
    position: absolute;
    height: 20px;
    left: 0;
    right: 20%;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    position: absolute;
    cursor: pointer;
}
</style>
  