<template>
    <div class="fyWrap">
      <img v-for="(itemImg,index) in item.picList" :key="index" :src="itemImg.url" class="fyImgIcon"/>
      <div class="content">
          <img :src="item.zsImg" class="zsImg"/>
          <div class="contengBox">
            <div v-for="listItem in item.list" :key="listItem.title" class="listItem">
              <div class="listItemKey">{{listItem.title}}</div>
              <div class="listItemVal">{{listItem.value}}</div>
            </div>
          </div>
          <div class="tipWrap">
            注: 如有疑问，请与认证机构方圆标志认证集团有限公司(电话: 01068414836)联系核实，或拨打12315举报、投诉。
          </div>
      </div>
    </div>
</template>
<script>

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {

    }
  },
  methods:{
    getName(index) {
      let name = 'Block'+(index+1)+'Visible'
      return this[name]
    },
    changeVisible(index) {
      let name = 'Block'+(index+1)+'Visible'
      this[name] = !this[name]
    }
  }
}
</script>
<style scoped>
.fyWrap{
  background: #DCE9DE url('https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/s_bj%402x.png') top center no-repeat;
  background-size: 100% 211px;
  padding-top: 46px;
  padding-bottom: 40px;
}
.fyImgIcon{
  width: 82px;
  height: 127px;
  margin: 0 27px;
}
.content{
  width: 357px;
  background: #fff;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 44px 6px 25px;
  margin: 0 auto 0px;
}
.zsImg{
  width: 260px;
  height: 345px;
  margin-bottom: 22px;
}
.contengBox{
  border-top: 1px dashed #DCE9DE;
  padding-top: 10px;
}
.listItem{
  display: flex;
  padding-top: 22px;
}
.listItemKey{
  width: 100px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #8B8F96;
  vertical-align: baseline;
  text-align: right;
  margin-right: 30px;
}
.listItemVal{
  flex: 1;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #3F4865;
  vertical-align: baseline;
  text-align: left;

}
.tipWrap{
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #E19717;
  padding: 15px 10px;
  background-color: #FFF9EC;
  border-radius: 10px;
  text-align: left;
}
</style>