<template>
  <div class="rBox">
    <el-form label-width="80px">
       <el-form-item label="标题">
            <div >
              <el-input v-model="curSelData.pageTitle" placeholder="请输入标题">
                </el-input>
            </div>

        </el-form-item>
        <el-card style="margin-bottom: 20px;">
          <el-form-item label="模块名称">
                <el-input v-model="curSelData.title" placeholder="请输入标题栏">
                </el-input>
            </el-form-item>

            <el-form-item label="标题" v-if="curSelData.useType == 2">
                <el-input v-model="curSelData.subTitle" placeholder="请输入标题">
                </el-input>
            </el-form-item>

            <el-form-item label="内容" v-if="curSelData.useType == 2">
                <el-input v-model="curSelData.titleValue" placeholder="请输入内容">
                </el-input>
            </el-form-item>
         
            <div v-for="(item,indexA) in curSelData.mainPar" :key="indexA">
              <form-item-edit :item="item" :model-array="modelArray" :show-del="false"/>
<!--              <el-form-item label="标题">-->
<!--                  <el-input v-model="item.title" placeholder="请输入标题">-->
<!--                  </el-input>-->
<!--              </el-form-item>-->
<!--              <el-form-item label="内容">-->
<!--                  <el-input v-model="item.content" placeholder="请输入内容">-->
<!--                  </el-input>-->
<!--              </el-form-item>-->
            </div>
            <draggable v-model="curSelData.subPar">
            <div v-for="(item,indexB) in curSelData.subPar" :key="indexB">
              <form-item-edit :item="item" :model-array="modelArray" @del="delItemSubPar(indexB)" :show-del="true"/>
<!--              <el-card style="margin-bottom: 10px;">-->
<!--                <div slot="header" class="clearfix red" @click="delItemSubPar(indexB)">删除</div>-->
<!--              <el-form-item label="标题">-->
<!--                  <el-input v-model="item.title" placeholder="请输入标题">-->
<!--                  </el-input>-->
<!--              </el-form-item>-->
<!--              <el-form-item label="内容">-->
<!--                  <el-input v-model="item.content" placeholder="请输入内容">-->
<!--                  </el-input>-->
<!--              </el-form-item>-->
<!--              </el-card>-->
            </div>
            </draggable>
            <el-button style="margin:20px auto;width: 80%;display: block" @click="addItemSubPar">添加一行</el-button>
        </el-card>
        
        <template v-if="curSelData.useType == 2">
          <el-card class="mkBox">
            <el-form-item label="模块名称">
              <el-input v-model="curSelData.parDetail.title" placeholder="请输入模块名称">
              </el-input>
            </el-form-item>
            <draggable v-model="curSelData.parDetail.list">
              <el-card v-for="(innerItem, innerIndex) in curSelData.parDetail.list" :key="innerIndex" class="box-card" style="margin-bottom: 10px;">
                <div slot="header" class="clearfix red" @click="delItem22(innerIndex)">删除</div>
                  <el-form-item label="项目">
                    <el-input v-model="innerItem.c1" />
                  </el-form-item>
                  <el-form-item label="合格指标">
                    <el-input v-model="innerItem.c2" />
                  </el-form-item>
                  <el-form-item label="检验结果">
                    <el-input v-model="innerItem.c3" />
                  </el-form-item>
              </el-card>
            </draggable>
              <el-button style="margin:20px auto;width: 80%;display: block" @click="addItem22(index)">添加一行</el-button>
          </el-card>


          <el-card class="box-card" style="margin-top: 10px">
              <div slot="header" class="clearfix">
                  产品图片
              </div>
              <el-form label-width="90px">
                  <el-row style="margin-bottom: 10px;">
                      <el-col :span="6">
                          <el-image :src="curSelData.reportImg"
                              style="display: inline-block;height: 74px;width: 148px;max-width: 90%">
                              <div slot="error" class="image-slot">
                                  <i class="el-icon-picture-outline" />
                              </div>
                          </el-image>
                      </el-col>
                      <el-col :span="18">
                          <el-input v-model="curSelData.reportImg" placeholder="请输入图片地址">
                              <template slot="append"><span style="cursor: pointer"
                                      @click="selImg('deviceNameplate', 'reportImg')">选择图片</span></template>
                          </el-input>
                      </el-col>
                  </el-row>
              </el-form>
              <el-form-item label="产品描述">
                <template>
                  <el-input v-model="curSelData.devInfo" placeholder="请输入产品描述"></el-input>
                </template>
              </el-form-item>
          </el-card>
          <el-card class="box-card" style="margin-top: 10px">
            <div slot="header" class="clearfix">文档列表</div>
            <draggable v-model="curSelData.docList">
              <div v-for="(item5,item5Index) in curSelData.docList" :key="item5Index">
                  <el-card class="box-card" style="margin-top: 10px">
                    <div slot="header" class="clearfix" >
                      <span @click="delDocItem(item5Index)" class="red">删除</span>
                    </div>
                    <el-form label-width="90px">
                        <el-row style="margin-bottom: 10px;">
                            <el-col :span="5">
                                <el-image :src="curSelData.docList[item5Index].icon"
                                    style="display: inline-block;height: 34px;width: 34px;max-width: 90%; margin-left: 20px;">
                                    <div slot="error" class="image-slot">
                                        <i class="el-icon-picture-outline" />
                                    </div>
                                </el-image>
                            </el-col>
                            <el-col :span="19">
                                <el-input v-model="curSelData.docList[item5Index].icon" placeholder="请输入图片地址">
                                    <template slot="append"><span style="cursor: pointer"
                                            @click="selImg('deviceNameplate', 'docList', item5Index)">选择图标</span></template>
                                </el-input>
                            </el-col>
                        </el-row>
                    </el-form>
                    <el-form-item label="文件标题">
                      <el-input v-model="item5.title" />
                    </el-form-item>
                    <el-form-item label="上传文件">
                      <el-upload
                            :action="uploadImgUrl"
                            :headers="headers"
                            name="file"
                            :show-file-list="false"
                            :on-success="(res) => { init(res, item5Index)}"
                            :before-upload="beforeVideoUpload"
                            class="devUp"
                        >
                          <el-button size="small" type="primary">点击上传</el-button>
                          <div slot="tip" class="el-upload__tip">支持格式：doc, xls, ppt, pdf, docx, xlsx, pptx</div>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="文件地址">
                          <el-input readonly v-model="curSelData.docList[item5Index].src" placeholder="请上传文件">
                          </el-input>
                    </el-form-item>
                  </el-card>
                </div>
            </draggable>
            
              <el-button style="margin:20px auto;width: 80%;display: block" @click="addDocItem()">添加文件</el-button>
          </el-card>
          <el-card class="box-card" style="margin-top: 10px">
            <div slot="header" class="clearfix"> 按钮</div>
            <el-form-item label="按钮名称">
              <template>
                <el-input v-model="curSelData.text" placeholder="请输入按钮名称"></el-input>
              </template>
            </el-form-item>
            <el-form-item label="电话号码">
              <template>
                <el-input v-model="curSelData.phone" placeholder="请输入电话号码"></el-input>
              </template>
            </el-form-item>
          </el-card>


        </template>

        <template v-else>
          <draggable v-model="curSelData.parDetail">
          <el-card v-for="(item, index) in curSelData.parDetail" :key="index">
            <div slot="header" class="clearfix" @click="delItem(index)">删除</div>
            <el-form-item label="模块名称">
              <el-input v-model="item.title" placeholder="请输入模块名称">
              </el-input>
            </el-form-item>
            <el-form-item label="是否折叠">
              <template>
                  <el-radio v-model="item.type" :label="1">是</el-radio>
                  <el-radio v-model="item.type" :label="2">否</el-radio>
              </template>
            </el-form-item>
            <template v-if="item.type === 1">
              <el-card v-for="(innerItem, innerIndex) in item.list" :key="innerItem.title" class="box-card">
                <div slot="header" class="clearfix" @click="delItem2(index, innerIndex)">删除</div>
                  <el-form-item label="第一列">
                    <el-input v-model="innerItem.c1" />
                  </el-form-item>
                  <el-form-item label="第二列">
                    <el-input v-model="innerItem.c2" />
                  </el-form-item>
                  <el-form-item label="第三列">
                    <el-input v-model="innerItem.c3" />
                  </el-form-item>
              </el-card>
              <el-button style="margin:20px auto;width: 80%;display: block" @click="addItem2(index)">添加一行</el-button>
            </template>
            <template v-else-if="item.type === 2">
            <el-form-item label="模块内容">
                <el-input v-model="item.value" placeholder="请输入模块内容">
                </el-input>
            </el-form-item>
            </template>
          </el-card>
          </draggable>
          <el-button style="margin:20px auto;width: 80%;display: block" @click="addItem">添加一项</el-button>
          
          <el-card class="box-card" style="margin-top: 10px">
              <div slot="header" class="clearfix">
                  报告图片
              </div>
              <el-form label-width="90px">
                  <el-row>
                      <el-col :span="6">
                          <el-image :src="curSelData.reportImg"
                              style="display: inline-block;height: 74px;width: 148px;max-width: 90%">
                              <div slot="error" class="image-slot">
                                  <i class="el-icon-picture-outline" />
                              </div>
                          </el-image>
                      </el-col>
                      <el-col :span="18">
                          <el-input v-model="curSelData.reportImg" placeholder="请输入图片地址">
                              <template slot="append"><span style="cursor: pointer"
                                      @click="selImg('deviceNameplate', 'reportImg')">选择图片</span></template>
                          </el-input>
                      </el-col>
                  </el-row>
              </el-form>
          </el-card>
        </template>
    </el-form>
  </div>
</template>
<script>
// import BsSlider from '../module/BsSlider'
// import BsColorPicker from '../module/BsColorPicker'
import draggable from 'vuedraggable'
import FormItemEdit from "@/pages/customPage/editpage/FormItemEdit.vue";
export default {
  components: {
    FormItemEdit,
    draggable
    // BsSlider,
    // BsColorPicker
  },
  props: {
    curSelData: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => {}
    },
    modelArray:{
       type: Array,
       default: ()=>{return []}
    }
  },
  data() {
    let token = localStorage.getItem("token")
    return {
      uploadImgUrl: '/api/file/uploadObjectOSS', // 上传的视频服务器地址
      ossUrl: this.$store.state.ossUrl,
      headers: {
        token: token,
      },
    }
  },
  methods: {
    selImg(type, attr,index) {
      this.$emit('selImg', type, attr, index)
    },
    addItem() {
      this.curSelData.parDetail.push({
        title: '模块名称',
        type: 1,
        list:[
          {
            c1: '项目',
            c2: '值',
            c3: '值'
          },
        ],
        value: '模块内容',
      })
    },
    addItemSubPar() {
     this.curSelData.subPar.push({
        title: '标题',
        content: '内容',
     })
    },
    delItemSubPar(index) {
      this.curSelData.subPar.splice(index, 1)
    },
    addItem2(index) {
      this.curSelData.parDetail[index].list.push(
        {
          c1: '项目名称',
          c2: '实测值',
          c3: '要求值'
        }
      )
    },

    addItem22(index) {

      this.curSelData.parDetail.list.push(
        {
          c1: '项目名称',
          c2: '实测值',
          c3: '要求值'
        }
      )
    },
    delItem22(index) {
      this.curSelData.parDetail.list.splice(index, 1)
    },
    delItem2(index,innerIndex) {
      this.curSelData.parDetail[index].list.splice(innerIndex, 1)
    },
    // delItem(type,index) {
    //   if(type === 1) {
    //     this.curSelData.gnList.splice(index, 1)
    //   }else  if(type === 2) {
    //     this.curSelData.parList.splice(index, 1)
    //   }
    // },
    delItem(index) {
      this.curSelData.parDetail.splice(index, 1)
    },
    addBotImg(index) {
      this.curSelData.parDetail[index].picList.push(
        {
          img: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/345.png'
        }
      )
    },
     delBotImg(index, bImgIndex) {
        this.curSelData.parDetail[index].picList.splice(bImgIndex, 1)
    },

    addDocItem() {
       this.curSelData.docList.push({
            title:'文件名称',
            icon:'https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/sms%402x.png',
            src:'',
       })
    },
    delDocItem(index) {
      this.curSelData.docList.splice(index, 1)
    },
    // 上传
    init(res, index) {
      this.$message.success('上传成功!');
      this.curSelData.docList[index].src = this.ossUrl +res.data
    },
    beforeVideoUpload(file) {
      // doc, xls, ppt, pdf, docx, xlsx, pptx
    let uniDocList = ['doc', 'xls', 'ppt', 'pdf', 'docx', 'xlsx', 'pptx']
      let fileType = file.name.substring(file.name.lastIndexOf('.')+1)
      console.log(fileType)
      if(uniDocList.indexOf(fileType) == -1) {
        this.$message.error('文件格式不支持!')
        return false
      }else {
       return true
        // const isLt20M = file.size / 1024 / 1024 < 5;
        // if (!isLt20M) {
        //   this.$message.error('上传PDF不能超过5MB!');
        // }
        // return isLt20M;
      }
    },

  }
}
</script>
<style scoped>
.mkBox{
  margin-bottom: 30px;
}
.red{
  color: #f00;

}
.devUp >>> .el-upload--text{
  width: auto;
  height: auto;
  border: none;
}
</style>
