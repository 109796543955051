<template>
	<div class="rBox">
		<el-form label-width="90px">
			<el-form-item label="标题图片">
				<el-col :span="18">
					<el-input v-model="curSelData.titleImg" placeholder="请输入标题图标地址">
						<template slot="append"><span style="cursor: pointer"
								@click="selImg('wisdom', 'titleImg')">选择图片</span></template>
					</el-input>
				</el-col>
				<el-col :span="18">
					<img height="100px" :src="curSelData.titleImg" alt="" class="imgClass">
				</el-col>
			</el-form-item>
			<el-form-item label="标题图片尺寸">
				<bs-slider v-model="curSelData.titleImgSize" :min="0" :max="300" />
			</el-form-item>
			<el-form-item label="标题">
				<template>
					<el-input v-model="curSelData.title" placeholder="请输入标题" style="margin-top: 10px"></el-input>
				</template>
			</el-form-item>
			<el-form-item label="底部节点名称">
				<template>
					<el-input v-model="curSelData.jdname" placeholder="请输入节点名称" style="margin-top: 10px"></el-input>
				</template>
			</el-form-item>
			<el-form-item label="标题文字大小">
				<bs-slider v-model="curSelData.titleSize" :min="0" :max="100" />
			</el-form-item>
			<el-form-item label="标题颜色">
				<template>
					<bs-color-picker v-model="curSelData.titleColor" />
				</template>
			</el-form-item>
			<el-form-item label="标题内容">
				<template>
					<el-input v-model="curSelData.titleC" placeholder="请输入标题内容" style="margin-top: 10px"></el-input>
				</template>
			</el-form-item>
			<el-form-item label="内容颜色">
				<template>
					<bs-color-picker v-model="curSelData.titlecColor" />
				</template>
			</el-form-item>
			<el-form-item label="图片1上下边距">
				<bs-slider v-model="curSelData.z2M" :min="-200" :max="200" />
			</el-form-item>
			<el-form-item label="图片2上下边距">
				<bs-slider v-model="curSelData.z4M" :min="0" :max="400" />
			</el-form-item>
			<el-card v-for="(item, index) in curSelData.list" :key="item.name" class="box-card"
				style="margin-top: 10px">
				<div slot="header" class="clearfix">
					<el-button style="float: right; padding: 3px 0" type="text" @click="delItem(index)">删除</el-button>
				</div>
				<el-row>
					<el-row>
						<el-col :span="6">
							<el-image :src="item.num"
								style="display: inline-block;height: 74px;width: 148px;max-width: 90%">
								<div slot="error" class="image-slot">
									<i class="el-icon-picture-outline" />
								</div>
							</el-image>
						</el-col>
						<el-col :span="18">
							<el-input v-model="item.num" placeholder="请输入图片地址">
								<template slot="append"><span style="cursor: pointer"
										@click="selImg('wisdom', 'num', index)">选择图片</span></template>
							</el-input>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="24" style="margin-top: 5px;">
							<el-input v-model="item.titleL" placeholder="这里是标题">
								<template slot="append"><span style="cursor: pointer">标题</span></template>
							</el-input>
						</el-col>

					</el-row>
					<el-form-item label="内容图片">
						<el-col :span="18">
							<el-input v-model="item.pic" placeholder="请输入内容图片地址">
								<template slot="append"><span style="cursor: pointer"
										@click="selImg('wisdom', 'pic', index)">选择图片</span></template>
							</el-input>
						</el-col>
						<el-col :span="18">
							<img height="100px" :src="item.pic" alt="" class="imgClass">
						</el-col>
					</el-form-item>
					<el-form-item label="图片高度">
						<bs-slider v-model="item.height" :min="0" :max="1000" />
					</el-form-item>
					<el-form-item label="文本颜色">
						<template>
							<bs-color-picker v-model="item.color" />
						</template>
					</el-form-item>
					<el-form-item label="文字大小">
						<bs-slider v-model="item.textSize" :min="0" :max="30" />
					</el-form-item>
					<el-card v-for="(i, j) in item.content" :key="j" class="box-card" style="margin-top: 10px">
						<div slot="header" class="clearfix">
							<el-button style="float: right; padding: 3px 0" type="text" @click="delText(index, j)">删除
							</el-button>
						</div>
						<el-row>
							<el-col :span="24" style="margin-top: 5px;">
								<el-input v-model="i.text" placeholder="这里是内容介绍">
									<template slot="append"><span style="cursor: pointer">内容</span></template>
								</el-input>
							</el-col>

						</el-row>
					</el-card>
					<el-form-item label="视频">
					    <template>
					        <el-radio v-model="curSelData.spShow" :label="true">显示</el-radio>
					        <el-radio v-model="curSelData.spShow" :label="false">不显示</el-radio>
					    </template>
					</el-form-item>
					<el-card class="box-card" style="margin-top: 10px" v-if="index == 2 && curSelData.spShow">
						<el-row>
						  <el-col :span="24">
						    <el-input v-model="curSelData.src" placeholder="请输入视频地址">
						      <template slot="append"><span style="cursor: pointer" @click="selVideo">选择视频</span></template>
						    </el-input>
						  </el-col>
						</el-row>
					</el-card>
					
					<el-form-item v-if="index == 2" label="视频上边距">
						<bs-slider v-model="curSelData.srcTop" :min="0" :max="50" />
					</el-form-item>
					<el-button style="margin:20px auto;width: 80%;display: block;" @click="addC(index)">添加内容</el-button>
					<!-- <el-col :span="24" v-for="(i, j) in item.content" :key="j" style="margin-top: 5px;">
						<el-input v-model="i.text" placeholder="这里是内容介绍">
							<template slot="append"><span style="cursor: pointer">内容</span></template>
						</el-input>
					</el-col> -->

				</el-row>
				<el-button style="margin:20px auto;width: 80%;display: block" @click="addItem">添加</el-button>
				<!-- <el-button style="margin:20px auto;width: 80%;display: block;" @click="addC(index)">添加内容</el-button> -->
			</el-card>
			<el-form-item label="标识">
			    <template>
			        <el-radio v-model="curSelData.bsShow" :label="true">显示</el-radio>
			        <el-radio v-model="curSelData.bsShow" :label="false">不显示</el-radio>
			    </template>
			</el-form-item>
			
			
			<el-card v-for="(item, index) in curSelData.analysisList" :key="item.name" class="box-card"
				style="margin-top: 10px">
				<div slot="header" class="clearfix">
					<el-button style="float: right; padding: 3px 0" type="text" @click="delBS(index)">删除</el-button>
				</div>
				<el-row>
					<el-row>
						<el-col :span="24" style="margin-top: 5px;">
							<el-input v-model="item.name" placeholder="这里是按钮">
								<template slot="append"><span style="cursor: pointer">按钮</span></template>
							</el-input>
						</el-col>
			
					</el-row>
					<el-form-item label="内容图片">
						<el-col :span="18">
							<el-input v-model="item.bsImg" placeholder="请输入内容图片地址">
								<template slot="append"><span style="cursor: pointer"
										@click="selImg('wisdom', 'bsImg')">选择图片</span></template>
							</el-input>
						</el-col>
						<el-col :span="18">
							<img height="100px" :src="item.bsImg" alt="" class="imgClass">
						</el-col>
					</el-form-item>
					<el-form-item label="图片高度">
						<bs-slider v-model="item.height" :min="0" :max="1000" />
					</el-form-item>
						<el-row>
							<el-col :span="24" style="margin-top: 5px;">
								<el-input v-model="item.content" placeholder="这里是内容">
									<template slot="append"><span style="cursor: pointer">内容</span></template>
								</el-input>
							</el-col>
									
						</el-row>
				</el-row>
				<el-button style="margin:20px auto;width: 80%;display: block" @click="addBS">添加</el-button>
				<!-- <el-button style="margin:20px auto;width: 80%;display: block;" @click="addC(index)">添加内容</el-button> -->
			</el-card>

		</el-form>
	</div>
</template>
<script>
	import BsColorPicker from '../module/BsColorPicker'
	import BsSlider from '../module/BsSlider'
	export default {
		components: {
			BsSlider,
			BsColorPicker
		},
		props: {
			curSelData: {
				type: Object,
				default: () => {}
			}
		},
		data() {
			return {
				inputValue: ''
			}
		},
		methods: {
			selVideo() {
			  this.$emit('selVideo')
			},
			addBS() {
				this.curSelData.analysisList.push({
					name: '# 标识解析应用前',
					bsImg: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/zhihuihuagong/t_q@2x.png',
					content: '指应用数字技术和数据资源为传统产业带来的产出增加和效率提升',
					height: 326,
					width: 323
				})
			},
			addC(index) {
				this.curSelData.list[index].content.push({
					text: '化工等典型的流程型制造业，一方面产业销条层级多，存在数据来源分散，上下游协同效率低下的问题;另一方面生产过程复杂，伴随着风险因素难以分辨，安全生产管理手段落后，应急响应预案及事故管理实施困难等痛点。'
				})
			},
			addItem() {
				this.curSelData.list.push({
					num: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/zhihuihuagong/01@2x.png',
					gImg: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/zhihuihuagong/g@2x.png',
					titleL: '发展痛点',

					content: [{
						text: '化工等典型的流程型制造业，一方面产业销条层级多，存在数据来源分散，上下游协同效率低下的问题;另一方面生产过程复杂，伴随着风险因素难以分辨，安全生产管理手段落后，应急响应预案及事故管理实施困难等痛点。'
					}],
					bg: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/zhihuihuagong/k_1@2x.png',
					color: '#fff',
					textSize: 12,
					pic: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/zhihuihuagong/zhhg@2x.png',
					height: 326,
					width: 323
				})
			},
			delItem(index) {
				this.curSelData.list.splice(index, 1)
			},
			delBS(index) {
				this.curSelData.analysisList.splice(index, 1)
			},
			delText(index, j) {
				this.curSelData.list[index].content.splice(j, 1)
			},
			selProduct(index) {
				console.log(index)
				this.$emit('selProduct', index)
			},
			clearColor(attr) {
				this.curSelData[attr] = 'transparent'
			},
			selImg(type, attr, index) {
				this.$emit('selImg', type, attr, index)
			},
			clearTit() {
				this.curSelData.title = ''
			},
			clearSubTit() {
				this.curSelData.subTitle = ''
			}

		}
	}
</script>
<style scoped>
	.imgClass {
		max-width: 100%;
		margin-top: 20px;
	}

	.selProductButton {
		position: absolute;
		height: 20px;
		left: 0;
		right: 20%;
		bottom: 0;
		background: rgba(0, 0, 0, 0.5);
		color: #fff;
		text-align: center;
		font-size: 12px;
		line-height: 20px;
		position: absolute;
		cursor: pointer;
	}

	.el-row {}
</style>
