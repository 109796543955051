<template>
	<div class="rBox">
		<el-form label-width="90px">
			<!-- <el-form-item label="风格">
	    			          <template>
	    			            <el-radio v-model="curSelData.scanStyle" :label="1">风格一</el-radio>
	    			            <el-radio v-model="curSelData.scanStyle" :label="2">风格二</el-radio>
	    			          </template>
	    			      </el-form-item> -->
			<!-- <el-form-item label="上下边距">
	    					<template>
	    						<bs-slider v-model="curSelData.paddTop" :min="0" :max="100" />
	    					</template>
	    				</el-form-item>
	    				<el-form-item label="左右边距">
	    					<template>
	    						<bs-slider v-model="curSelData.paddLeft" :min="0" :max="100" />
	    					</template>
	    				</el-form-item> -->
			<!-- <el-form-item label="二维码背景">
	    			            <el-input v-model="curSelData.scanBg" placeholder="请输入图片地址">
	    			            <template slot="append"><span style="cursor: pointer" @click="selImg('scanFive','scanBg')">选择图片</span></template>
	    			          </el-input>
	    			      </el-form-item> -->
			<!-- <el-form-item label="背景颜色" class="formsItem">
	    					<template>
	    						<bs-color-picker v-model="curSelData.bgColor" style="float:left;" />
	    						<el-button type="primary" class="btnClear" @click="clearColor('bgColor')">清除</el-button>
	    					</template>
	    				</el-form-item> -->
			<!-- <el-form-item label="按钮背景色" class="formsItem">
	    			        <template>
	    			          <bs-color-picker v-model="curSelData.btnBgColor" style="float:left;" />
	    			          <el-button type="primary" class="btnClear" @click="clearColor('btnBgColor')">清除</el-button>
	    			        </template>
	    			      </el-form-item> -->
			<el-form-item label="标题图标">
				<el-col :span="18">
					<el-input v-model="curSelData.icon" placeholder="请输入标题图标地址">
						<template slot="append"><span style="cursor: pointer"
								@click="selImg('changeZone', 'icon')">选择图片</span></template>
					</el-input>
				</el-col>
				<el-col :span="18">
					<img :src="curSelData.icon" alt="" class="imgClass">
				</el-col>
			</el-form-item>
			<el-form-item label="标题">
				<el-input v-model="curSelData.title" type="text" placeholder="请输入标题">
					<el-button slot="append">清除</el-button>
				</el-input>
			</el-form-item>
			<el-form-item label="步骤">
				<el-col :span="18">
					<el-input v-model="curSelData.step" placeholder="请输入步骤图片地址">
						<template slot="append"><span style="cursor: pointer"
								@click="selImg('changeZone', 'step')">选择图片</span></template>
					</el-input>
				</el-col>
				<el-col :span="18">
					<img :src="curSelData.step" alt="" class="imgClass">
				</el-col>
			</el-form-item>
			<el-form-item label="按钮内容">
				<el-input v-model="curSelData.btn" type="text" placeholder="请输入标题">
					<el-button slot="append">清除</el-button>
				</el-input>
			</el-form-item>
			<el-form-item label="底边框">
				<template>
					<el-radio v-model="curSelData.bottomshow" :label="true">显示</el-radio>
					<el-radio v-model="curSelData.bottomshow" :label="false">不显示</el-radio>
				</template>
			</el-form-item>
			<!-- <el-form-item label="背景图片">
	    					<el-input v-model="curSelData.boxBg" placeholder="请输入图片地址">
	    						<template slot="append"><span style="cursor: pointer"
	    								@click="selImg('scanFive', 'boxBg')">选择图片</span></template>
	    					</el-input>
	    					<el-col :span="18">
	    						<img :src="curSelData.boxBg" alt="" class="imgClass">
	    					</el-col>
	    				</el-form-item>
	    				<el-form-item label="标识码大小">
	    					<template>
	    						<bs-slider v-model="curSelData.greyBoxFize" :min="0" :max="100" />
	    					</template>
	    				</el-form-item>
	    				<el-form-item label="标识码颜色" class="formsItem">
	    					<template>
	    						<bs-color-picker v-model="curSelData.greyBoxColor" style="float:left;" />
	    						<el-button type="primary" class="btnClear" @click="clearColor('greyBoxColor')">清除</el-button>
	    					</template>
	    				</el-form-item>
	    				<el-form-item label="标识码背景颜色" class="formsItem">
	    					<template>
	    						<bs-color-picker v-model="curSelData.greyBoxBg" style="float:left;" />
	    						<el-button type="primary" class="btnClear" @click="clearColor('greyBoxBg')">清除</el-button>
	    					</template>
	    				</el-form-item>
	    				<el-form-item label="节点大小">
	    					<template>
	    						<bs-slider v-model="curSelData.msgBoxFize" :min="0" :max="100" />
	    					</template>
	    				</el-form-item>
	    				<el-form-item label="节点颜色" class="formsItem">
	    					<template>
	    						<bs-color-picker v-model="curSelData.msgBoxColor" style="float:left;" />
	    						<el-button type="primary" class="btnClear" @click="clearColor('msgBoxColor')">清除</el-button>
	    					</template>
	    				</el-form-item>
	    				<el-form-item label="查询次数大小">
	    					<template>
	    						<bs-slider v-model="curSelData.scanNumberFize" :min="0" :max="100" />
	    					</template>
	    				</el-form-item>
	    				<el-form-item label="查询次数颜色" class="formsItem">
	    					<template>
	    						<bs-color-picker v-model="curSelData.scanNumberColor" style="float:left;" />
	    						<el-button type="primary" class="btnClear" @click="clearColor('scanNumberColor')">清除</el-button>
	    					</template>
	    				</el-form-item>
	    				<el-form-item label="查询文字大小">
	    					<template>
	    						<bs-slider v-model="curSelData.scanMsgFize" :min="0" :max="100" />
	    					</template>
	    				</el-form-item>
	    				<el-form-item label="查询文字颜色" class="formsItem">
	    					<template>
	    						<bs-color-picker v-model="curSelData.scanMsgColor" style="float:left;" />
	    						<el-button type="primary" class="btnClear" @click="clearColor('scanMsgColor')">清除</el-button>
	    					</template>
	    				</el-form-item>
	    				<el-form-item label="查询提示文字大小">
	    					<template>
	    						<bs-slider v-model="curSelData.scanInfoFize" :min="0" :max="100" />
	    					</template>
	    				</el-form-item>
	    				<el-form-item label="查询提示文字颜色" class="formsItem">
	    					<template>
	    						<bs-color-picker v-model="curSelData.scanInfoColor" style="float:left;" />
	    						<el-button type="primary" class="btnClear" @click="clearColor('scanInfoColor')">清除</el-button>
	    					</template>
	    				</el-form-item>
	    				<el-form-item label="查询信息文字大小">
	    					<template>
	    						<bs-slider v-model="curSelData.scanTextFize" :min="0" :max="100" />
	    					</template>
	    				</el-form-item>
	    				<el-form-item label="查询信息文字颜色" class="formsItem">
	    					<template>
	    						<bs-color-picker v-model="curSelData.scanTextColor" style="float:left;" />
	    						<el-button type="primary" class="btnClear" @click="clearColor('scanTextColor')">清除</el-button>
	    					</template>
	    				</el-form-item>
	    				<el-form-item label="查询信息背景颜色" class="formsItem">
	    					<template>
	    						<bs-color-picker v-model="curSelData.scanQueryBg" style="float:left;" />
	    						<el-button type="primary" class="btnClear" @click="clearColor('scanQueryBg')">清除</el-button>
	    					</template>
	    				</el-form-item> -->
			<!-- <el-form-item label="标签展示">
	    			          <template>
	    			            <el-radio v-model="curSelData.showCodeType" :label="1">不显示</el-radio>
	    			            <el-radio v-model="curSelData.showCodeType" :label="2">显示验证码</el-radio>
	    			            <el-radio v-model="curSelData.showCodeType" :label="3">显示标识码</el-radio>
	    			          </template>
	    			      </el-form-item>
	    			      <el-form-item label="标签颜色">
	    			          <template>
	    			              <bs-color-picker v-model="curSelData.CodeTypeColor" style="float:left;" />
	    			              <el-button type="primary" class="btnClear" @click="clearColor('CodeTypeColor')">清除</el-button>
	    			           </template>
	    			      </el-form-item> -->
			<!-- <el-form-item label="跳转控件">
	    					<template>
	    						<el-radio v-model="curSelData.jumpStyle" :label="1">不显示</el-radio>
	    						<el-radio v-model="curSelData.jumpStyle" :label="2">风格一</el-radio>
	    					</template>
	    				</el-form-item> -->
			<!--弹窗控件-->
			<!-- <el-form-item label="按钮控件">
	    					<template>
	    						<el-radio v-model="curSelData.kjStyle" :label="1">风格一</el-radio>
	    						<el-radio v-model="curSelData.kjStyle" :label="2">不显示</el-radio>
	    					</template>
	    				</el-form-item> -->
			<!-- <template v-if="curSelData.kjStyle === 1">
	    					<el-card v-for="(item, index) in curSelData.picList" :key="item.url + index" class="box-card"
	    						style="margin-top: 10px">
	    						<div slot="header" class="clearfix">
	    							<el-button style="float: right; padding: 3px 0" type="text" @click="delPic(index)">删除
	    							</el-button>
	    						</div>
	    						<el-row>
	    							<el-col :span="6">
	    								<el-image :src="item.url" style="display: block;height: 74px;width: 148px;max-width: 90%">
	    									<div slot="error" class="image-slot">
	    										<i class="el-icon-picture-outline" />
	    									</div>
	    								</el-image>
	    							</el-col>
	    							<el-col :span="18">
	    								<el-input v-model="item.url" placeholder="请输入图片地址">
	    									<template slot="append"><span style="cursor: pointer"
	    											@click="selImg('scanFive', 'picList', index)">选择图片</span></template>
	    								</el-input>
	    								<el-input v-model="item.link" placeholder="请输入链接" style="margin-top: 10px">
	    									<template slot="append">选择链接</template>
	    								</el-input>
	    							</el-col>
	    							<el-input v-model="item.width" placeholder="请输入图片宽度" style="margin-top: 10px">
	    								<template slot="append">图片宽度</template>
	    							</el-input>
	    						</el-row>
	    					</el-card>
	    					<el-button style="margin:20px auto;width: 80%;display: block" @click="addItem">添加</el-button>
	    				</template> -->

		</el-form>
	</div>
</template>
<script>
// import BsSlider from '../module/BsSlider'
// import BsColorPicker from '../module/BsColorPicker'
export default {
	components: {
		// BsSlider,
		// BsColorPicker
	},
	props: {
		curSelData: {
			type: Object,
			// eslint-disable-next-line vue/require-valid-default-prop
			default: () => { }
		}
	},
	data() {
		return {

		}
	},
	created() {
		console.log('=======' + JSON.stringify(this.curSelData))
	},
	methods: {
		clearColor(attr) {
			console.log(attr)
			if (attr == 'bgColor') {
				this.curSelData[attr] = 'transparent'
			} else if (attr == 'greyBoxColor') {
				this.curSelData[attr] = '#000'
			} else if (attr == 'greyBoxBg') {
				this.curSelData[attr] = '#e7e7e7'
			} else if (attr == 'msgBoxColor') {
				this.curSelData[attr] = '#B49B51'
			} else if (attr == 'scanNumberColor') {
				this.curSelData[attr] = '#B49B51'
			} else if (attr == 'scanMsgColor') {
				this.curSelData[attr] = '#3e362c'
			} else if (attr == 'scanInfoColor') {
				this.curSelData[attr] = '#B49B51'
			} else if (attr == 'scanQueryBg') {
				this.curSelData[attr] = '#f8f7f4'
			} else if (attr == 'scanTextColor') {
				this.curSelData[attr] = '#000'
			}
		},
		selImg(type, attr) {
			this.$emit('selImg', type, attr)
		},
		addItem() {
			const width = (100 / (this.curSelData.picList.length + 1)).toFixed(2)
			this.curSelData.picList.forEach(item => {
				item.width = width + '%'
			})
			this.curSelData.picList.push({
				width: width + '%',
				url: ''
			})
		},
		delPic(index) {
			const width = (100 / (this.curSelData.picList.length - 1)).toFixed(2)
			this.curSelData.picList.forEach(item => {
				item.width = width + '%'
			})
			this.curSelData.picList.splice(index, 1)
		},
	}
}
</script>
<style scoped>
.imgClass {
	width: 200px;
	height: auto;
	margin: 15px 0;
}
</style>
