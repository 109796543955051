<template>
  <div class="rBox">
    <el-form label-width="90px">
      <el-form-item label="上下边距">
        <template>
          <bs-slider v-model="curSelData.paddTop" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="左右边距">
        <template>
          <bs-slider v-model="curSelData.paddLeft" :min="0" :max="100" />
        </template>
      </el-form-item>
	  <el-form-item label="扫码信息">
	    <template>
	      <el-radio v-model="curSelData.quStatus" label="0">显示</el-radio>
	      <el-radio v-model="curSelData.quStatus" label="1">隐藏</el-radio>
	    </template>
	  </el-form-item>
	  
	  <div v-if="curSelData.quStatus == 0">
	  		  <el-form-item label="代理商名称">
	  		      <el-input disabled v-model="curSelData.dlName" placeholder="代理商名称"></el-input>
	  		  </el-form-item>
	  		  <el-form-item label="经销商名称">
	  		      <el-input disabled v-model="curSelData.dealer" placeholder="经销商名称"></el-input>
	  		  </el-form-item>
			  
			  <el-form-item label="零售价(元)">
			      <el-input v-model="curSelData.price" onkeyup="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')" placeholder="请输入零售价"></el-input>
			  </el-form-item>
	  		  <el-form-item label="销售地区">
	  		      <el-input disabled v-model="curSelData.address" placeholder="销售地区"></el-input>
	  		  </el-form-item>
	  </div>
      <!-- <el-form-item label="背景颜色" class="formsItem">
        <template>
          <bs-color-picker v-model="curSelData.bgColor" style="float:left;" />
          <el-button type="primary" class="btnClear" @click="clearColor('bgColor')">清除</el-button>
        </template>
      </el-form-item>

      <el-form-item label="背景图片">
          <el-input v-model="curSelData.boxBg" placeholder="请输入图片地址">
            <template slot="append"><span style="cursor: pointer" @click="selImg('scanFive','boxBg')">选择图片</span></template>
          </el-input>
        <el-col :span="18">
          <img :src="curSelData.boxBg" alt="" class="imgClass">
        </el-col>
      </el-form-item> -->
    </el-form>
  </div>
</template>
<script>
import BsSlider from '../module/BsSlider'
// import BsColorPicker from '../module/BsColorPicker'

export default {
  components: {
    BsSlider,
    // BsColorPicker,
    
  },
  props: {
    curSelData: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => {}
    }
  },
  data() {
    return {

    }
  },
  methods: {
    clearColor(attr) {
      this.curSelData[attr] = 'transparent'
    },
    selImg(type, attr) {
      this.$emit('selImg', type, attr)
    },
    addItem() {
      const width = (100 / (this.curSelData.picList.length + 1)).toFixed(2)
      this.curSelData.picList.forEach(item => {
        item.width = width + '%'
      })
      this.curSelData.picList.push({ width: width + '%', url: '' })
    },
    delPic(index) {
      const width = (100 / (this.curSelData.picList.length - 1)).toFixed(2)
      this.curSelData.picList.forEach(item => {
        item.width = width + '%'
      })
      this.curSelData.picList.splice(index, 1)
    },
  }
}
</script>
<style scoped>
  .imgClass{
    width: 200px;
    height: auto;
    margin: 15px 0;
  }
</style>
