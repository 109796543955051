<template>
  <div class="CountdownWrap" :style="{marginTop: item.marginTop+'px', marginBottom: item.marginBottom+'px',borderBottom : item.bottomshow ? '5px solid #F0F2F7' : ''}">
    <img v-if="item.showImg" :src="item.imgUrl" class="leftImg" mode="widthFix"/>
    <div>
      <div class="countTit">{{item.title}}</div>
      <div class="priceBox">
        <span class="t1">￥</span><span class="t2">{{item.price}}</span>
        <span class="t3">{{item.oldPrice}}</span> <span class="t4">原价</span>
      </div>
      <div class="arrawBox">
        <div class="TimeWrap" v-if="item.showTime">
          <span class="time">
            <TimeCountDown
              :diffTime="getTime(item.endTime)"
              :type="2"
            />
          </span>
          <span class="timeState">后结束</span>
        </div>
        <span class="lckf" v-if="item.showContact">联系客服</span>
      </div>
    </div>
  </div>
</template>
<script>
import TimeCountDown from './TimeCountDown'
export default {
  data() {
    return {
      diffTime: 1000 * 10,
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  components: {
    TimeCountDown
  },
  methods:{
    handleContact(e) {
      console.log(e.detail)
    },
    getTime(fDate) {
      if(fDate) {
        let date = new Date(fDate) - new Date().getTime()
        return date/1000
      }else {
        return 0
      }
    }
  }
}
</script>
<style scoped lang="scss">
.CountdownWrap{
  padding: 25px 0 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .leftImg{
    width: 116px;
    height: 114px;
    margin-right: 23px;
  }
  .countTit{
    width: 198px;
    word-break: break-all;
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #26353D;
    text-align: left;
  }
  .priceBox{
    text-align: left;
     .t1{
       font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #FE0532;
     }
     .t2{
       font-size: 18px;
       font-family: Arial;
       font-weight: bold;
       color: #FE0532;
     }
     .t3{
        font-size: 13px;
        font-family: Arial;
        font-weight: 400;
        color: #808080;
        text-decoration:line-through;
        &::before{
          content: "";
          display: inline-block;
          width: 1px;
          height: 13px;
          background: #9CA7AC;
          margin: 0 10px;
        }
     }
     .t4{
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #808080;
     }
  }
  .arrawBox{
    margin-top: 12px;
    width: 196px;
    height: 33px;
    background: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/zhuoxizhiye/redArrawBg.png') top center no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 0 15px 0 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .TimeWrap{
      padding: 3px 5px;
      background: rgba(255, 237, 239, 0.8);
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #D11E39;
      border-radius: 2px;
      display: flex;
      .timeState{
        color: #503636;
        font-size: 10px;
      }
    }
    .lckf{
      font-size: 13px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #FFFFFF;
    }
  }
}

</style>