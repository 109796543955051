<template>
  <el-dialog
    append-to-body
    :close-on-click-modal="false"
    :visible="dialogVisible"
    :before-close="closeDialog"
    title="选择批次号"
    width="900px"
  >
    <div class="lookPanel">
      <div>已选择：<el-button @click="clearCheckList">清除全部</el-button></div>
      <div v-if="checkList.length">
        {{checkList}}
      </div>
    </div>
    <el-checkbox-group v-model="checkList">
    <el-table
      :data="tableData"
      border
      style="width: 100%">
        <el-table-column prop="createTime" width="100" align="center" label="创建时间">
          <template slot-scope="scope">
            {{scope.row.createTime}}
          </template>
        </el-table-column>
        <el-table-column prop="title" align="center" label="页面标题">
        </el-table-column>
        <el-table-column prop="title" align="center" label="缩略图">
          <template slot-scope="scope">
            <img :src="scope.row.coverImg" class="coverImg" />
          </template>
        </el-table-column>
        <el-table-column prop="batch" align="center" label="批次号" width="250">
          <template slot-scope="scope">
            <template v-if="scope.row.lotNumberList && scope.row.lotNumberList.length">
              <div v-for="item in scope.row.lotNumberList" :key="item.lotNumber" class="lotSpan">
                <span>
                  <el-checkbox :label="item.lotNumber" :key="item.lotNumber"></el-checkbox> ({{item.count}})
                </span>
              </div>
            </template>
          </template>
        </el-table-column>

    </el-table>
    </el-checkbox-group>
    <el-pagination
      :page-size.sync="params.pageSize"
      :total="total"
      :current-page.sync="params.pageNum"
      style="margin-top: 8px;"
      :page-sizes=pageSizes
      layout="total, prev, pager, next, sizes"
      @size-change="pageSizeChange($event)"
      @current-change="pageChange($event)"
    />
    <div slot="footer">
      <el-button type="info" @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="selBatchNumber">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import axios from 'axios'

import { fetchCreatePageList } from '@/api/customPage'
import {baseImgUrl} from "@/utils/constant";
export default {
  components: {  },
  props: {

  },
  data() {
    return {
      dialogVisible: false,
      params: {pageNum: 1,pageSize: 12, type: 0},
      total: 0,
      pageSizes: [12,16,20,24],
      tableData: [],

      checkList: [], 
    }
  },
  mounted() {
     this.init()
  },
  methods: {
    clearCheckList() {
      this.checkList = []
    },
    pageChange(value){
      this.params.pageNum=value
      this.init()
    },
    pageSizeChange(value){
      this.params.pageSize=value
      this.init()
    },
    selBatchNumber() {
     this.$parent.changeBatchNumber(this.checkList)
     this.dialogVisible=false;
    },
    openDialog(data) {
      this.dialogVisible = true
      if(data && data.length) {
        this.checkList = data
      }
    },
    closeDialog(){
      this.dialogVisible=false;
      this.clearCheckList()
    },
    async init(){
      let result = await fetchCreatePageList(this.params)
      if(result.data) {
        this.total = result.data.total
        this.tableData = result.data.list
      }
    },
    handleClick(value){
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
/deep/ .el-upload--text {
    width: 80px;
    height: 32px;
    text-align: center;
}
/deep/ .el-tabs__nav {
  margin-left: 0!important;
}
.coverImg{
  width: 40px;
}
.lookPanel{
  margin-bottom: 10px;
}
</style>
