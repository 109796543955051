<template>
  <div class="rBox">
    <el-form label-width="90px">
      <el-form-item label-width="90px" label="微信">
         <el-input v-model="curSelData.wx" placeholder="请输入微信" style="margin-top: 10px">
            <template slot="append">微信</template>
          </el-input>
      </el-form-item>
      <el-form-item label-width="90px" label="电话">
         <el-input v-model="curSelData.phone" placeholder="请输入电话" style="margin-top: 10px">
            <template slot="append">电话</template>
          </el-input>
      </el-form-item>
      <el-form-item label-width="90px" label="网址">
         <el-input v-model="curSelData.website" placeholder="请输入网址" style="margin-top: 10px">
            <template slot="append">网址</template>
          </el-input>
      </el-form-item>
      <el-form label-width="90px" label="内容">
         <el-input v-model="curSelData.twoText" placeholder="请输入内容" style="margin-top: 10px">
          </el-input>
      </el-form>
      <el-form label-width="90px" label="内容">
         <el-input v-model="curSelData.twoContent" placeholder="请输入内容" style="margin-top: 10px">
          </el-input>
      </el-form>
      <el-card class="box-card" style="margin-top: 10px">
          <div slot="header" class="clearfix">
              logo图片
          </div>
          <el-form label-width="90px">
              <el-row>
                  <el-col :span="6">
                      <el-image :src="curSelData.logo"
                          style="display: inline-block;height: 74px;width: 148px;max-width: 90%">
                          <div slot="error" class="image-slot">
                              <i class="el-icon-picture-outline" />
                          </div>
                      </el-image>
                  </el-col>
                  <el-col :span="18">
                      <el-input v-model="curSelData.logo" placeholder="请输入图片地址">
                          <template slot="append"><span style="cursor: pointer"
                                  @click="selImg('extensionPage', 'logo')">选择图片</span></template>
                      </el-input>
                  </el-col>
              </el-row>
              <el-input v-model="curSelData.logoWidth" placeholder="请输入图片宽度" style="margin-top: 10px">
                      <template slot="append">图片宽度</template>
              </el-input>
          </el-form>
      </el-card>
      <el-card class="box-card" style="margin-top: 10px">
          <div slot="header" class="clearfix">
              二维码图片
          </div>
          <el-form label-width="90px">
              <el-row>
                  <el-col :span="6">
                      <el-image :src="curSelData.codeImg"
                          style="display: inline-block;height: 74px;width: 148px;max-width: 90%">
                          <div slot="error" class="image-slot">
                              <i class="el-icon-picture-outline" />
                          </div>
                      </el-image>
                  </el-col>
                  <el-col :span="18">
                      <el-input v-model="curSelData.codeImg" placeholder="请输入图片地址">
                          <template slot="append"><span style="cursor: pointer"
                                  @click="selImg('extensionPage', 'codeImg')">选择图片</span></template>
                      </el-input>
                  </el-col>
              </el-row>
          </el-form>
      </el-card>
      <el-card class="box-card" style="margin-top: 10px">
          <div slot="header" class="clearfix">
              图片
          </div>
          <el-form label-width="90px">
              <el-row>
                  <el-col :span="6">
                      <el-image :src="curSelData.oneImg"
                          style="display: inline-block;height: 74px;width: 148px;max-width: 90%">
                          <div slot="error" class="image-slot">
                              <i class="el-icon-picture-outline" />
                          </div>
                      </el-image>
                  </el-col>
                  <el-col :span="18">
                      <el-input v-model="curSelData.oneImg" placeholder="请输入图片地址">
                          <template slot="append"><span style="cursor: pointer"
                                  @click="selImg('extensionPage', 'oneImg')">选择图片</span></template>
                      </el-input>
                  </el-col>
              </el-row>
          </el-form>
      </el-card>
    </el-form>
  </div>
</template>
<style lang="scss" scoped>
.btnClear{
  float: left;
  margin-left: 10px;
}
  .myRow {
    padding: 10px 0;
  }
  .formsItem{
    > label {
      position: relative;
      top:10px;
    }
  }
  .imgClass{
    max-width: 100%;
  }
</style>
<script>
export default {
  components: {
  },
  props: {
    curSelData: {
      type: Object,
      required: true
    },
    singleImgae: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    }
  },
  methods: {
    selImg(type, attr, index) {
      this.$emit('selImg', type, attr, index)
    },
  }
}
</script>
