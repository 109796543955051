<template>
    <div>
      <el-color-picker :value="value" :show-alpha="true" @active-change="$emit('input',$event)"></el-color-picker>
    </div>
</template>
<script>
  export  default {
    props: {
      value: {
        type: String
      }
    },
    computed: {

    },
    data(){
      return {

      }
    },
    methods:{

    }
  }
</script>
