<template>
	<div class="page"
		:style="{ 'borderBottom': item.bottomshow ? `${item.borderHeight ? item.borderHeight : 5}px solid #F0F2F7` : '', marginTop: item.marginTop+'px', marginBottom: item.marginBottom+'px' }">
		<div>
			<div class="city" :style="{ 'background-image': 'url('+ item.cityImg +')' }">
				<div class="titleImg" :style="{ 'background-image': 'url('+ item.titleImg +')', width: item.titleImgSize + 'px' }"></div>
				<div v-if="item.title" class="title" :style="{color: item.titleColor, fontSize: item.titleSize + 'px'}">{{item.title}}</div>
				<div class="titleC" :style="{color: item.titlecColor}">{{item.titleC}}</div>
			</div>
			<div class="content1">
			    <div class="list" v-for="(i, index) in item.list" :key="index" :style="{margin: index == 2 ? '50px 0 20px' : '20px 0px'}">
			        <div class="tImg">
						<div class="num" :style="{ 'background-image': 'url('+ i.num +')' }" style="width: 47px;height: 45px;background-size: 100% 100%;
		background-repeat: no-repeat;"></div>
						<div class="titleL">
							{{i.titleL}}
							<div class="gimg" :style="{ 'background-image': 'url('+ i.gImg +')', left: (i.titleL.length - 1) * 20 + 'px' }"></div>
						</div>
						<div v-if="index==0" class="z1" :style="{ 'background-image': 'url('+ item.z1 +')'}"></div>
						
						<div v-if="index==2" class="z3" :style="{ 'background-image': 'url('+ item.z3 +')'}"></div>
						
					</div>
					<div class="lcontent" v-if="i.content.length > 0 || i.pic">
						<div v-if="index==1" class="z2" :style="{ 'background-image': 'url('+ item.z2 +')', top: item.z2M + 'px'}"></div>
						<img v-if="i.pic" :style="{width: i.width + 'px', height: i.height + 'px' }" :src="i.pic" alt="" srcset="">
						<div :style="{color: i.color, fontSize: i.textSize + 'px'}" class="text" v-for="(ac, j) in i.content" :key="j">
							<img :src="item.bIcon" alt="" srcset="">
							<div>{{ac.text}}</div>
						</div>
						<video v-if="index == 2" class="myVideo"
						controls="controls" 
						:style="{'margin-top': item.srcTop + 'px', display: item.spShow ? 'block' : 'none'}"
						:src="item.src" 
						:width="item.videoWidth? item.videoWidth : '100%'"
						:height="item.videoHeight ? item.videoHeight : 'auto'"
						></video>
					</div>
			    </div>
			</div>
			<div class="analysisList" :style="{ 'background-image': 'url('+ item.bg +')', display: item.bsShow ? 'block' : 'none'  }">
				<div>
					<div v-for="(a, g) in item.analysisList" :key="g">
						<div class="yyafter">{{a.name}}</div>
						<div v-if="a.bsImg" class="bsImg" :style="{ 'background-image': 'url('+ a.bsImg +')',width: a.width + 'px', height: a.height + 'px' }"></div>
						<div v-if="a.content" class="bstext">
							<img :src="item.bIcon" alt="" srcset="">
							<div class="acontent">{{a.content}}</div>
						</div>
					</div>
					<div class="z4" :style="{ 'background-image': 'url('+ item.z4 +')', bottom: item.z4M + 'px'}"></div>
					<div class="z5" :style="{ 'background-image': 'url('+ item.z5 +')'}"></div>
				</div>
			</div>
			<div class="jdname">{{item.jdname}}</div>
		</div>
	</div>
</template>
<script>
	export default {
		props: {
			item: {
				type: Object,
				required: true
			}
		},
		computed: {
			styleObject() {
				return {
					// padding: this.item.paddTop + 'px' + ' ' + this.item.paddLeft + 'px',
					// textAlign: this.item.align,
					// fontSize: this.item.fontSize + 'px',
					// fontWeight: this.item.weight,
					// color: this.item.textColor
					backgroundColor: this.item.bgColor,
					backgroundImage: `url(${this.item.bgUrl})`,
					backgroundImageSize: 'cover',
					backgroundRepeat: 'no-repeat'
				}
			}
		},
		methods: {
			toMiniProgram(data, url, appId) {
				let {
					cardId,
					goodsId
				} = data
				console.log('cardId', cardId)
				console.log('goodsId', goodsId)
				wx.navigateToMiniProgram({
					appId: appId || "wx19adaa0665cc0c6a", // 要跳转的小程序的appid // wx1c06c4e49bf6040e
					path: `pages/prodDetail/main?goodsId=${goodsId}&cardId=${cardId}&from=111`, // 跳转的目标页面
					// envVersion: 'develop', // 仅在当前小程序为开发版或体验版时此参数有效。如果当前小程序是正式版，则打开的小程序必定是正式版。
					extarData: {
						// goodsId: 2,
						// cardId: 17785,
						// from: 111, // 111 代表小程序标记
					},
					success(res) {},
					fail(res) {
						console.log(res);
					}
				});
			},
			goDetailPage(data) {
				let {
					cardId,
					goodsId
				} = data
				console.log(data)
				uni.navigateTo({
					url: `/pages/productDetail/index?goodsId=${goodsId}&cardId=${cardId}&from=1`
				});
			}
		}
	}
</script>
<style scoped>
	.page {
		background-size: 100% 100%;
		background-repeat: no-repeat;
		width: 100%;
		height: 100vh;
		overflow: auto;
		display: flex;
		justify-content: center;
		box-sizing: border-box;
		padding: 50px 0;
		text-align: center;
		background: #070823;
		position: relative;
	}

	.page>div {
		/* width: 90%; */
		margin: 0 15px;
	}
	
	.city {
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.titleImg {
		background-size: 100%;
		background-repeat: no-repeat;
		height: 56px;
		margin: 0 auto;
	}

	.clearfix:after {
		visibility: hidden;
		display: block;
		font-size: 0;
		content: " ";
		clear: both;
		height: 0;
	}

	.title {
		margin-top: 25px;
		font-family: Source Han Sans CN;
		font-weight: 500;
		
	}
	
	.titleL {
		font-size: 20px;
		font-family: Source Han Sans CN;
		font-weight: bold;
		color: #FFFFFF;
		position: absolute;
		left: 0px;
		bottom: 0px;
		z-index: 9;
	}
	
	.name {
		
	}

	.titleC {
		padding: 0 10px;
		margin-top: 15px;
		font-size: 12px;
		font-family: Source Han Sans CN;
		font-weight: bold;
		line-height: 24px;
		height: 85px;
		background: linear-gradient(180deg, rgba(29,12,190,0.01), rgba(29,12,190,0.59), rgba(7,8,36,0));
		display: flex;
		align-items: center;
	}
	
	.content1 {
		margin-top: 16px;
	}
	
	.lcontent {
		margin-top: 20px;
		width: 100%;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		box-sizing: border-box;
		padding: 10px;
		background-color: #090835;
		position: relative;
		border: 1px solid #420CBE;
	}
	
	.tImg {
		position: relative;
	}
	
	.gimg {
		position: absolute;
		bottom: 0px;
		z-index: -1;
		width: 70px;height: 28px;background-size: 100% 100%;
		background-repeat: no-repeat;
	}
	
	.text {
		font-family: Source Han Sans CN;
		font-weight: 400;
		text-align: left;
		margin: 10px 0;
		line-height: 22px;
		display: flex;
		justify-content: space-between;
	}
	
	.bstext {
		font-family: Source Han Sans CN;
		font-weight: 400;
		margin: 10px 0;
		line-height: 22px;
		display: flex;
		justify-content: space-between;
		color: #fff;
	}
	
	.bstext > img {
		width: 6px;
		height: 6px;
		margin-right: 5px;
		margin-top: 8px;
	}
	
	.bstext > div {
		width: 100%;
		text-align: left;
	}
	
	.text > img {
		width: 6px;
		height: 6px;
		margin-right: 5px;
		margin-top: 8px;
	}
	
	.yybefore, .yyafter {
		width: 160px;
		padding: 8px 10px;
		background: linear-gradient(53deg, #0FCFEC, rgba(126,14,240,0.66));
		border-radius: 25px;
		color: #fff;
		margin: 0 5px;
	}
	
	.yybefore {
		margin-top: 10px;
	}
	
	.yyafter {
		margin-top: 10px;
	}
	
	.bsImg {
		margin: 22px auto 0;
		/* width: 323px;
		height: 326px; */
		background-size: 100%;
		background-repeat: no-repeat;
		position: relative;
		z-index: 2;
	}
	
	.thImg {
		margin-top: 22px;
		width: 326px;
		height: 270px;
		background-size: 100%;
		background-repeat: no-repeat;
	}
	
	.analysisList {
		width: 100%;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		padding: 10px;
		border: 1px solid #420CBE;
	}
	
	.analysisList > div {
		position: relative;
	}
	
	.z1 {
		width: 60px;
		height: 55px;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		position: absolute;
		right: 20px;
		top: 0px;
	}
	
	.z2 {
		width: 42px;
		height: 87px;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		position: absolute;
		right: -15px;
		
	}
	
	.z3 {
		width: 27px;
		height: 27px;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		position: absolute;
		left: -15px;
		top: -35px;
	}
	
	.z4 {
		width: 92px;
		height: 144px;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		position: absolute;
		right: -20px;
		z-index: 1;
	}
	
	.z5 {
		width: 80px;
		height: 55px;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		position: absolute;
		left: -15px;
		bottom: -110px;
	}
	
	.acontent {
		font-size: 12px;
	}
	
	.jdname {
		padding: 15px 0;
		font-size: 12px;
		color: #fff
	}
</style>
