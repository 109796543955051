<template>
    <div>
        <el-form label-width="90px">
        <!-- <el-form-item label="风格">
          <template>
            <el-radio v-model="curSelData.showStyle" :label="1">图片</el-radio>
            <el-radio v-model="curSelData.showStyle" :label="2">文字</el-radio>
          </template>
        </el-form-item> -->
        <el-form-item label="上边距">
            <template>
            <bs-slider v-model="curSelData.paddTop" :min="0" :max="100" />
            </template>
        </el-form-item>
         <el-form-item label="下边距">
            <template>
            <bs-slider v-model="curSelData.paddBottom" :min="0" :max="100" />
            </template>
        </el-form-item>
        <el-form-item label="图标上边距">
            <template>
             <bs-slider v-model="curSelData.top" :min="0" :max="100" />
            </template>
        </el-form-item>
        <template>
            
            <el-card class="box-card" style="margin-top: 10px">
                <div slot="header" class="clearfix">
                    开封图片
                </div>
                <el-form label-width="90px">
                    <el-row>
                        <el-col :span="6">
                            <el-image :src="curSelData.openImg.url"
                                style="display: inline-block;height: 74px;width: 148px;max-width: 90%">
                                <div slot="error" class="image-slot">
                                    <i class="el-icon-picture-outline" />
                                </div>
                            </el-image>
                        </el-col>
                        <el-col :span="18">
                            <el-input v-model="curSelData.openImg.url" placeholder="请输入图片地址">
                                <template slot="append"><span style="cursor: pointer"
                                        @click="selImg('bottleState', 'openImg')">选择图片</span></template>
                            </el-input>
                            <el-input v-model="curSelData.openImg.link" placeholder="请输入链接" style="margin-top: 10px">
                                <template slot="append">选择链接</template>
                            </el-input>
                        </el-col>
                        <el-input v-model="curSelData.openImg.width" placeholder="请输入图片宽度" style="margin-top: 10px">
                            <template slot="append">图片宽度</template>
                        </el-input>
                    </el-row>
                </el-form>
            </el-card>
            <el-card class="box-card" style="margin-top: 10px">
                <div slot="header" class="clearfix">
                    未开封图片
                </div>
                <el-form label-width="90px">
                    <el-row>
                        <el-col :span="6">
                            <el-image :src="curSelData.closeImg.url"
                                style="display: inline-block;height: 74px;width: 148px;max-width: 90%">
                                <div slot="error" class="image-slot">
                                    <i class="el-icon-picture-outline" />
                                </div>
                            </el-image>
                        </el-col>
                        <el-col :span="18">
                            <el-input v-model="curSelData.closeImg.url" placeholder="请输入图片地址">
                                <template slot="append"><span style="cursor: pointer"
                                        @click="selImg('bottleState', 'closeImg')">选择图片</span></template>
                            </el-input>
                            <el-input v-model="curSelData.closeImg.link" placeholder="请输入链接" style="margin-top: 10px">
                                <template slot="append">选择链接</template>
                            </el-input>
                        </el-col>
                        <el-input v-model="curSelData.closeImg.width" placeholder="请输入图片宽度" style="margin-top: 10px">
                            <template slot="append">图片宽度</template>
                        </el-input>
                    </el-row>
                </el-form>
            </el-card>            
        </template>

        <template>
            <el-form-item label="文字大小">
                <template>
                <bs-slider v-model="curSelData.fontSize" :min="0" :max="30" />
                </template>
            </el-form-item>
            <el-form-item label="文字上距离">
                <template>
                <bs-slider v-model="curSelData.marginTop" :min="0" :max="200" />
                </template>
            </el-form-item>
            <el-form-item label="开封描述">
                <el-input v-model="curSelData.openText" placeholder="请输入开封描述" style="margin-top: 10px">
                </el-input>
            </el-form-item>
            <el-form-item label="文字颜色" class="formsItem">
                <bs-color-picker v-model="curSelData.openColor" style="float: left" />
                <el-button type="primary" class="btnClear" @click="clearColor('openColor')">清除</el-button>
            </el-form-item>
            <el-form-item label="未开封描述">
                <el-input v-model="curSelData.closeText" placeholder="请输入未开封描述" style="margin-top: 10px">
                </el-input>
            </el-form-item>
             <el-form-item label="文字颜色" class="formsItem">
                <bs-color-picker v-model="curSelData.closeColor" style="float: left" />
                <el-button type="primary" class="btnClear" @click="clearColor('closeColor')">清除</el-button>
            </el-form-item>
        </template>

          <el-form-item label="背景图片">
            <el-input v-model="curSelData.boxBg" placeholder="请输入图片地址">
                <template slot="append"><span style="cursor: pointer" @click="selImg('bottleState', 'boxBg')">选择图片</span></template>
            </el-input>
            <el-col :span="18">
            <img :src="curSelData.boxBg" alt="" class="imgClass" :key="curSelData.boxBg">
            </el-col>
          </el-form-item>
        </el-form>
    </div>
</template>
<script>
	import BsSlider from '../module/BsSlider'
	import BsColorPicker from '../module/BsColorPicker'
	export default {
		components: {
			BsSlider,
			BsColorPicker
		},
		props: {
			curSelData: {
				type: Object,
				required: true
			},
			singleImgae: {
				type: Boolean,
				default: false
			}
		},
		data() {
            return {
                
            }
        },
        methods : {
            selImg(type, attr) {
			  this.$emit('selImg', type, attr)
			},
            clearColor(attr) {
              this.curSelData[attr] = 'transparent'
            },
        }
    }
</script>
<style scoped>
.imgClass{
    max-width: 100%;
}
</style>