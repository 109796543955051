<template>
  <div class="PKPage">
    <img :src="item.img" />
  </div>
</template>
<script>

	export default {
		data() {
			return {

			}
		},
		components: {

		},
		props: {
			item: {
				type: Object,
				required: true
			}
		},
		mounted() {
    },
    methods: {

    }
  }
</script>
<style scoped>
.PKPage img{
  width: 100%;
}
</style>