<template>
    <div class="rBox">

        <el-form label-width="90px">
            <el-form-item label="上边距">
                <template>
                <bs-slider v-model="curSelData.marginTop" :min="0" :max="100" />
                </template>
            </el-form-item>
            <el-form-item label="下边距">
                <template>
                <bs-slider v-model="curSelData.marginBottom" :min="0" :max="100" />
                </template>
            </el-form-item>
            <el-form-item label="标题">
                <el-input v-model="curSelData.title" type="text" placeholder="请输入标题">
                    <el-button slot="append" @click="clearTit">清除</el-button>
                </el-input>
            </el-form-item>

            <el-form-item label="标题">
                <template>
                    <el-radio v-model="curSelData.titleshow" :label="true">显示</el-radio>
                    <el-radio v-model="curSelData.titleshow" :label="false">不显示</el-radio>
                </template>
            </el-form-item>

            <el-form-item label="底边框">
                <template>
                    <el-radio v-model="curSelData.bottomshow" :label="true">显示</el-radio>
                    <el-radio v-model="curSelData.bottomshow" :label="false">不显示</el-radio>
                </template>
            </el-form-item>
            <el-form-item label="底边框高度" v-if="curSelData.bottomshow" >
                <template>
                    <bs-slider v-model="curSelData.borderHeight" :min="0" :max="20" />
                </template>
            </el-form-item>

            <el-form-item label="标题图标">
                <el-col :span="18">
                    <el-input v-model="curSelData.icon" placeholder="请输入标题图标地址">
                        <template slot="append"><span style="cursor: pointer"
                                @click="selImg('read', 'icon')">选择图片</span></template>
                    </el-input>
                </el-col>
                <el-col :span="18">
                    <img :src="curSelData.icon" alt="" class="imgClass">
                </el-col>
            </el-form-item>
            <el-card v-for="(item, index) in curSelData.productList" :key="item.name" class="box-card"
                style="margin-top: 10px">
                <div slot="header" class="clearfix">
                    <el-button style="float: right; padding: 3px 0" type="text" @click="delItem(index)">删除</el-button>
                </div>
                <el-row>
                    <el-col :span="6" style="position:relative;z-index: 999;">
                        <el-image :src="item.photo" style="display: block;height: 74px; width: 80%;">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline" />
                            </div>
                        </el-image>
                        <!-- <div class="selProductButton" @click="selProduct(index)">选择商品</div> -->
                        <div class="selProductButton" @click="selImg('read', 'photo', index)">选择图片</div>
                    </el-col>
                    <el-col :span="18">
                        <el-input v-model="item.introduce" placeholder="这里是内容介绍">
                            <template slot="append"><span style="cursor: pointer">内容</span></template>
                        </el-input>
                    </el-col>
                </el-row>
                    
                    <el-row class="myRow">
                        <el-form-item label="图片位置">
                            <el-radio v-model="item.imgAlign" label="row">图片居右</el-radio>
                            <el-radio v-model="item.imgAlign" label="row-reverse">图片居左</el-radio>
                        </el-form-item>
                    </el-row>
                    <el-row class="myRow">
                        <el-form-item label="热度">
                            <el-input v-model="item.hotNum" placeholder="请输入热度"></el-input>
                        </el-form-item>
                    </el-row>
                    <el-row class="myRow">
                        <el-form-item label="时间">
                            <el-input v-model="item.date" placeholder="请输入时间"></el-input>
                        </el-form-item>
                    </el-row>
                    <el-row class="myRow">
                        <el-form-item label="链接">
                            <el-input v-model="item.url" placeholder="请输入链接"></el-input>
                        </el-form-item>
                    </el-row>
            </el-card>
            <el-button style="margin:20px auto;width: 80%;display: block" @click="addItem">添加</el-button>
        </el-form>
    </div>
</template>
<script>
// import BsColorPicker from '../module/BsColorPicker'
import BsSlider from '../module/BsSlider'
export default {
    components: {
        BsSlider,
        // BsColorPicker
    },
    props: {
        curSelData: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            inputValue: ''
        }
    },
    methods: {
        addItem() {
            this.curSelData.productList.push(
                {
                    imgAlign: 'row',
                    introduce: '2022年的日化行业充满了变化 与机遇，消费者在依然 ...',
                    date: '4小时之前',
                    icon: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/zhuoxizhiye/huo.png',
                    photo: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/zhuoxizhiye/proImg.png',
                    hotNum: 1660,
                    url:'',
                },
            )
        },
        delItem(index) {
            this.curSelData.productList.splice(index, 1)
            this.curSelData.productList.map((item, index) => {
                item.goodsName = '商品名称' + (index + 1)
            })
        },
        selProduct(index) {
            console.log(index)
            this.$emit('selProduct', index)
        },
        clearColor(attr) {
            this.curSelData[attr] = 'transparent'
        },
        selImg(type, attr, index) {
            this.$emit('selImg', type, attr, index)
        },
        clearTit() {
            this.curSelData.title = ''
        },
        clearSubTit() {
            this.curSelData.subTitle = ''
        }

    }
}
</script>
<style scoped>
.imgClass {
    max-width: 100%;
    margin-top: 20px;
}

.selProductButton {
    position: absolute;
    height: 20px;
    left: 0;
    right: 20%;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    position: absolute;
    cursor: pointer;
}
</style>
  