<template>
  <div class="rBox">
    <el-form label-width="100px">
      <el-form-item label="按钮名称">
        <el-input v-model="curSelData.text" placeholder="请输入按钮名称" style="margin-top: 10px">
        </el-input>
      </el-form-item>
      <el-form-item label="文字大小">
        <template>
          <bs-slider v-model="curSelData.fontSize" :min="12" :max="30" />
        </template>
      </el-form-item>
      <el-form-item label="文字颜色" class="formsItem">
        <bs-color-picker v-model="curSelData.color" style="float: left" />
        <el-button type="primary" class="btnClear" @click="clearColor('color')">清除</el-button>
      </el-form-item>
      <el-form-item label="背景色" class="formsItem">
        <bs-color-picker v-model="curSelData.bgColor" style="float: left" />
        <el-button type="primary" class="btnClear" @click="clearColor('bgColor')">清除</el-button>
      </el-form-item>
      <el-form-item label="按钮风格">
          <template>
            <el-radio v-model="curSelData.btnStyle" :label="1">风格一</el-radio>
            <!-- <el-radio v-model="curSelData.btnStyle" :label="2">风格二</el-radio> -->
          </template>
      </el-form-item>
      <el-form-item label="距上距离">
        <template>
          <bs-slider v-model="curSelData.paddTop" :min="0" :max="30" />
        </template>
      </el-form-item>
      <el-form-item label="距下距离">
        <template>
          <bs-slider v-model="curSelData.paddBottom" :min="0" :max="30" />
        </template>
      </el-form-item>
      <el-form-item label="设置咨询字段">
        <el-input v-model="curSelData.zx_key" placeholder="请输入字段名" style="margin-top: 10px" :disabled="true">
        </el-input>
      </el-form-item>
      <el-form-item label="指定客服">
        <el-row>
          <el-col :span="12">
            <el-input v-model="curSelData.zx_name" placeholder="指定客服">
              <template slot="append"><span style="cursor: pointer" @click="selKf">指定客服</span></template>
            </el-input>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import BsSlider from '../module/BsSlider'
import BsColorPicker from '../module/BsColorPicker'
export default {
  components: {
    BsSlider,
    BsColorPicker
  },
  props: {
    curSelData: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
    }
  },
  methods: {
    delPic(index) {
      const width = (100 / (this.curSelData.picList.length - 1)).toFixed(2)
      this.curSelData.picList.forEach(item => {
        item.width = width + '%'
      })
      this.curSelData.picList.splice(index, 1)
    },
    selImg(type, attr) {
      this.$emit('selImg',type, attr)
    },
    selKf() {
      this.$emit('selKf')
    },
    addItem() {
      const width = (100 / (this.curSelData.picList.length + 1)).toFixed(2)
      this.curSelData.picList.forEach(item => {
        item.width = width + '%'
      })
      this.curSelData.picList.push({ width: width + '%', url: '' })
    },
    clearColor(attr) {
      this.curSelData[attr] = 'transparent'
      // this.$set(this.curSelData, attr, '#fff')
    },
    getUnitText(unit) {
      let str = ''
      if(unit === 1) {
        str="%"
      }else {
        str = 'px'
      }
      return str
    }
  }
}
</script>
