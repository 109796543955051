<template>
  <div class="gzhBtn">
      <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/gzhIcon.png" class="gzhIcon" />
      <span>{{item.name}}</span>
    </div>
</template>
<script>
export default {
  name: 'gzhBtn',
  components: {
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {

    }
  }
}
</script>
<style scoped>
.gzhBtn{
      width: 100%;
      height: 50px;
      border-top: 1px solid #ccc;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      line-height: 50px;
      background: #2196F3;
      color: #fff;
    }
.gzhIcon{
  width: 30px;
  height: 30px;
  margin-right: 10rpx;
}
</style>