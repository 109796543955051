<template>
  <div class="rBox">
    <el-form label-width="90px">
      <el-form-item label="左右边距">
        <template>
          <bs-slider v-model="curSelData.paddLeft" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="上下边距">
        <template>
          <bs-slider v-model="curSelData.paddTop" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="背景颜色">
        <template>
          <bs-color-picker v-model="curSelData.bgColor" style="float:left" />
          <el-button type="primary" class="btnClear" @click="clearColor('bgColor')">清除</el-button>
        </template>
      </el-form-item>
      <!-- <el-form-item label="背景图片">
        <el-col :span="18" v-for="(item,index) in curSelData.bgImg" :key="item.url">
          <el-input v-model="item.url" placeholder="请输入图片地址">
            <template slot="append"><span style="cursor: pointer" @click="selImg(index)">选择图片</span></template>
          </el-input>
          <el-input v-model="item.link" placeholder="请输入链接" style="margin-top: 10px">
            <template slot="append">选择链接</template>
          </el-input>
        </el-col>
        <el-col :span="18">
          <img :src="item.url" alt="" class="imgClass" v-for="(item,index) in curSelData.bgImg" :key="item.url+index">
        </el-col>
      </el-form-item> -->
      <el-form-item label="主标题" class="formsItem">
        <el-input v-model="curSelData.wrapTit" placeholder="请输入标题" style="margin-top: 10px">
        </el-input>
      </el-form-item>
      <el-form-item label="主标题颜色">
        <template>
          <bs-color-picker v-model="curSelData.wrapTitColor" />
        </template>
      </el-form-item>
      <el-form-item label="副标题" class="formsItem">
        <el-input v-model="curSelData.wrapEnTit" placeholder="请输入标题" style="margin-top: 10px">
        </el-input>
      </el-form-item>
      <el-form-item label="副标题颜色">
        <template>
          <bs-color-picker v-model="curSelData.wrapEnTitColor" />
        </template>
      </el-form-item>
    </el-form>
    <el-form label-width="100px">
    <el-card v-for="(item,index) in curSelData.picList" :key="item.title" class="box-card" style="margin-top: 10px">
      <div slot="header" class="clearfix">
        <el-button style="float: right; padding: 3px 0" type="text" @click="delPic(index)">删除</el-button>
      </div>
      <el-row>
        <el-col :span="6">
          <el-image :src="item.url" style="display: block;height: 74px;width: 148px;max-width: 90%">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline" />
            </div>
          </el-image>
        </el-col>
        <el-col :span="18">
          <el-input v-model="item.url" placeholder="请输入图片地址">
            <template slot="append"><span style="cursor: pointer" @click="selImg('imgTextList','picList',index)">选择图片</span></template>
          </el-input>
          <el-input v-model="item.link" placeholder="请输入链接" style="margin-top: 10px">
            <template slot="append">选择链接</template>
          </el-input>
        </el-col>
        <el-input v-if="!singleImgae" v-model="item.width" placeholder="请输入图片宽度" style="margin-top: 10px">
          <template slot="append">图片宽度</template>
        </el-input>
      </el-row>
      <el-row class="myRow">
        <el-form-item label="图片位置">
          <el-radio v-model="item.imgAlign" label="row">图片居左</el-radio>
          <el-radio v-model="item.imgAlign" label="row-reverse">图片居右</el-radio>
        </el-form-item>
      </el-row>
      <el-row class="myRow">
        <el-form-item label="图片圆角">
          <bs-slider v-model="item.imgRadius" :min="0" :max="100" />
        </el-form-item>
      </el-row>
      <el-row class="myRow">
        <el-form-item label="文本区域位置">
          <el-radio v-model="item.conWrapAlign" label="flex-start">居上</el-radio>
          <el-radio v-model="item.conWrapAlign" label="center">居中</el-radio>
          <el-radio v-model="item.conWrapAlign" label="flex-end">居下</el-radio>
        </el-form-item>
      </el-row>

      <!--标题-->
      <el-row>
        <el-col :span="24">
          <el-input v-model="item.title" placeholder="请输入标题" style="margin-top: 10px">
            <template slot="append">标题</template>
          </el-input>
        </el-col>
      </el-row>
       <el-row class="myRow">
        <el-form-item label="标题对齐方式">
            <el-radio v-model="item.titleAlign" label="left">居左</el-radio>
            <el-radio v-model="item.titleAlign" label="center">居中</el-radio>
            <el-radio v-model="item.titleAlign" label="right">居右</el-radio>
        </el-form-item>
      </el-row>
      <el-row class="myRow">
        <el-form-item label="标题颜色">
          <bs-color-picker v-model="item.titleColor" />
        </el-form-item>
      </el-row>
      <el-row class="myRow">
        <el-form-item label="标题尺寸">
          <bs-slider v-model="item.titleSize" :min="0" :max="100" />
        </el-form-item>
      </el-row>
      <el-row class="myRow">
        <el-form-item label="标题线显示">
          <el-radio v-model="item.isShowLine" :label="true">显示</el-radio>
          <el-radio v-model="item.isShowLine" :label="false">隐藏</el-radio>
        </el-form-item>
      </el-row>
      <template v-if="item.isShowLine">
        <el-row class="myRow">
          <el-form-item label="标题线颜色">
            <bs-color-picker v-model="item.lineColor" />
          </el-form-item>
        </el-row>
      </template>
      <el-row class="myRow">
        <el-form-item label="标题上下边距">
          <bs-slider v-model="item.titPaddTop" :min="0" :max="100" />
        </el-form-item>
      </el-row>
      <!--小标题-->
      <el-row>
        <el-col :span="24">
          <el-input v-model="item.subTitle" placeholder="请输入小标题" style="margin-top: 10px">
            <template slot="append">小标题</template>
          </el-input>
        </el-col>
      </el-row>
      <el-row class="myRow">
        <el-form-item label="小标题颜色">
          <bs-color-picker v-model="item.subTitColor" />
        </el-form-item>
      </el-row>
      <el-row class="myRow">
        <el-form-item label="小标题尺寸">
          <bs-slider v-model="item.subTitleSize" :min="0" :max="100" />
        </el-form-item>
      </el-row>
      <!--内容-->
      <el-row>
        <el-col :span="24">
          <el-input v-model="item.content" placeholder="请输入内容" style="margin-top: 10px">
            <template slot="append">内容</template>
          </el-input>
        </el-col>
      </el-row>
      <el-row class="myRow">
        <el-form-item label="内容对齐方式">
          <el-radio v-model="item.contentAlign" label="left">居左</el-radio>
          <el-radio v-model="item.contentAlign" label="center">居中</el-radio>
          <el-radio v-model="item.contentAlign" label="right">居右</el-radio>
        </el-form-item>
      </el-row>
      <el-row class="myRow">
        <el-form-item label="内容颜色">
          <bs-color-picker v-model="item.contentColor" />
        </el-form-item>
      </el-row>
      <el-row class="myRow">
        <el-form-item label="内容文字大小">
          <bs-slider v-model="item.contentSize" :min="0" :max="100" />
        </el-form-item>
      </el-row>
    </el-card>
    </el-form>
    <el-button v-if="!singleImgae" style="margin:20px auto;width: 80%;display: block" @click="addItem">添加</el-button>
  </div>
</template>
<style lang="scss" scoped>
.btnClear{
  float: left;
  margin-left: 10px;
}
  .myRow {
    padding: 10px 0;
  }
  .formsItem{
    > label {
      position: relative;
      top:10px;
    }
  }
</style>
<script>
import BsSlider from '../module/BsSlider'
import BsColorPicker from '../module/BsColorPicker'
export default {
  components: {
    BsSlider,
    BsColorPicker
  },
  props: {
    curSelData: {
      type: Object,
      required: true
    },
    singleImgae: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    }
  },
  methods: {
    delPic(index) {
      this.curSelData.picList.splice(index, 1)
      this.curSelData.picList.map((item, index) => {
        item.title = '这是标题'+( index + 1)
      })
    },
    selImg(type, attr, index) {
      this.$emit('selImg', type, attr, index)
    },
    addItem() {
      let str= '这是标题' + (this.curSelData.picList.length ? this.curSelData.picList.length +1 : '1')
      this.curSelData.picList.push(
        {
          url: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/custom/ec6368a65159228c41f8c73fc09ca20b.jpeg',
          width: '30%', // 图片宽度
          link: '',
          subTitle: '',
          title: str,
          content: '这是内容',

          imgAlign: 'row',
          imgRadius: 0,
          conWrapAlign: 'center',

          titleAlign: 'left',
          titleColor: '#000',
          titPaddTop: 0, // 标题上下距离
          titleSize: 16,

          subTitColor: '#313131',
          subTitleSize: 12,

          contentAlign: 'left',
          contentColor: '#666',
          contentSize: 12,

          isShowLine: false,
          lineColor: '#000',

        }
      )

    },
    clearColor(attr) {
      this.curSelData[attr] = 'transparent'
    },
  }
}
</script>
