<template>
  <div>
     <el-card v-for="(item,index) in curSelData.videoList" :key="item.url + index" class="box-card" style="margin-top: 10px">
      <div slot="header" class="clearfix">
        <el-button style="float: right; padding: 3px 0" type="text" @click="delVideo(index)">删除</el-button>
      </div>
      <el-row>
        <!-- <el-col :span="6">
          <el-image :src="item.url" style="display: block;height: 74px;width: 148px;max-width: 90%">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline" />
            </div>
          </el-image>
        </el-col> :span="18"-->
        <el-col>
          <el-input v-model="item.url" placeholder="请输入视频链接">
            <template slot="append"><span style="cursor: pointer" @click="selVideo(index)">选择视频</span></template>
          </el-input>
        </el-col>
      </el-row>
      <!-- <el-row>
        <el-input v-model="item.videoWidth" placeholder="请输入视频宽度" style="margin-top: 10px">
          <template slot="append">视频宽度</template>
        </el-input>
      </el-row>
      <el-row>
        <el-input v-model="item.videoHeight" placeholder="请输入视频高度" style="margin-top: 10px">
          <template slot="append">视频高度</template>
        </el-input>
      </el-row> -->
    </el-card>
    <el-button style="margin:20px auto;width: 80%;display: block" @click="addItem">添加</el-button>
  </div>
</template>
<script>
export default {
  components: {

  },
  props: {
    curSelData: {
      type: Object,
      required: true
    },
  },
  methods: {
    addItem() {
      this.curSelData.videoList.push({
        url: '',
        videoHeight: null,
        videoWidth: null
      })
    },
    delVideo(index) {
      this.curSelData.videoList.splice(index, 1)
    },
    selVideo(index) {
      this.$emit('selVideo', 'mulVideo', 'videoList', index)
    }
    
  }
}
</script>